import { NavLink } from "react-router-dom";
import SelectedCountry from "../components/SelectedCountry";
import { useEffect, useState } from "react";
import { ConfigToast, setNotificationError } from "../func/setNotification";
import { registerUser } from "../auth/func/authUser";
import SelectedFromData from "../components/SelectedFromData";
import countryData from "../data/countryAvaible";
import formattedValue from "../func/filter";


const typeAccountData = [
    { id: 0, name: 'Selecciona tu tipo de cuenta:', img: process.env.PUBLIC_URL + '/assets/type/select.png' },
    { id: 1, name: 'Usuario', img: process.env.PUBLIC_URL + '/assets/type/user.png' },
    { id: 2, name: 'Empresa', img: process.env.PUBLIC_URL + '/assets/type/briefcase.png' },
]

const messagesRegister = [
    { id: 0, message: 'Crea tu cuenta de empresa y brinda tus servicios a los usuarios.', title: 'Titulo 1' },
    { id: 1, message: 'Se parte de la red de usuario para contratar los mejores servicios en tu comunidad.', title: 'Titulo 2' },
    { id: 2, message: 'Work it brinda rapides y seguridad a las transacciones entre usuarios y empresas.', title: 'Titulo 3' },
]


const ErrorAlert = ({ text }) => {
    return (
        <div className="flex flex-row items-center w-full gap-x-1 text-[#fc526a]" >
            <i className="bi bi-exclamation-triangle-fill text-base"></i>
            <span className="text-sm" >{text}</span>
        </div>
    )
}

export default function Register() {
    const [err, setErr] = useState(0)
    const [country, setCountry] = useState(countryData[0])
    const [typeAccount, setTypeAccount] = useState(typeAccountData[0])
    const [form, setForm] = useState({ email: '', name: '', country: country, typeAccount: typeAccount, password: '' })
    useEffect(() => setForm({ ...form, country: country, typeAccount: typeAccount }), [country, typeAccount])
    const handleSubmit = (event) => {
        event.preventDefault()
        const data = new FormData(event.target)
        if (data.get('email') === '') { setErr(1); return; }
        else if (data.get('name') === '') { setErr(2); return; }
        else if (country.id === 0) { setErr(3); return; }
        else if (typeAccount.id === 0) { setErr(4); return; }
        else if (data.get('password') === '') { setErr(5); return; }

        if (data.get('name').length <= 6) { setErr(6); return; }

        const dataUser = {
            email: data.get('email'),
            name: data.get('name'),
            country: country,
            typeAccount: typeAccount,
            password: data.get('password')
        }
        registerUser(dataUser)
    }

    useEffect(() => {
        if (err === 1 && form.email !== '') { setErr(0); return; }
        else if (err === 2 && form.name !== '') { setErr(0); return; }
        else if (err === 3 && country.id !== 0) { setErr(0); return; }
        else if (err === 4 && typeAccount.id !== 0) { setErr(0); return; }
        else if (err === 5 && form.password !== '') { setErr(0); return; }
    }, [form])

    return (
        <>
            <ConfigToast />
            <div className="w-full h-screen bg-[#f7fafc]" >
                <div className="flex flex-row h-full w-full sm:w-[540px] xl:w-[1080px] phone:mx-auto pt-[56px]" >
                    <div className="hidden w-1/2 h-full bg-[#f7fafc] xl:flex flex-col items-center">
                        <div className="w-full flex flex-col sm:px-12 py-4 sm:py-14 bg-transparent rounded h-[814px] max-h-[814px] gap-y-8" >
                            <h2 className="w-full text-left text-principal text-4xl font-bold">work it</h2>
                            <div className="flex flex-col gap-y-6 max-w-sm" >
                                {
                                    messagesRegister.map((message) => (
                                        <div key={message.id} className="flex flex-row gap-x-4" >
                                            <i className="bi bi-check-circle-fill text-principal text-lg"></i>
                                            <div className="flex flex-col">
                                                <span className="w-full text-justify text-gray-500 text-sm font-normal" >{message.message}</span>
                                            </div>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>

                    <div className="w-full xl:w-1/2 h-full bg-[#f7fafc] flex flex-col items-center">
                        <div className="w-full flex flex-col sm:px-12 py-4 sm:py-14 bg-white sm:shadow-4xl rounded h-[814px] max-h-[814px]" >
                            <span className="text-xl px-5 pb-5" >Crea tu cuenta de <b className="text-principal" >Work It</b>.</span>

                            <form onSubmit={(event) => handleSubmit(event)} className="px-5 flex flex-col gap-y-8" >
                                <div className="flex flex-col pt-1" >
                                    <label htmlFor="email" className="pb-2" >
                                        <span className="w-full text-[#1a1f36] text-sm font-semibold" >Correo electrónico</span>
                                    </label>
                                    <input className={`
                                        w-full rounded-md border border-[#e0e1e4] px-3 py-2
                                        transition duration-500 ease-in-out transform 
                                        hover:shadow-4xl 
                                        focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-blue-500
                                    `} type="text" name="email" id="email" value={form.email} onChange={(e) => setForm({ ...form, email: e.target.value })} />

                                    {err === 1 && <ErrorAlert text={'Ingresa tu correo electrónico.'} />}
                                </div>

                                <div className="flex flex-col pt-1" >
                                    <label htmlFor="name" className="pb-2" >
                                        <span className="w-full text-[#1a1f36] text-sm font-semibold" >Nombre Completo</span>
                                    </label>
                                    <input className={`
                                        w-full rounded-md border border-[#e0e1e4] px-3 py-2
                                        transition duration-500 ease-in-out transform 
                                        hover:shadow-4xl 
                                        focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-blue-500
                                    `} type="text" name="name" id="name" value={form.name} onChange={(e) => {
                                            setForm({ ...form, name: formattedValue(e.target.value.toLowerCase()) });
                                        }} />

                                    {err === 2 && <ErrorAlert text={'Ingresa tu nombre completo.'} />}
                                    {err === 6 && <ErrorAlert text={'El nombre tiene que tener como minimo 6 caracteres.'} />}
                                </div>

                                <div className="flex flex-col pt-1">
                                    <SelectedCountry
                                        country={countryData}
                                        setCountryUser={setCountry}
                                        value={country}
                                    />

                                    {err === 3 && <ErrorAlert text={'Selecciona tu pais.'} />}
                                </div>

                                <div className="flex flex-col pt-1">
                                    <SelectedCountry
                                        labelText={'Tipo de cuenta'}
                                        country={typeAccountData}
                                        setCountryUser={setTypeAccount}
                                        value={typeAccount}
                                    />

                                    {err === 4 && <ErrorAlert text={'Selecciona tu tipo de cuenta.'} />}
                                </div>


                                <div className="flex flex-col pt-1" >
                                    <label htmlFor="password" className="pb-2" >
                                        <span className="w-full text-[#1a1f36] text-sm font-semibold" >Contraseña</span>
                                    </label>
                                    <input className={`
                                        w-full rounded-md border border-[#e0e1e4] px-3 py-2
                                        transition duration-500 ease-in-out transform 
                                        hover:shadow-4xl 
                                        focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-blue-500
                                    `} type="password" name="password" id="password" value={form.password} onChange={(e) => setForm({ ...form, password: e.target.value })} />

                                    {err === 5 && <ErrorAlert text={'Ingresa tu contraseña.'} />}
                                </div>
                                <button className="w-full bg-principal text-white py-3 rounded-lg" type="submit">Crear cuenta</button>
                            </form>

                            <div className="w-full flex flex-row items-center justify-center pt-8" >
                                <span className="text-base text-center text-gray-500" >¿Ya tienes una cuenta? <NavLink to={'/login'} className={`
                                    text-principal font-medium
                                    hover:text-principal/80 transition duration-500 ease-in-out
                                `} >Inicia sesión</NavLink></span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}