//  Firebase
import { auth, googleProvider } from "../../config/firebase-config";
import { AuthErrorCodes, EmailAuthProvider,confirmPasswordReset, 
    reauthenticateWithCredential, 
    sendEmailVerification, sendPasswordResetEmail, 
    signInWithEmailAndPassword, 
    signInWithPopup, updatePassword, 
    updateProfile
} from "firebase/auth";
import { signOut } from "firebase/auth";
import { createUserWithEmailAndPassword } from "firebase/auth";

//  Firestore
import { setDoc, doc, updateDoc, getDoc, Timestamp } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from "../../config/firebase-config";

//  Notification
import { setNotificationError } from "../../func/setNotification";
import { setNotificationSuccess } from "../../func/setNotification";
import ApiCustomer from "../../api/customer.api";

//  LoginUser
export const loginUser = async (data) => {
    //  ValidateData
    if (data.email === '') {
        return 'Ingresa un email valido.'
    } else if (data.password === '') {
        return 'Ingresa una contraseña valida.'
    }


    try {
        await signInWithEmailAndPassword(
            auth,
            data.email,
            data.password
        );

        return true;
    } catch (error) {
        console.log(error);

        return 'El email o la contraseña son incorrectos.'
    }
}

//  LogoutUser
export const logoutUser = async () => {
    try {
        await signOut(auth);
    } catch (error) {
        console.log(error);
    }
}



//  UpdateUser
export const updateUser = async (data, userData, currentUser) => {
    //  ValidateData
    let objectToUpdate = {}

    Object.keys(data).some((key) => {
        if (data[key] !== '') {
            Object.assign(objectToUpdate, {
                [key]: data[key]
            })
        }
    })

    if (Object.keys(objectToUpdate).length === 0) {
        setNotificationError('No hay datos para actualizar.')
        return
    }

    if (objectToUpdate.profileImage) {
        try {
            const fileRef = ref(storage, userData.uid + '.png')
            const snapShot = await uploadBytes(fileRef, objectToUpdate.profileImage)
            const photoURL = await getDownloadURL(snapShot.ref)

            console.log(photoURL);

            objectToUpdate = {
                ...objectToUpdate,
                profileImage: photoURL
            }

            updateProfile(currentUser, {photoURL})
        } catch (error) {
            setNotificationError('Error al actualizar la imagen de perfil.')
        }
    }

    console.log(objectToUpdate.typeAccount);

    if (Object.keys(objectToUpdate).length >= 1) {

        const user = currentUser;

        user.getIdToken().then(async token => {
            updateProfile(user, {
                displayName: data.name,
            })

            await updateDoc(doc(db, "users", userData.uid), {
                email: objectToUpdate.email ? objectToUpdate.email : userData.email,
                accountType: objectToUpdate.accountType ? objectToUpdate.accountType : userData.accountType,
                //'profile.name': objectToUpdate.name ? objectToUpdate.name : userData.profile.name,
                //'profile.lastName': objectToUpdate.lastName ? objectToUpdate.lastName : userData.profile.lastName,
                'profile.phone': objectToUpdate.phone ? objectToUpdate.phone : userData.profile.phone,
                'profile.address': objectToUpdate.address ? objectToUpdate.address : userData.profile.address,
                'profile.photo': objectToUpdate.profileImage ? objectToUpdate.profileImage : userData.profile.photo,
            }).then(() => {
                setNotificationSuccess('Datos actualizados.')
            }).catch(() => {
                setNotificationError('Error al actualizar los datos.')
            })
        })
    }
}

//  RegisterUser
export const registerUser = async (data) => {
    //  useStates
    let error = null;

    //  ValidateData
    if (data.email === '') {
        error = 'Ingresa un email valido.'
    } else if (data.name === '') {
        error = 'Ingresa un nombre valido.'
    } else if (data.country.id === 0) {
        error = 'Selecciona un país.'
    } else if (data.password === '') {
        error = 'Ingresa una contraseña valida.'
    } else if (data.password.length < 8) {
        error = 'La contraseña debe tener al menos 8 caracteres.'
    } else if (data.typeAccount.id === 0) {
        error = 'Selecciona un tipo de cuenta.'
    }

    if (error !== null) {
        setNotificationError(error)
        return error;
    }

    //  RegisterUser
    try {
        await createUserWithEmailAndPassword(
            auth,
            data.email,
            data.password
        ).then((userCredential) => {
            const user = userCredential.user;
            const uid = user.uid;
            user.getIdToken().then(token => {
                updateProfile(user, {
                    displayName: data.name,
                })
    
                //  CreateDataUser
                setDoc(doc(db, "users", uid), {
                    uid: uid,
                    email: data.email,
                    accountType: data.typeAccount.id,
                    profile: {
                        //name: "",
                        //lastName: "",
                        phone: "",
                        photo: "",
                        address: ""
                    },
                    createdAt: Timestamp.fromDate(new Date()),
                }).then(() => {
                    console.log('Usuario registrado');
                    ApiCustomer.post('/', {
                        data: {
                            email: data.email, 
                            name: data.name, 
                        }
                    }, {
                        headers: {
                            Authorization: `Bearer ${ token }`
                        },
                    })
                });
            })
        });
    } catch (error) {
        if (error.code === AuthErrorCodes.EMAIL_EXISTS) {
            setNotificationError('El correo ya esta registrado.')
        } else if (error.code === AuthErrorCodes.INVALID_EMAIL) {
            setNotificationError('El correo no es valido.')
        }else {
            console.log(error);
            setNotificationError('Error al registrar el usuario.')
        }
    }
}

//  UpdatePassword
export const updatePasswordUser = async ({ actualPassword, newPassword, confirmPassword }) => {
    const { currentUser } = auth;
    if (newPassword === confirmPassword) {
        let credential = EmailAuthProvider.credential(
            currentUser.email,
            actualPassword
        );
        reauthenticateWithCredential(currentUser, credential).then(() => {
            updatePassword(currentUser, newPassword).then(() => {
                setNotificationSuccess('Contraseña actualizada.')
                return { error: false, message: 'Contraseña actualizada.' };
            }).catch(() => {
                setNotificationError('Error al actualizar la contraseña.')
            })
        }).catch((error) => {
            setNotificationError('La contraseña actual es incorrecta.')
        })

    } else {
        setNotificationError('Las contraseñas no coinciden.')
    }
}

//  SendEmailVerify
export const sendEmailVerify = async () => {
    const { currentUser } = auth;
    if (currentUser.emailVerified) {
        setNotificationError('El correo ya esta verificado.')
    } else {
        try {
            await sendEmailVerification(currentUser).then(resp => {
                console.log(resp)
            })
            setNotificationSuccess('Se ha enviado un correo de verificación.')
        } catch (error) {
            console.log(error);
            setNotificationError('Error al enviar el correo de verificación.')
        }
    }
}

//  ForgotPassword
export const forgotPassword = async ({ email }) => {
    if (email === '') {
        setNotificationError('Ingresa un email valido.')
    } else {
        sendPasswordResetEmail(auth, email).then(() => {
            setNotificationSuccess('Se ha enviado un correo para restablecer la contraseña.')
        }).catch((error) => {
            if (error.code === AuthErrorCodes.TOO_MANY_ATTEMPTS_TRY_LATER) {
                setNotificationError('Demasiados intentos de inicio de sesión fallidos. Inténtalo de nuevo más tarde.');
            }
            else if (error.code === AuthErrorCodes.INVALID_EMAIL) {
                setNotificationError('El correo electrónico no es válido.');
            } else {
                setNotificationError('Error al enviar el correo para restablecer la contraseña.')
            }
        })
    }
}

//  ResetPassword
export const resetPassword = async ({ oobCode, newPassword, confirmPassword }) => {
    if (newPassword === confirmPassword) {
        try {
            confirmPasswordReset(auth, oobCode, newPassword).then(() => {
                setNotificationSuccess('Contraseña actualizada.')
            }).catch(() => {
                setNotificationError('Error al actualizar la contraseña.')
            })
        } catch (error) {
            setNotificationError('Error al actualizar la contraseña.')
        }
    } else {
        setNotificationError('Las contraseñas no coinciden.')
    }
}

//  GoogleProvider
export const googleRegister = async () => {
    try {
        const result = await signInWithPopup(auth, googleProvider)
        const docRef = doc(db, "users", result.user.uid);
        const docSnap = await getDoc(docRef);        
        if (! (docSnap.exists())) {
            setDoc(doc(db, "users", result.user.uid), {
                uid: result.user.uid,
                email: result.user.email,
                accountType: null,
                profile: {
                    name: result.user.displayName.split(" ")[0] ? result.user.displayName.split(" ")[0] : "",
                    lastName: result.user.displayName.split(" ")[1] ? result.user.displayName.split(" ")[1] : "",
                    phone: "",
                    photo: result.user.photoURL,
                    address: ""
                },
                favorites: [],
                createdAt: Timestamp.fromDate(new Date()),
            }).then(() => {
                return true;
            });
        }
    } catch (error) {
        console.log(error);
    }
}



