//  React
import { useState } from "react";
import { useEffect } from "react";
import { createContext } from "react";

//  Firebase
import { db } from "../config/firebase-config";
import { doc, onSnapshot } from 'firebase/firestore'

//  ContextAuth
import { AuthContext } from "./AuthContext";
import { useContext } from "react";

//  ContextData
export const DataContext = createContext();
export const DataProvider = ({ children }) => {
    const { currentUser, loadingUser } = useContext(AuthContext);
    const [loadingData, setLoadingData] = useState(false);
    const [userData, setUserData] = useState(null);

    useEffect(() => {
        if (currentUser !== null) {
            if (loadingUser) {
                const unSub = onSnapshot(doc(db, "users", currentUser.uid), (doc) => {
                    setUserData(doc.data());
                    setLoadingData(true);
                });

                return () => unSub();
            }
        }
    }, [currentUser]);

    return (
        <DataContext.Provider value={{ userData, loadingData }}>
            {children}
        </DataContext.Provider>
    );
}