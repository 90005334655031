//  Styles
import './scss/SearchBar.scss'

//  React
import { Link, NavLink } from 'react-router-dom'
import { useState } from 'react'

//  Icons
import { AiOutlineSetting } from 'react-icons/ai'
import { MdPayment } from 'react-icons/md'
import { TbCrown, TbHelp } from 'react-icons/tb'
import { FaFileInvoiceDollar } from 'react-icons/fa'
import { IonIcon } from '@ionic/react'
import { searchOutline, filterOutline, chevronDownOutline } from 'ionicons/icons'

//  Context
import { useContext } from 'react'
import { DataContext } from '../context/DataContext'
import { AuthContext } from '../context/AuthContext'
import { useEffect } from 'react'

import SearchBarInput from './SearchBarInput'

//  Return
export default function SearchBar() {
    const { userData, loadingData } = useContext(DataContext)
    const { currentUser, loadingUser } = useContext(AuthContext)
    const [dataSearch, setDataSearch] = useState([])

    //  AvatarImageNotFound
    const AvatarImageNotFound = process.env.PUBLIC_URL + "/images/users/notuser.png"

    const loadProfile = () => {
        return {
            username: loadingData ? (
                currentUser.displayName
            ) : 'Name Lastname' ,
            email: loadingData ? (
                userData.email ? userData.email : 'email@email.com'
            ) : (
                'email@email.com'
            ),
            imageProfile: loadingData ? (
                userData.profile.photo ? userData.profile.photo : AvatarImageNotFound 
            ) : (
                AvatarImageNotFound
            )
        }
    }
    const [dataUser, setDataUser] = useState(loadProfile)
    useEffect(() => {
        setDataUser(loadProfile)
    }, [userData])

    
    //  OpenSettings
    const openSettingsProfile = () => {
        document.querySelector('#button-profile-config').classList.toggle('active')
        document.querySelector('#dropdown').classList.toggle('active')
    }

    return (
        <div className="container-search-bar m-3 gap-3">
            <div className="left-side pe-2 d-flex gap-2">
                <div className='w-full relative'>
                    <div className="bg-[#eee] rounded-lg flez flex items-center w-full px-2 relative">
                        <IonIcon className='text-[#9D9D9D]' icon={ searchOutline } size="large" />
                        <SearchBarInput setValues={setDataSearch} />
                    </div>

                    

                    {
                        dataSearch.length !== 0 && (
                            <div className='flex flex-col absolute rounded-lg w-full my-4 bg-[#eee] z-50 overflow-hidden'>
                                {
                                    dataSearch.map((item, index) => {
                                        return (
                                            <NavLink to={"/objectID"} className='transition-all duration-300 hover:bg-black/10 px-2 py-2 object-cover' key={index}>
                                                <div className='flex flex-row items-center gap-2'>
                                                    <div className='relative w-12 h-12 overflow-hidden drop-shadow-2xl'>
                                                        <img className='w-full h-full object-cover object-center z-0 rounded-lg' src={item.profile.photoURL} alt={ item.nameCompany + " photo" } />
                                                    </div>
                                                    <h1 className='text-xl text-principal font-semibold'>{item.profile.nameCompany}</h1>
                                                </div>
                                            </NavLink>
                                        )
                                    })
                                }
                            </div>
                        )
                    }
                </div>



                <button >
                    <IonIcon icon={ filterOutline } size="large" color="light" />
                </button>
            </div>

            <div className="right-side px-2">

                <div className="profile-view profile-view-loading cursor-default">
                    <div className='userimg mx-1 loading-imgbx cursor-default'>
                        <img src={ dataUser.imageProfile } alt="Avatar" className='cursor-default' />
                    </div>

                    <div className='mx-1 name-info'>
                        <p className='m-0 text-principal capitalize font-extrabold '>{ currentUser.displayName }</p>
                        {
                            loadingUser && (
                                currentUser.emailVerified ? (
                                    <div className='flex flex-row gap-x-1 justify-center cursor-default' title='verificado' >
                                        <i className="bi bi-check-circle-fill text-green-500"></i>
                                        <p className='m-0 email'>{ dataUser.email }</p>
                                    </div>
                                ) : (
                                    <div className='flex flex-row gap-x-1 justify-center cursor-default' title='no verificado' >
                                        <i className="bi bi-x-circle-fill text-red-500"></i>
                                        <p className='m-0 email'>{ dataUser.email }</p>
                                    </div>
                                )
                            )
                        }
                    </div>

                    <div className='ps-2 mx-1 button-up'>
                        <button id='button-profile-config' className='' onClick={openSettingsProfile} >
                            <IonIcon icon={ chevronDownOutline } />
                        </button>
                    </div>
                </div>

                <div id="dropdown">
                    <Link to="/settings" ><AiOutlineSetting className='icon' /><p>Configuracion</p></Link>
                    <Link to="/settings/payment" ><MdPayment className='icon' /><p>Metodos de Pago</p></Link>
                    <Link to="/settings/billing"><FaFileInvoiceDollar className="icon"/><p>Datos de Facturacion</p></Link>
                    <Link to="/settings/help" ><TbHelp className='icon' /><p>Soporte</p></Link>
                </div>
            </div>
        </div>
    )
}