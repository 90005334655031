// Path: node_modules\react-timestamp\dist\values.js
const getTimeAgo = (e, t = new Date) => {
    const r = (e, t) => {
        switch (e) {
            case "second":
            case "minute":
            case "hour":
            case "day":
            case "week":
            case "month":
            case "year":
                return t + " " + e + (1 == t ? "" : "s");
            case "d_ago":
                return t + " ago";
            case "in_d":
                return "in " + t;
            default:
                return e
        }
    };

    const result = Math.floor((t.getTime() - e) / 1e3);
    
    if (result < 60) return r("just now", result);
    if (result < 3600) return r("minute", Math.floor(result / 60));
    if (result < 86400) return r("hour", Math.floor(result / 3600));
    if (result < 604800) return r("day", Math.floor(result / 86400));
    if (result < 2592e3) return r("week", Math.floor(result / 604800));
    if (result < 31536e3) return r("month", Math.floor(result / 2592e3));
    return r("year", Math.floor(result / 31536e3));
}
export default getTimeAgo;