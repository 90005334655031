import SideBar from "../components/SideBar";
import { FaFileInvoiceDollar } from "react-icons/fa";
import { BsFillHouseDoorFill, BsFillPersonFill, BsFillTelephoneFill } from "react-icons/bs";
import { HiMail } from "react-icons/hi";
import { BiSolidPencil } from "react-icons/bi";
import { RiDeleteBin6Fill } from "react-icons/ri";
import { useState } from "react";

const dataBilling = {
    name: "Casa",
    person: {
        name: "Perla Maribel Villarreal Tovias"
    },
    rfc: "VITP6707053J0",
    address: {
        street: "Valle de Mexico",
        number: 18,
        interior: 0,
        colony: "Valle Alto",
        city: "Matamoros",
        cp: 87360,
        state: "Tamaulipas"
    },
    phone: 8688283313,
    email: "maribelvillrreal@hotmail.es",
    fiscalRegime: 612,
    useCFDI: "G03"
}

export default function Billing() {
    const [onEdit, setOnEdit] = useState(false)
    const [onDelete, setOnDelete] = useState(false)

    const handleEdit = () => { setOnEdit(true) }
    const handleDelete = () => { setOnDelete(true) }

    return (
        <SideBar>
            <div className="md:flex-1 md:overflow-y-auto flex flex-col p-4">
                <div className="flex flex-row items-center gap-x-2 text-principal font-bold">
                    <FaFileInvoiceDollar />
                    <h1 className="text-xl">Mis direcciones de facturación</h1>
                </div>

                <div className="flex flex-col border rounded-lg w-fit">
                    <div className="flex flex-col py-4">
                        <div className="p-3 flex flex-col gap-y-5">
                            <h1 className="text-principal font-extrabold text-xl"><b>{ dataBilling.name }</b></h1>

                            <div className="border"></div>
                            
                            <div className="flex flex-row items-center gap-x-2">
                                <BsFillPersonFill    className="text-xl text-principal"/>
                                <span>{ dataBilling.person.name }</span>
                            </div>

                            <div className="flex flex-row items-center gap-x-2">
                                <FaFileInvoiceDollar className="text-xl text-principal"/>
                                <span><b className="font-bold">RFC: </b>{ dataBilling.rfc }</span>
                            </div>

                            <div className="flex flex-row gap-x-2">
                                <BsFillHouseDoorFill className="text-xl text-principal"/>
                                <div className="flex flex-col gap-y-1">
                                    <span><b className="font-bold">Calle: </b>{ `${ dataBilling.address.street } ${ dataBilling.address.number } Int ${ dataBilling.address.interior }` }</span>
                                    <span><b className="font-bold">Colonia: </b>{ dataBilling.address.colony }</span>
                                    <span><b className="font-bold">Del. </b>{ dataBilling.address.city }</span>
                                    <span><b className="font-bold">C.P. </b>{ dataBilling.address.cp }</span>
                                    <span><b className="font-bold">Estado: </b>{ dataBilling.address.state }</span>
                                </div>
                            </div>

                            <div className="flex flex-row gap-x-2">
                                <BsFillTelephoneFill className="text-xl text-principal"/>
                                <span><b className="font-bold">Tel. </b>{ dataBilling.phone }</span>
                            </div>

                            <div className="flex flex-row gap-x-2">
                                <HiMail className="text-xl text-principal"/>
                                <div className="flex flex-col gap-y-1">
                                    <span><b className="font-bold">Correo: </b>{ dataBilling.email }</span>
                                    <span><b className="font-bold">Regimen fiscal: </b>{ dataBilling.fiscalRegime }</span>
                                    <span><b className="font-bold">Uso CFDI: </b>{ dataBilling.useCFDI }</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w-full flex flex-row border-t">
                        <button onClick={handleEdit} className="w-full py-2 border-r-[0.5px] flex flex-row justify-center items-center gap-x-1"><BiSolidPencil className="text-principal"/><span>Editar</span></button>
                        <button onClick={handleDelete} className="w-full py-2 border-l-[0.5px] flex flex-row justify-center items-center gap-x-1"><RiDeleteBin6Fill className="text-principal"/><span>Eliminar</span></button>
                    </div>
                </div>
            </div>
        </SideBar>
    )
}