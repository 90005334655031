//  Toast
import { toast, ToastContainer } from 'react-toastify';
import "react-toastify/dist/ReactToastify.css"

//  Variable
let timeLimit = 5000;
let theme = "light";
let positionNotification = "bottom-right";

export const setNotificationError = (message) => {
    toast.error(message, {
        position: positionNotification,
        autoClose: timeLimit,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: theme,
    });
}

export const setNotificationSuccess = (message) => {
    toast.success(message, {
        position: positionNotification,
        autoClose: timeLimit,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: theme,
    });
}

export const setNotificationInfo = (message) => {
    toast.info(message, {
        position: positionNotification,
        autoClose: timeLimit,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: theme,
    });
}

export const setNotificationWarning = (message) => {
    toast.warning(message, {
        position: positionNotification,
        autoClose: timeLimit,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: theme,
    });
}

export const ConfigToast = () => {
    return (
        <ToastContainer
            position={ positionNotification }
            limit={5}
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="light"
        />
    )
}