//  React
import { useEffect, useState } from "react";
import { useContext } from "react";

import SideBar from "../components/SideBar";
import { IonIcon } from '@ionic/react'
import { addCircleOutline } from "ionicons/icons";

import "../scss/settings.scss";

import PhoneInput from "react-phone-input-2";

import { motion, AnimatePresence } from "framer-motion";

import { DataContext } from "../context/DataContext";
import { updatePasswordUser, updateUser, sendEmailVerify } from "../auth/func/authUser";
import { AuthContext } from "../context/AuthContext";
import formattedValue from "../func/filter";

const ErrorAlert = ({ text }) => {
    return (
        <div className="flex flex-row items-center w-full gap-x-1 text-[#fc526a]" >
            <i className="bi bi-exclamation-triangle-fill text-base"></i>
            <span className="text-sm" >{text}</span>
        </div>
    )
}

export default function Settings() {
    const [err, setErr] = useState(0)

    //  AuthContext
    const { userData, loadingData } = useContext(DataContext)
    const { currentUser } = useContext(AuthContext)

    //  PhotoUser
    const [photoUser, setPhotoUser] = useState(null)
    const AvatarImageNotFound = process.env.PUBLIC_URL + "/images/users/notuser.png"

    //  States
    const [formChangePassword, setFormChangePassword] = useState({
        actualPassword: '',
        newPassword: '',
        confirmPassword: ''
    })
    const handleChangePassword = () => {
        let res = updatePasswordUser(formChangePassword)
        console.log(res);
        if (res.error) {
            setModalChangePassword(false)
        }
    }
    const changeValue = (name, e) => {
        setFormChangePassword({
            ...formChangePassword,
            [name]: e.target.value
        })
    }


    const [modalChangePassword, setModalChangePassword] = useState(false)
    const [form, setForm] = useState({
        name: '',
        lastName: '',
        phone: '',
        address: '',
        profileImage: '',
    });

    //  Handle Image
    const handleImage = (e) => {
        setPhotoUser(URL.createObjectURL(e.target.files[0]))
        setForm({
            ...form,
            profileImage: e.target.files[0]
        })
    }

    //  HanleSubmit
    const handleSubmit = () => {
        if (form.name.trim() === '') {
            updateUser(form, userData, currentUser);
        } else if (form.name.length < 6) {
            return setErr(1)
        } else {
            updateUser(form, userData, currentUser);
        }

        setForm({
            name: '',
            phone: '',
            address: '',
            profileImage: '',
        });
    };

    //  FormatPhone
    const formatPhone = (phone) => {
        let formattedNumber
        const { length } = phone.slice(-10)

        const regex = () => phone.slice(-10).replace(/[^0-9]/g, '')
        const areaCode = () => `(${regex().slice(0, 3)})`
        const firstSix = () => `${areaCode()} ${regex().slice(3, 6)}`
        const trailer = (start) => `${regex().slice(start, regex().length)}`

        if (length < 3) {
            formattedNumber = regex();
        } else if (length === 4) {
            formattedNumber = `${areaCode()} ${trailer(3)}`;
        } else if (length === 5) {
            formattedNumber = `${areaCode().replace(")", "")}`;
        } else if (length > 5 && length < 9) {
            formattedNumber = `${areaCode()} ${trailer(3)}`;
        } else if (length >= 10) {
            formattedNumber = `${firstSix()}-${trailer(6)}`;
        }

        return '+' + phone.slice(0, -10) + ' ' + formattedNumber;
    }


    useEffect(() => {
        //  Check Display Name
        console.log(form.name)
        console.log(currentUser)

    }, [form.name])

    useEffect(() => {
        if (err === 1 && form.name !== '') { setErr(0); return; }
    }, [form])


    if (loadingData) {
        return (
            <SideBar>
                <div className="content">
                    <form>
                        <h3>Configuracion de cuenta.</h3>

                        <div className="content-options">
                            <div className="image-content">
                                <input type="file" name="image" id="File" style={{ display: 'none' }} onChange={handleImage} />
                                <label htmlFor="File">
                                    <img src={
                                        loadingData ? (
                                            photoUser ? photoUser : (
                                                userData.profile.photo ? userData.profile.photo : AvatarImageNotFound
                                            )
                                        ) : (
                                            AvatarImageNotFound
                                        )
                                    } alt="imageUser" />

                                    <div className="icon-image">
                                        <IonIcon icon={addCircleOutline} />
                                    </div>
                                </label>
                            </div>

                            <div className="form-content">
                                <div className="form-group col-span-2">
                                    <label htmlFor="name">Nombre de usuario</label>
                                    <input value={form.name} type="text" placeholder={currentUser.displayName || 'nombre'} onChange={(e) => {
                                        setForm({ ...form, name: formattedValue(e.target.value.toLowerCase()) });
                                    }} />
                                    {err === 1 && <ErrorAlert text={'El nombre debe tener al menos 6 caracteres.'} />}
                                </div>


                                <div className="form-group">
                                    <label htmlFor="name">Direccion</label>
                                    <input value={form.address} type="text" placeholder={userData.profile.address ? userData.profile.address : 'direccion'} onChange={(e) => {
                                        setForm({
                                            ...form,
                                            address: e.target.value
                                        })
                                    }} />
                                </div>


                                <div className="form-group">
                                    <label htmlFor="name">Celular</label>
                                    <PhoneInput
                                        value={form.phone}
                                        placeholder={userData.profile.phone ? formatPhone(userData.profile.phone) : 'telefono'}
                                        autocomplete="off"
                                        onChange={(e) => {
                                            setForm({
                                                ...form,
                                                phone: e
                                            })
                                        }
                                        } />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="name">Email</label>
                                    <button type="button" className={`
                                        w-full h-full bg-[#364670] rounded-lg
                                        text-white text-[1.1rem]
                                        hover:shadow-2xl transition-all duration-300
                                    `} onClick={sendEmailVerify} >{
                                            currentUser.emailVerified ? 'Email verificado' : 'Verificar email'
                                        }</button>
                                </div>

                                <div className="form-group relative">
                                    <label htmlFor="name">Cambiar contraseña</label>
                                    <button type="button" className={`
                                        w-full h-full bg-[#364670] rounded-lg
                                        text-white text-[1.1rem]
                                        hover:shadow-2xl transition-all duration-300
                                    `} onClick={() => setModalChangePassword(!modalChangePassword)} >Cambiar contraseña</button>
                                </div>
                            </div>
                        </div>

                        <div className="button-save">
                            <button onClick={handleSubmit} type="button" className={`
                                hover:shadow-2xl transition-all duration-300
                            `}>Guardar cambios</button>
                        </div>
                    </form>
                </div>

                <AnimatePresence>
                    {
                        modalChangePassword && (
                            <motion.div
                                initial={{ opacity: 0 }}
                                animate={{ opacity: 1 }}
                                exit={{ opacity: 0 }}
                                className="left-0 top-0 flex items-center justify-center w-screen h-screen bg-black/40 fixed" >
                                <div className="rounded-[5px] bg-white min-w-[600px] max-w-[600px] h-auto p-6 flex flex-col items-center justify-center gap-y-8" >
                                    <div className="w-full border-b border-gray-300 pb-1" >
                                        <h2 className="m-0 text-[#364670] font-semibold" >Cambia tu contraseña</h2>
                                    </div>

                                    <form className="flex flex-col gap-y-4 w-full" >
                                        <div className="form-group w-full flex flex-col gap-y-2">
                                            <label htmlFor="name" className="text-[#364670] text-[1.1rem] font-medium">Contraseña actual</label>
                                            <input className={`
                                            px-4 py-2 border-[1.5px] text-[1.2rem] text-[#333] outline-none
                                            transition-all duration-300 rounded-lg
                                            focus:opacity-80 focus:shadow-2xl
                                            placeholder-[#ced4da] placeholder:text-lowercase
                                        `} value={formChangePassword.actualPassword} onChange={(e) => { changeValue('actualPassword', e) }} type="password" placeholder="contraseña actual" />
                                        </div>

                                        <div className="form-group w-full flex flex-col gap-y-2">
                                            <label htmlFor="name" className="text-[#364670] text-[1.1rem] font-medium">Nueva contraseña</label>
                                            <input className={`
                                            px-4 py-2 border-[1.5px] text-[1.2rem] text-[#333] outline-none
                                            transition-all duration-300 rounded-lg
                                            focus:opacity-80 focus:shadow-2xl
                                            placeholder-[#ced4da] placeholder:text-lowercase
                                        `} value={formChangePassword.newPassword} onChange={(e) => { changeValue('newPassword', e) }} type="password" placeholder="nueva contraseña" />
                                        </div>

                                        <div className="form-group w-full flex flex-col gap-y-2">
                                            <label htmlFor="name" className="text-[#364670] text-[1.1rem] font-medium">Confirmar contraseña</label>
                                            <input className={`
                                            px-4 py-2 border-[1.5px] text-[1.2rem] text-[#333] outline-none
                                            transition-all duration-300 rounded-lg
                                            focus:opacity-80 focus:shadow-2xl
                                            placeholder-[#ced4da] placeholder:text-lowercase
                                        `} value={formChangePassword.confirmPassword} onChange={(e) => { changeValue('confirmPassword', e) }} type="password" placeholder="confirmar contraseña" />
                                        </div>
                                    </form>

                                    <div className="flex justify-between flex-row w-full" >
                                        <button type="button" className={`
                                        bg-red-600 text-white rounded-[6px] px-4 py-1 text-lg
                                        hover:shadow-2xl transition-all duration-300
                                        hover:bg-red-700
                                    `} onClick={() => setModalChangePassword(false)} >Cancel</button>
                                        <button type="button" className={`
                                        bg-green-600 text-white rounded-[6px] px-4 py-1 text-lg
                                        hover:shadow-2xl transition-all duration-300
                                        hover:bg-green-700
                                    `} onClick={handleChangePassword} >Confirmar</button>
                                    </div>
                                </div>
                            </motion.div>
                        )
                    }
                </AnimatePresence>
            </SideBar>
        );
    }
}