import { useLocation } from 'react-router-dom';
import { useContext, useEffect, useState } from "react"
import { auth } from '../config/firebase-config';
import { applyActionCode } from 'firebase/auth';
import { AuthContext } from '../context/AuthContext';
import { Navigate } from 'react-router-dom';
import Loading from "../components/Loading"
import { setNotificationError, setNotificationSuccess } from '../func/setNotification';
const TemplateComponent = () => {
    return (
        <div className='h-screen flex flex-col justify-center'>
            <div className='flex flex-col items-center justify-center gap-8'>
                <div className='flex flex-col items-center w-full'>
                    <h1 className='text-principal font-semibold text-4xl'>Espere...</h1>
                    <span className='text-[#9d9d9d]'>Estamos cargando la pagina espere porfavor.</span>
                </div>
                <Loading />
            </div>
        </div>
    )
}


export default function TestingPage() {
    const { currentUser } = useContext(AuthContext)
    const [response, setResponse] = useState(null)

    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const actionCode = searchParams.get('oobCode');

    const emailVerify = async () => {
        try {
            await applyActionCode(auth, actionCode).then(() => {
                setNotificationSuccess("Se ha verificado correctamente.")
            })
        } catch (error) {
            if (error.code === 'auth/invalid-action-code') return setNotificationError("Ups, parece que ese link ha caducado o ya se ha usado.")
            setNotificationError("Se ha producido un error.")
        }
    };

    useEffect(() => {
        emailVerify()
    }, [])

    useEffect(() => {
        //  Check user
        if (currentUser) {
            setResponse(<Navigate to={"/home"} />)
        } else {
            setResponse(<Navigate to={"/login"} />)
        }
    }, [currentUser])
    return response ? response : <TemplateComponent />
}