//  Styles
import "../scss/favorites.scss";

//  Components
import SideBar from "../components/SideBar";
import { useContext, useEffect, useState } from "react";
import ViewService from "../components/ViewService";
import ApiFavorites from "../api/favorites.api";
import { AnimatePresence } from "framer-motion";
import { AuthContext } from "../context/AuthContext";
import FavoriteServices from "../components/FavoriteServices";


export default function Favorites() {
    const [onStar, setOnStar] = useState(false);
    const [viewOpenService, setViewOpenService] = useState(false);
    const [serviceData, setServiceData] = useState(null);
    const [favoritesServices, setFavoritesServices] = useState([]);
    const { tokenUser } = useContext(AuthContext);
    const imageService = process.env.PUBLIC_URL + "/images/services/odontologia.png";

    useEffect(() => {
        const unSub = ApiFavorites.get("/", {
            headers: {
                'Authorization': `Bearer ${tokenUser}`,
            },
        }).then((res) => {
            setFavoritesServices(res.data.data.services);
            console.log(res.data.data.services);
        });

        return () => unSub;
    }, [])

    const handleDelteFavorites = (serviceId) => {
        ApiFavorites.delete('/', {
            data: {
                service: serviceId,
            }
        }, {
            headers: {
                'Authorization': `Bearer ${tokenUser}`,
            },
        }).then((res) => {
            setFavoritesServices(res.data.data.services);
        })
    }

    return (
        <SideBar>
            <div id="favorites">
                <div className="content-favorites">
                    {
                        favoritesServices.map((service) => (
                            <div key={service} >
                                <FavoriteServices serviceId={service} callback={handleDelteFavorites} />
                            </div>
                        ))
                    }
                </div>
            </div>
            <AnimatePresence>
                {viewOpenService && (
                    <ViewService
                        serviceData={serviceData}
                        closeViewService={() => {
                            setViewOpenService(false);
                            setServiceData(null);
                        }}
                        statusViewService={viewOpenService}
                        isOwner={false}
                    />
                )}
            </AnimatePresence>
        </SideBar>
    )
}