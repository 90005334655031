//  React
import { useState } from "react";
import { useEffect } from "react";
import { createContext } from "react";
import React from "react";

//  Firebase
import { auth as authFirebase, database as databaseFirebase } from "../config/firebase-config";
import { onAuthStateChanged } from "firebase/auth";
import { onDisconnect, onValue, push, ref, serverTimestamp, set } from "firebase/database";

//  ContextAuth
export const AuthContext = createContext();
export const AuthProvider = ({ children }) => {
    const [currentUser, setCurrentUser] = useState(null);
    const [loadingUser, setLoadingUser] = useState(false);
    const [tokenUser, setTokenUser] = useState(null);

    

    useEffect(() => {
        try {
            onAuthStateChanged(authFirebase, (user) => {
                setCurrentUser(user)
                setLoadingUser(true)

                if(user) {
                    user.getIdToken().then((token) => {
                        setTokenUser(token)
                        console.log(token)
                    })
                    const onOnlineRef = ref(databaseFirebase, `users/${user.uid}/connections`);
                    const lastOnlineRef = ref(databaseFirebase, `users/${user.uid}/lastOnline`);
                    
                    //  https://firebase.google.com/docs/database/web/offline-capabilities?hl=es-419
                    const connectedRef = ref(databaseFirebase, ".info/connected");
                    onValue(connectedRef, (snapshot) => {
                        if (snapshot.val() === true) {
                            const conn = push(onOnlineRef);
                            onDisconnect(conn).remove();
                            set(conn, true);
                            onDisconnect(lastOnlineRef).set(serverTimestamp());
                        }
                    });
                }
            })
        } catch (error) {
            setLoadingUser(true)
        }
    }, []);

    return (
        <AuthContext.Provider value={{ currentUser, loadingUser, tokenUser }}>
            {children}
        </AuthContext.Provider>
    );
}