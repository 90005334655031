//  Styles
import "./scss/ViewService.scss";

//  React
import { useState, useEffect } from 'react';

//  Icons
import { FaStar } from "react-icons/fa";

//  FrameMotion
import { motion } from "framer-motion";

//  Colors
const colors = {
    orange: "#FFBA5A",
    grey: "#A9A9A9",
};


export default function ViewService({ serviceData, nameCompany, closeViewService, statusViewService, isOwner }) {
    //  Stars
    const stars = Array(5).fill(0);
    console.log(0 / 0);

    return (
        <div id="view-service">
            <motion.div className="modal"    
                animate={{ opacity: 1, delay: 0.5, duration: 0.5 }}
                initial={{ opacity: 0, elay: 0.5, duration: 0.5 }}
                exit={{ opacity: 0, elay: 0.5, duration: 0.5 }}
            
            >
                <div className="view-service__header">
                    <div className="imgbx">
                        <img src={serviceData.data.image} alt="service" />
                    </div>

                    <div className="view-service__header__info">
                        <h3 className="text-principal text-2xl font-bold" >{serviceData.data.name}</h3>
                        <div className="footer">
                            <p className="name" >{'Work It'}</p>
                            <div id="rating">
                                <div>
                                    {stars.map((_, index) => {
                                        return (
                                            <FaStar
                                                key={index}
                                                size={20}
                                                color={
                                                    Math.round(
                                                        serviceData.data.raiting.stars / serviceData.data.raiting.count
                                                    ) > index
                                                        ? colors.orange
                                                        : colors.grey
                                                }
                                            />
                                        );
                                    })}
                                </div>

                                <p>({serviceData.data.raiting.count})</p>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="view-service__body">
                    <div className="view-service__body__info">
                        <h4 className="text-xl text-principal text-bold" >Descripción</h4>
                        <p className="description" >{serviceData.data.description}</p>
                    </div>

                    <div className="view-service__body__price">
                        <h4 className="text-xl text-principal text-bold">Precio</h4>
                        <p className="price" >{'MXN $' + serviceData.data.price}</p>
                    </div>
                </div>

                <div className="view-service__footer">
                    <button className="cancel" onClick={closeViewService} >Volver</button>
                    {
                        !isOwner && (
                            <button className="save" >Pagar</button>
                        )
                    }
                </div>
            </motion.div>
        </div>
    )
}