import { useContext, useEffect, useState } from "react"
import ApiCompany from "../api/company.api"
import { AuthContext } from "../context/AuthContext"

function containsAnyLetters(str) {
    return /[a-z]/i.test(str);
}

const SearchBarInput = ({ setValues }) => {
    const { tokenUser } = useContext(AuthContext)
    const [searchText, setSearchText] = useState('')
    const handle = (e) => {
        if (e.target.value.length === 0) {
            setValues([])
            setSearchText('')
        } else {
            setSearchText(e.target.value)
        }
    }

    useEffect(() => {
        if (searchText.length !== 0 && containsAnyLetters(searchText)) {
            ApiCompany.get('/search', {
                params: {
                    q: searchText
                },
                headers: {
                    Authorization: `Bearer ${tokenUser}`
                }
            }).then((data) => {
                setValues(data.data.data)
            }).catch((err) => {
                console.log(err)
            })
        } else {
            setValues([])
        }
    }, [!searchText, searchText])


    return (
        <input value={searchText} onChange={handle} type="text" placeholder="buscar una empresa..." className='p-2 outline-none bg-transparent text-[22px] text-black w-full placeholder:text-[#9D9D9D]' />
    )
}

export default SearchBarInput