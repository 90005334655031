//  React
import { useState } from "react";
import { useEffect } from "react";
import { createContext } from "react";

//  Firebase
import { db } from "../config/firebase-config";
import { doc, onSnapshot } from 'firebase/firestore'

//  ContextAuth
import { AuthContext } from "./AuthContext";
import { useContext } from "react";
import ApiConnect from "../api/connect.api";
import { DataContext } from "./DataContext";

//  ContextCompany
export const CompanyContext = createContext();
export const CompanyProvider = ({ children }) => {
    const { currentUser, loadingUser, tokenUser } = useContext(AuthContext);
    const { userData, loadingData } = useContext(DataContext);
    const [loadingCompany, setLoadingCompany] = useState(false);
    const [companyData, setCompanyData] = useState(null);
    const [statusAccount, setStatusAccount] = useState(null);
    const [accountCompany, setAccountCompany] = useState(null);
    const [subscriptionStatus, setSubscriptionStatus] = useState(null);

    useEffect(() => {
        if (currentUser !== null) {
            if (loadingUser) {
                console.log("loadingUser");
                if(loadingData) {
                    console.log("loadingData");
                    if(userData.accountType === 2) {
                        console.log("companyVerified === 2");
                        ApiConnect.get("/onboarding", {
                            headers: {
                                Authorization: `Bearer ${tokenUser}`
                            }
                        }).then((response) => {
                            if(response.status === 200){
                                setAccountCompany(response.data)
                                setStatusAccount(response.data.data.account.payouts_enabled);
                            }
                        }).catch((error) => {
                            if(error.status === 503){
                                setStatusAccount(false);
                            }
                        })
        
                        const unSub = onSnapshot(doc(db, "companies", currentUser.uid), (doc) => {
                            setCompanyData(doc.data());
                            setLoadingCompany(true);
                        })
                        
        
                        return () => unSub();   
                    } else {
                        setCompanyData(null);
                        setLoadingCompany(true);
                    }
                }
            }
        }
    }, [currentUser, loadingData]);

    return (
        <CompanyContext.Provider value={{ companyData, loadingCompany, statusAccount, accountCompany, subscriptionStatus }}>
            {children}
        </CompanyContext.Provider>
    );
}
