//  React
import React from 'react'
import { createRoot } from 'react-dom/client'

//  Components
import App from './App'

//  Styles
import 'bootstrap-icons/font/bootstrap-icons.css'
import './index.css'

const rootElement = document.getElementById('root')
const root = createRoot(rootElement)


root.render(
    //    <React.StrictMode>
    <App />
    //    </React.StrictMode>
)
