//  Styles
import '../scss/notfound.scss';

export default function NotFound() {
    return (
        <div id="not-found">
            <div className="not-found__container">
                <h1 className="not-found__title">404</h1>
                <p className="not-found__subtitle">Este es un error.</p>
            </div>
        </div>
    )
}