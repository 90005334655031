//  React
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { useContext, useEffect, useState } from 'react'


//  Components
import ComponentImageUpload from './ComponentImageUpload'
import ComponentInput from './ComponentInput'
import ComponentButton from './ComponentButton'
import ApiServices from '../api/services.api'
import { AuthContext } from '../context/AuthContext'
import { setNotificationError, setNotificationSuccess } from '../func/setNotification'

const config = {
    maxLenght: {
        nameService: 30,
        descriptionService: 440
    }
}

//  Render
const AddServiceComponent = ({ onAddService, setOnAddService }) => {
    const { tokenUser } = useContext(AuthContext)
    const [photoService, setPhotoService] = useState(null)
    const [nameService, setNameService] = useState('')
    const [descriptionService, setDescriptionService] = useState('')
    const [priceService, setPriceService] = useState(0)
    const [previewImage, setPreviewImage] = useState(null)
    const [onLoading, setOnLoading] = useState(false)
    const handleImage = (e) => {
        setPreviewImage(URL.createObjectURL(e.target.files[0]))
        setPhotoService(e.target.files[0])
    }

    const handleNewService = async () => {
        setOnLoading(true)
        const formData = new FormData()
        formData.append('name', nameService)
        formData.append('description', descriptionService)
        formData.append('price', priceService)
        formData.append('image', photoService)
        ApiServices.post("/", formData, {
            headers: {
                "Content-Type": "multipart/form-data",
                Authorization: `Bearer ${tokenUser}`
            }
        }).then((res) => {
            if (res.status === 201) {
                setNotificationSuccess('Servicio creado correctamente')
                setOnLoading(false)
                onAddService && setOnAddService(false)
            } else {
                setOnLoading(false)
            }
        }).catch((err) => {
            setNotificationError('Error al crear el servicio')
            setOnLoading(false)
        })
    }
    

    return (
        <div className="w-full bg-[#ffffff] phone:rounded-lg phone:shadow-4xl flex flex-col px-4 phone:p-4 phone:max-w-md mx-auto md:w-md">
            <div className="relative flex flex-row items-center pb-2 border-b">
                <div className="w-full absolute">
                    <button onClick={() => setOnAddService(false)} className="text-slate-800 font-semibold my-auto"><i className="bi bi-arrow-left-short text-[2rem]"></i></button>
                </div>
                <h1 className="w-full text-2xl text-[#30313D] text-center font-semibold">Añadir un Servicio</h1>
            </div>

            <div className='flex flex-col py-10 gap-6'>
                <div title='Foto del servicio'>
                    <ComponentImageUpload name="image-register-complete" handleImage={handleImage} onValue={previewImage} />
                </div>

                <div>
                    <label htmlFor="name-service"><span className="w-full text-[#1a1f36] text-sm font-semibold" >Nombre del servicio</span></label>
                    <ComponentInput maxLenght={config.maxLenght.nameService} placeholder="nombre del servicio" name={"name-service"} setValue={setNameService} selectedValue={nameService} />
                </div>

                <div>
                    <label htmlFor="description-service"><span className="w-full text-[#1a1f36] text-sm font-semibold" >Descripción del servicio</span></label>
                    <ComponentInput maxLenght={config.maxLenght.descriptionService} type="textarea" placeholder="descripción del servicio" name={"description-service"} setValue={setDescriptionService} selectedValue={descriptionService} />
                </div>

                <div>
                    <label htmlFor="price-service"><span className="w-full text-[#1a1f36] text-sm font-semibold" >Precio del servicio</span></label>
                    <ComponentInput type="price" placeholder="precio del servicio" name={"price-service"} setValue={setPriceService} selectedValue={priceService} />
                </div>
            </div>

            <div className='w-full flex flex-col'>
                <ComponentButton onLoading={onLoading} text="Publicar" callback={handleNewService} />
            </div>
        </div>
    )
}


export default AddServiceComponent 