//  Styles
import './scss/EditCompany.scss'

//  Country
import { Country, State, City } from 'country-state-city';

//  React
import { useEffect, useState } from 'react';
import PhoneInput from "react-phone-input-2";

//  Icons
import { IonIcon } from '@ionic/react'
import { addCircleOutline } from 'ionicons/icons'
import { BsFacebook, BsLinkedin } from 'react-icons/bs'
import { MdLocationPin } from 'react-icons/md'

//  Components
import Selector from './Selector';

//  FrameMotion
import { AnimatePresence, motion } from 'framer-motion'
import { updateCompany } from '../auth/func/authCompany';

const categoryData = [
    { name: 'Escoje tu categoria', value: 0 },
    { name: 'Leyes', value: 1 },
    { name: 'Comunicacion', value: 2 },
    { name: 'Salud', value: 3 },
    { name: 'Industrial', value: 4 },
    { name: 'Oficios', value: 5 },
    { name: 'Publicidad', value: 6 },
    { name: 'Construccion', value: 7 },
    { name: 'Educativo', value: 8 },
    { name: 'Gatronomia', value: 9 },
    { name: 'Programacion', value: 10 },
    { name: 'Finanzas', value: 11 },
    { name: 'Otros', value: 12 },
]

//  Modal
export default function EditCompany({ closeModal, statusModel, dataActual }) {
    const [isOpen, setIsOpen] = useState(false);
    const [lengthDescription, setLengthDescription] = useState(0);
    
    //  Company Image
    const AvatarImageNotFound = process.env.PUBLIC_URL + '/images/users/notcompany.png'
    const [imageProfile, setImageProfile] = useState(null)

    //  Countries
    let countryData = Country.getAllCountries()
    countryData = [
        countryData[141]
    ]


    //  useState
    const [lengthName, setLengthName] = useState(0)

    const [stateData, setStateData] = useState([]);
    const [cityData, setCityData] = useState([]);

    const [country, setCountry] = useState(countryData[0]);
    const [state, setState] = useState();
    const [city, setCity] = useState();

    const [phoneNumber, setPhoneNumber] = useState('')
    //  Form
    const [form, setForm] = useState({
        name: '',
        phoneNumber: '',
        country: '',
        state: '',
        city: '',
        description: '',
        companyImage: '',
        category: '',
        facebook: '',
        instagram: '',
        location: '',
        twitter: '',
    })

    const [category, setCategory] = useState(categoryData[0])

    //  useEffect
    useEffect(() => {
        setForm({
            ...form,
            phoneNumber: phoneNumber,
            country: country?.name,
            state: state?.name,
            city: city?.name,
            companyImage: imageProfile == null ? '' : imageProfile,
            category: categoryData[0].name == category?.name ? '' : category?.value,
        })
    }, [phoneNumber, country, state, city, category, imageProfile])

    useEffect(() => {
        if (country) {
            setStateData(State.getStatesOfCountry(country?.isoCode));
        }
    }, [country]);

    useEffect(() => {
        if (state) {
            setCityData(City.getCitiesOfState(country?.isoCode, state?.isoCode));
        }
    }, [state, country]);

    useEffect(() => {
        stateData && setState(stateData[0]);
    }, [stateData]);

    useEffect(() => {
        cityData && setCity(cityData[0]);
    }, [cityData]);


    //  limits
    const limits = {
        name: 18,
        description: 370
    }

    //  hanldeSubmit
    const handleSubmit = async () => {
        const res = await updateCompany(form, dataActual)
        if (res.error === false) {
            closeModal()
        }
    };

    const handleImage = (image) => {
        setImageProfile(image.target.files[0]);
        console.log(image)
    }

    //  useEffect description
    useEffect(() => {
        const description = document.getElementById('my-description');
        const limitDescription = document.getElementById('limit-description');
        const myName = document.getElementById('my-name');
        const limitName = document.getElementById('limit-name');

        if (lengthDescription >= limits.description) {
            description.classList.add('error');
            limitDescription.classList.add('error');
        } else {
            description.classList.remove('error');
            limitDescription.classList.remove('error');
        }

        if (lengthName >= limits.name) {
            myName.classList.add('error')
            limitName.classList.add('error')
        } else {
            myName.classList.remove('error')
            limitName.classList.remove('error')
        }
    }, [lengthDescription, lengthName])

    return (
        <div id="modal-container">
            <motion.div
                className='modal'
                animate={{ opacity: 1, delay: 0.5, duration: 0.5 }}
                initial={{ opacity: 0, elay: 0.5, duration: 0.5 }}
                exit={{ opacity: 0, elay: 0.5, duration: 0.5 }}

            >
                {
                    !isOpen && (
                        <motion.div className='modal'
                            animate={{ opacity: 1, delay: 0.5, duration: 0.5 }}
                            initial={{ opacity: 0, elay: 0.5, duration: 0.5 }}
                            exit={{ opacity: 0, elay: 0.5, duration: 0.5 }}
                        >
                            <div className="top-title">
                                <h3>Editar Empresa</h3>
                                <p>Edita tu perfil de empresa a tu gusto.</p>
                            </div>

                            <form>
                                <div className="form-group">
                                    <label htmlFor="name">Nombre de Empresa</label>
                                    <input
                                        id='my-name'
                                        maxLength={limits.name}
                                        type="text"
                                        className='form-control'
                                        placeholder='nombre...'
                                        onChange={(e) => {
                                            setLengthName(e.target.value.length)
                                            setForm({ ...form, name: e.target.value })
                                        }}
                                    />
                                    <p className='limit' id='limit-name' >{lengthName}/{limits.name}</p>
                                </div>

                                <div className="form-group phone">
                                    <label htmlFor="">Telefono</label>
                                    <PhoneInput placeholder={'+1 (123) 456 7890'} autocomplete="off" onChange={setPhoneNumber} />
                                </div>

                                <div className="form-country">
                                    <div className="form-group">
                                        <label htmlFor="country">Pais</label>
                                        <Selector
                                            data={countryData}
                                            selected={country}
                                            setSelected={setCountry}
                                            key={countryData}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="state">Estado</label>
                                        <Selector
                                            data={stateData}
                                            selected={state}
                                            setSelected={setState}
                                            key={stateData}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="city">Ciudad</label>
                                        <Selector
                                            data={cityData}
                                            selected={city}
                                            setSelected={setCity}
                                            key={cityData}
                                        />
                                    </div>
                                </div>


                                <div className="form-group">
                                    <label htmlFor="description">Descripcion de Empresa</label>
                                    <textarea
                                        onChange={(e) => {
                                            setLengthDescription(e.target.value.length)
                                            setForm({ ...form, description: e.target.value })
                                        }}
                                        className='form-control'
                                        placeholder='descripcion...'
                                        id='my-description'
                                        rows={4}
                                        maxLength={limits.description}
                                    ></textarea>
                                    <p className='limit' id='limit-description' >{lengthDescription}/{limits.description}</p>
                                </div>


                                <div className="buttons">
                                    <button type='button' className='btn btn-back' onClick={closeModal} >Cancelar</button>
                                    <button type='button' className="btn btn-save" onClick={() => setIsOpen(!isOpen)}>Siguiente</button>
                                </div>
                            </form>
                        </motion.div>
                    )
                }

                {
                    isOpen && (
                        <motion.div
                            animate={{ opacity: 1, delay: 0.5, duration: 0.5 }}
                            initial={{ opacity: 0, elay: 0.5, duration: 0.5 }}
                            exit={{ opacity: 0, elay: 0.5, duration: 0.5 }}
                        >
                            <form>
                                <div className="form-image">
                                    <input
                                        type="file"
                                        name='image'
                                        id='File'
                                        style={{ display: 'none' }}
                                        onChange={handleImage}
                                    />
                                    <label htmlFor="File">
                                        <img src={imageProfile == null ? AvatarImageNotFound : URL.createObjectURL(imageProfile)} alt="Avatar" />
                                        <div className="content-image">
                                            <IonIcon icon={addCircleOutline} />
                                        </div>
                                    </label>
                                    <h3>Cambia foto de Empresa.</h3>
                                </div>


                                <div className="form-group">
                                    <label htmlFor="category">Categoria</label>
                                    <Selector
                                        data={categoryData}
                                        selected={category}
                                        setSelected={setCategory}
                                        key={categoryData}
                                    />
                                </div>


                                <div className="form-group-social">
                                    <div className="form-group">
                                        <label htmlFor="facebook"><BsFacebook className='facebook icon' />Facebook</label>
                                        <input className='form-control' type="text" placeholder='facebook...'
                                            onChange={(e) => {
                                                setForm({ ...form, social: { ...form.social, facebook: e.target.value } })
                                            }}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="instagram"><div className="instagram"><span></span></div>Instagram</label>
                                        <input className='form-control' type="text" placeholder='instagram...'
                                            onChange={(e) => {
                                                setForm({ ...form, social: { ...form.social, instagram: e.target.value } })
                                            }}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="twitter"><BsLinkedin className='linkedin icon' />Linkedin</label>
                                        <input className='form-control' type="text" placeholder='linkedin...'
                                            onChange={(e) => {
                                                setForm({ ...form, social: { ...form.social, linkedin: e.target.value } })
                                            }}
                                        />
                                    </div>

                                    <div className="form-group">
                                        <label htmlFor="linkedin"><MdLocationPin className='location icon' />Location</label>
                                        <input className='form-control' type="text" placeholder='location...' />
                                    </div>
                                </div>

                                <div className="buttons">
                                    <button type='button' className='btn btn-back' onClick={() => setIsOpen(!isOpen)} >Volver</button>
                                    <button type='button' className='btn btn-save' onClick={handleSubmit} >Guardar</button>
                                </div>
                            </form>
                        </motion.div>
                    )
                }
            </motion.div>
        </div>
    )
}