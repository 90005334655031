import {
    Timestamp, arrayUnion, collection,
    doc, getDoc, getDocs, onSnapshot, setDoc, updateDoc,
} from "firebase/firestore";

//  React
import { useEffect, useState, useContext, useRef } from "react";

//  Components
import SideBar from "../components/SideBar";

//  Style
import "../scss/messages.scss";
import "../css/unreadMessages.css"

import { db, messageProvider, updateFile } from "../config/firebase-config";
import { AuthContext } from "../context/AuthContext";
import { ChatContext } from "../context/ChatContext";

import { v4 as uuidv4 } from "uuid";
import { DataContext } from "../context/DataContext";


const UserImage = process.env.PUBLIC_URL + "/images/users/user1.png";
const OtherUser = {
    id: 9999999,
    name: "Un",
    lastName: "usuario",
    photo: process.env.PUBLIC_URL + "/images/users/notuser.png",
    status: "Online",
};

const formatDate = (date) => {
    const month = [
        "enero", "febrero", "marzo", "abril", "mayo", "junio",
        "julio", "agosto", "septiembre", "octubre", "noviembre", "diciembre"
    ];

    const day = date.getDate();
    const monthName = month[date.getMonth()];
    const year = date.getFullYear();

    return `${day} de ${monthName} del ${year}`;
};


export default function Messages() {
    const messagesEndRef = useRef(null);
    const [openOptionsUser, setOpenOptionsUser] = useState(false);
    const [username, setUsername] = useState("");
    const [chats, setChats] = useState([]);
    const [users, setUsers] = useState([]);
    const [messages, setMessages] = useState([]);
    const [messagesLoading, setMessagesLoading] = useState(true);

    const [unreadMessages, setUnreadMessages] = useState(0);

    const openNav = () => {
        document.querySelector("#sidebar").classList.toggle("active");
    };

    //  Search
    const handleKey = (e) => {
        e.code === "Enter" && hanldeSearch();
    };
    const hanldeSearch = async () => {
        console.log(username);
    };

    //  OpenOptions
    const openOptions = () => {
        document.querySelector("#messages-users").classList.toggle("active");
    };

    const { currentUser } = useContext(AuthContext);
    const { userData } = useContext(DataContext)
    const { data, dispatch } = useContext(ChatContext);

    useEffect(() => {
        const getChats = () => {
            const unsub = onSnapshot(doc(db, "userChats", "channels"), (next) => {
                const chatData = next.data();
                const allChats = Object.entries(chatData ?? {});
                const chatIds =
                    allChats
                        .filter((x) => x[1]?.members.includes(currentUser.uid))
                        .map((x) => x[1]?.members.find((y) => y !== currentUser.uid)) ?? [];

                (async () => {
                    const data = await getDocs(collection(db, "users"));
                    const allUsers = data.docs.map((x) => x.data());

                    setChats(
                        allUsers
                            .filter((x) => chatIds.includes(x.uid))
                            .map((x) => ({
                                userInfo: x,
                                lastMessage: allChats.find((y) =>
                                    y[1]?.members.every((z) =>
                                        [currentUser.uid, x.uid].includes(z)
                                    )
                                )?.[1]?.lastMessage,
                                unreadMessages: allChats.find((y) =>
                                    y[1]?.members.every((z) =>
                                        [currentUser.uid, x.uid].includes(z)
                                    ))?.[1]?.unreadMessages,
                                currentUserUid: currentUser.uid
                            }))
                    );

                    setUsers(
                        allUsers.filter(
                            (x) => x.uid !== currentUser.uid && !chatIds.includes(x.uid)
                        )
                    );
                })();
            });

            return unsub;
        };

        currentUser.uid && getChats();

    }, [data?.chatId, currentUser.uid]);

    useEffect(() => {
        const unSub = onSnapshot(doc(db, "userChats", "channels"), (document) => {
            if (!document.exists()) {
                setDoc(doc(db, "userChats", "channels"), {});
                setMessages([]);
                return;
            }

            const docData = document.data();
            if (docData?.[data.chatId]?.messages) {
                setMessages(docData[data.chatId].messages);
                setMessagesLoading(false);
            } else {
                setMessages([]);
                setMessagesLoading(false);
            }
        });

        return () => {
            unSub();
        };
    }, [data.chatId, currentUser.uid]);

    useEffect(() => {
        messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
        console.log(messages);
        const unSub = async () => {
            const newMessages = messages.filter((x) => {
                console.log(x);
                if (x?.service && typeof (x.service) === "object") {
                    getDoc(doc(db, "onservice", x.onserviceID)).then((next) => {
                        const dataServiceon = next.data();
                        x.onservice = {
                            createdAt: new Date()
                        }
                    })
                }
            });

        }

        return () => {
            unSub();
        }
    }, [messages]);

    const [text, setText] = useState("");

    const hanldeSendImage = async (imageUser) => {
        const image = await updateFile(imageUser, imageUser.name);
        const date = Timestamp.now();
        const messageData = {
            id: uuidv4(),
            text,
            date,
            uid: currentUser.uid,
            img: image
        };
        const chatData = {
            members: [currentUser.uid, data.user.uid],
            messages: arrayUnion(messageData),
            lastMessage: {
                text,
                date,
                sender: currentUser.uid,
            }
        };

        if (!chats.find((x) => x.userInfo.uid === data.user.uid)) {
            setChats((prev) => [
                ...prev,
                {
                    userInfo: data.user,
                    lastMessage: chatData.lastMessage,
                },
            ]);
            setUsers((prev) => prev.filter((x) => x.uid !== data.user.uid));
        }

        await updateDoc(doc(db, "userChats", "channels"), {
            [`${data.chatId}.messages`]: chatData.messages,
            [`${data.chatId}.members`]: chatData.members,
            [`${data.chatId}.lastMessage`]: chatData.lastMessage
        });

        await markMessagesAsRead()

        setText("");
    };

    const handleSend = async () => {
        if (text === "") return;
        const date = Timestamp.now();
        const messageData = {
            id: uuidv4(),
            text,
            date,
            uid: currentUser.uid,
            img: ''
        };
        const chatData = {
            members: [currentUser.uid, data.user.uid],
            messages: arrayUnion(messageData),
            lastMessage: {
                text,
                date,
                sender: currentUser.uid,
            }
        };

        if (!chats.find((x) => x.userInfo.uid === data.user.uid)) {
            setChats((prev) => [
                ...prev,
                {
                    userInfo: data.user,
                    lastMessage: chatData.lastMessage,
                },
            ]);
            setUsers((prev) => prev.filter((x) => x.uid !== data.user.uid));
        }

        await updateDoc(doc(db, "userChats", "channels"), {
            [`${data.chatId}.messages`]: chatData.messages,
            [`${data.chatId}.members`]: chatData.members,
            [`${data.chatId}.lastMessage`]: chatData.lastMessage,
        });

        await markMessagesAsRead()

        setText("");
    };

    useEffect(() => {
        console.log(data.chatId === 'null');
    }, [data]);

    const markMessagesAsRead = async () => {
        const documentRef = doc(db, "userChats", "channels");
        const chatData = await getDoc(documentRef, data.chatId);

        if (chatData.exists() && chatData.data()[data.chatId]?.messages) {
            const messages = chatData.data()[data.chatId].messages;

            const updatedMessages = messages.map((message) => {
                if (message.uid !== currentUser.uid && !message.read) {
                    message.read = true;
                }
                return message;
            });

            const unreadLastMessages = 0;

            await updateDoc(doc(db, "userChats", "channels"), {
                [`${data.chatId}.messages`]: updatedMessages,
                [`${data.chatId}.unreadMessages`]: unreadLastMessages,
            });

            setUnreadMessages(unreadLastMessages)
        }
    };


    const handleSelect = (u) => {
        dispatch({ type: "CHANGE_USER", payload: u });
    };

    const [hasEnteredChat, setHasEnteredChat] = useState({
        uid: null,
        time: null,
    });

    useEffect(() => {
        const unsubscribe = onSnapshot(doc(db, "userChats", "channels"), async (document) => {
            if (document.data()[data.chatId]?.messages) {
                const messages = document.data()[data.chatId].messages;
                const unreadMessages = messages.filter((message) => !message.read).length;

                await updateDoc(doc(db, "userChats", "channels"), {
                    [`${data.chatId}.unreadMessages`]: unreadMessages,
                });

                setUnreadMessages(unreadMessages);

                if (!hasEnteredChat.uid && unreadMessages > 0) {
                    await markMessagesAsRead();
                    setHasEnteredChat({
                        uid: currentUser.uid,
                        time: new Date(),
                    });
                }
            }
        });

        return () => unsubscribe();
    }, [messages, hasEnteredChat]);

    useEffect(() => {
        const handleBlur = () => {
            if (!hasEnteredChat.uid) {
                setHasEnteredChat({
                    uid: null,
                    time: null,
                });
            }
        };

        const element = document.querySelector("#messages-users");
        if (element) {
            element.addEventListener("blur", handleBlur);
        }

        return () => {
            if (element) {
                element.removeEventListener("blur", handleBlur);
            }
        };
    }, [messages, hasEnteredChat]);

    return (
        <SideBar searchValidation={false} onHideHeader={false}>
            <div id="container-message">
                <div className="left-side-messages max-w-xs">
                    <div className="header">
                        <div className="user-img">
                            <div className="imgbx">
                                <button className="w-full h-full" >
                                    <img src={userData.profile.photo ? userData.profile.photo : process.env.PUBLIC_URL + "/images/users/notuser.png"} alt="user" className="cover" />
                                </button>
                            </div>

                            <h4>Chat</h4>
                        </div>

                        <div className="options-header">
                            <button>
                                <i className="bi bi-pencil-fill icon-user-header"></i>
                            </button>
                            <button onClick={openNav}>
                                <i className="bi bi-layout-sidebar-inset"></i>
                            </button>
                        </div>
                    </div>

                    <div className="search-user-message">
                        <div className="container-search">
                            <i className="bi bi-search icon"></i>

                            <div style={{ width: "100%" }}>
                                <input
                                    style={{ width: "100%" }}
                                    type="text"
                                    placeholder="search..."
                                    onKeyDown={handleKey}
                                    onChange={(e) => setUsername(e.target.value)}
                                />
                            </div>
                        </div>
                    </div>

                    <div className="users-messages">
                        <div className="w-full flex flex-col">
                            {chats
                                ?.sort((a, b) => {
                                    return b?.lastMessage?.date - a?.lastMessage?.date;
                                }).map((chat, index) => (
                                    <div className="max-w-full w-full flex flex-row items-stretch justify-start gap-4 p-2 hover:bg-gray-100 cursor-pointer" key={index} onClick={() => handleSelect(chat.userInfo)} >

                                        <div className="relative w-14 h-14 my-auto">
                                            <img src={chat.userInfo.profile.photo || process.env.PUBLIC_URL + "/images/users/notuser.png"} alt={'Hello'} className="rounded-full object-center object-cover w-full h-full" />
                                            <span class="absolute flex bottom-0 right-0 border-[3px] border-white w-4 h-4 rounded-full z-10 bg-green-500" title="active"></span>
                                        </div>

                                        <div className="userChatInfo flex-1 flex flex-col overflow-hidden relative">
                                            <div className="w-full flex flex-row" >
                                                <span className="w-full text-[#2a2d38]" >
                                                    {chat.userInfo.profile.name
                                                        ? chat.userInfo.profile.lastName
                                                            ? `${chat.userInfo.profile.lastName}, ${chat.userInfo.profile.name}`
                                                            : chat.userInfo.profile.name
                                                        : chat.userInfo.email}
                                                </span>
                                                <p className="text-sm text-[#c0c4cf]" >{new Date(chat.lastMessage?.date.seconds * 1000).getHours() + ':' + new Date(chat.lastMessage?.date.seconds * 1000).getMinutes()}</p>
                                            </div>
                                            <p className="text-ellipsis overflow-hidden text-[#c0c4cf]" >{chat.lastMessage?.text || 'Enviar mensaje.'}</p>
                                            {chat.unreadMessages >= 1 && chat.lastMessage?.sender !== currentUser.uid ? `${chat.unreadMessages} mensaje(s) sin leer` : ' '}
                                        </div>
                                    </div>
                                ))}
                        </div>
                    </div>
                </div>

                {data.chatId !== "null" ? (
                    <div className="right-side-messages active">
                        <div id="options-message" className={openOptionsUser ? "active" : ""} >
                            <div className="options-menu">
                                <button className="hamburger" onClick={() => setOpenOptionsUser(!openOptionsUser)} >
                                    <i className="bi bi-chevron-left icon-menu-user-config"></i>
                                </button>
                            </div>

                            <div className="img-user">
                                <div className="imgbx">
                                    <img
                                        src={data.user.profile.photo || process.env.PUBLIC_URL + "/images/users/notuser.png"}
                                        alt={"ImageUser"}
                                    />
                                </div>

                                <div className="display-name">
                                    <h3>
                                        {data.user.profile.name
                                            ? data.user.profile.lastName
                                                ? `${data.user.profile.lastName}, ${data.user.profile.name}`
                                                : data.user.profile.name
                                            : data.email}
                                    </h3>
                                </div>
                            </div>

                            <div className="options-user">
                                <button>
                                    <i className="bi bi-telephone-fill"></i>
                                </button>
                                <button>
                                    <i className="bi bi-camera-reels-fill"></i>
                                </button>
                                <button>
                                    <i className="bi bi-person-fill"></i>
                                </button>
                            </div>

                            <div className="menu">
                                <b>Soporte</b>
                                <button>
                                    <p className="option-title-user">Block</p>
                                    <i className="bi bi-person-fill-dash"></i>
                                </button>
                                <button>
                                    <p className="option-title-user">Report</p>
                                    <i className="bi bi-dash-circle-fill"></i>
                                </button>
                            </div>
                        </div>

                        <div className="header-message">
                            <div className="user-name">
                                <div className="imgbx">
                                    <img
                                        src={data.user.profile.photo || process.env.PUBLIC_URL + "/images/users/notuser.png"}
                                        className="cover"
                                        alt="Image"
                                    />
                                </div>

                                <div className="info-user">
                                    <h3>
                                        {data.user.profile.name
                                            ? data.user.profile.lastName
                                                ? `${data.user.profile.lastName}, ${data.user.profile.name}`
                                                : data.user.profile.name
                                            : data.email}
                                    </h3>
                                    <p>{OtherUser.status}</p>
                                </div>
                            </div>

                            <div className="ml-auto menu-bar">
                                <button onClick={(e) => setOpenOptionsUser(!openOptionsUser)} ><i className="bi bi-arrow-bar-left"></i></button>
                            </div>
                        </div>

                        <div id="messages-users">
                            <div className="w-full h-full" >
                                <div className="w-full flex flex-col flex-grow overflow-auto px-4 py-2 gap-y-4 justify-end" >
                                    {
                                        messagesLoading === false ? (
                                            messages?.sort((a, b) => { return b?.text - a?.text; }).map((message) => {
                                                if (message.service && message.onserviceID) {
                                                    console.log(message.id, message.onservice);
                                                    return (
                                                        <div className="relative">
                                                            <div className="shadow-4xl bg-white relative z-20 p-4 rounded-lg gap-y-4 box-border flex flex-col">

                                                                <div className="flex flex-row gap-x-4">
                                                                    <div className="relative min-w-[70px] w-[70px] h-[70px] overflow-hidden drop-shadow-2xl">
                                                                        <img className="w-full h-full object-cover rounded-lg" src={message.service.image} alt="" />
                                                                    </div>
                                                                    <div>
                                                                        <h1 className="text-principal font-bold text-xl">{message.service.name}</h1>
                                                                        <span className="text-[#9d9d9d] line-clamp-3">{message.service.description}</span>
                                                                    </div>
                                                                </div>

                                                                <div className="flex flex-row justify-between">
                                                                    <div className="flex flex-col items-start">
                                                                        <h1 className="text-principal font-bold text-xl">Precio</h1>
                                                                        <span className="text-[#9d9d9d] line-clamp-1">$1900 MXN</span>
                                                                    </div>

                                                                    <div className="flex flex-col items-end">
                                                                        <h1 className="text-principal font-bold text-xl">Solicitado</h1>
                                                                        <span className="text-[#9d9d9d] line-clamp-1">{formatDate(new Date())}</span>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            {
                                                                message.service.owenerUid === currentUser.uid && (
                                                                    <div className="flex flex-row w-full relative z-10 bg-gray-50 rounded-b-lg shadow-4xl pt-4">
                                                                        <div className="w-full h-[10px] absolute top-[-10px] bg-current bg-gray-50 z-10"></div>
                                                                        <div className="w-full h-full p-4 bg-gray-50 rounded-b-lg z-20 flex flex-row gap-x-4">
                                                                            <button className="bg-green-500 w-full text-white px-8 py-1 rounded cursor-pointer hover:opacity-85 hover:shadow-4xl duration-300 transition-all">Confirmar</button>
                                                                            <button className="bg-red-500  w-full text-white px-8 py-1 rounded cursor-pointer hover:opacity-85 hover:shadow-4xl duration-300 transition-all">Rechazar</button>
                                                                        </div>
                                                                    </div>
                                                                )
                                                            }
                                                        </div>
                                                    )
                                                } else if (message.uid === currentUser.uid) {
                                                    return (
                                                        <div key={message.id} className="p-2 relative bg-[#dcedff] w-fit rounded shadow-4xl ml-auto max-w-md">
                                                            {message.img === "" ? (
                                                                <p className="text-base text-right w-full text-ellipsis max-w-full overflow-hidden" title={message.read ? "leido" : "no leido"}>{message.text}</p>
                                                            ) : (
                                                                <img src={message.img} alt={message.id} />
                                                            )}
                                                        </div>
                                                    )
                                                } else if (message.uid === data.user.uid) {
                                                    return (
                                                        <>{message.read ? null :
                                                            <div className="unreadMessagesTitle">
                                                                <div className="message-line">&nbsp;</div>
                                                                <div className="message-title">No leidos</div>
                                                                <div className="message-line">&nbsp;</div>
                                                            </div>}
                                                            <div key={message.id} className="p-2 relative bg-[#eef1ff] w-fit rounded shadow-4xl mr-auto max-w-md">
                                                                {message.img === "" ? (
                                                                    <p className="text-base text-right w-full text-ellipsis max-w-full overflow-hidden" title={message.read ? "leido" : "no leido"}>{message.text}</p>
                                                                ) : (
                                                                    <div>
                                                                        <img src={message.img} alt={message.id} />
                                                                    </div>
                                                                )}
                                                            </div></>
                                                    )
                                                }
                                            })
                                        ) : (
                                            <span>Loading</span>
                                        )
                                    }
                                    <div ref={messagesEndRef} />
                                </div>
                            </div>
                        </div>

                        <div className="input-messages flex flex-row gap-x-2 ">

                            <label htmlFor="file" className="flex flex-col w-10 h-10 rounded-full bg-principal/90">
                                <i class="m-auto text-white text-xl bi bi-image-fill"></i>
                            </label>
                            <input type="file" id="file" className="hidden" onChange={(e) => {
                                hanldeSendImage(e.target.files[0]);
                            }} />

                            <div className="message-box">
                                <input
                                    type="text"
                                    placeholder="escribe tu mensaje..."
                                    onChange={(e) => setText(e.target.value)}
                                    value={text}
                                />

                                <button id="button-send" onClick={handleSend}>
                                    <div className="wrapper">
                                        <i className="bi bi-send"></i>
                                    </div>
                                    <span>Send</span>
                                </button>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div className="right-side-messages active" >
                        <div className="header-message">
                            <div className="user-name">
                                <div className="imgbx">
                                    <img src={OtherUser.photo} className="cover" alt="Image" />
                                </div>

                                <div className="info-user">
                                    <h3>Un usuario</h3>
                                    <p>online</p>
                                </div>
                            </div>


                        </div>

                        <div id="messages-users">
                            <div className="select-user">
                                <i className="bi bi-chat-dots icon"></i>

                                <h3 className="text-2xl font-bold" >Selecciona un chat.</h3>
                                <p>En la barra de la izquierda selecciona un chat para abrir.</p>
                            </div>
                        </div>
                    </div>
                )}
            </div>
        </SideBar>
    );
}