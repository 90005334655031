//  Firebase
import { auth } from "../../config/firebase-config";

//  Firestore
import { setDoc, doc, updateDoc, getDoc, collection, query, where, getDocs, limit } from "firebase/firestore";
import { ref, uploadBytes, getDownloadURL } from "firebase/storage";
import { db, storage } from "../../config/firebase-config";

//  Notification
import { setNotificationError } from "../../func/setNotification";
import { setNotificationSuccess } from "../../func/setNotification";

//  DataCompany
import { updateProfile } from "firebase/auth";
import ApiConnect from "../../api/connect.api";



//  Create Company
export const createCompany = async (userData, data, tokenUserCard, tokenUser) => {
    console.log("CREATE COMPANY")
    const { currentUser } = auth
    let resultCreateCompany = {
        success: false,
        message: '',
    }

    if (data.photo) {
        try {
            const fileRef = ref(storage, 'companies/' + currentUser.uid + '.png')
            const snapShot = await uploadBytes(fileRef, data.photo)
            const photoURL = await getDownloadURL(snapShot.ref)
            data = {
                ...data,
                photo: photoURL
            }
        } catch (error) {
            console.log(error)
        }
    }

    try {
        if (userData.accountType === 2) {
            setDoc(doc(db, "companies", currentUser.uid), {
                uid: currentUser.uid,
                status: 0,
                companyVerified: false,
                category: data.category,
                profile: {
                    nameCompany: data.nameCompany,
                    description: data.description,
                    phone: '',
                    photoURL: data.photo,
                    rating: {
                        count: 0,
                        total: 0,
                    },
                    socialMedia: {
                        facebook: data.facebook || '',
                        instagram: data.instagram || '',
                        linkedin: data.linkedin || '',
                    },
                    timeOnOpen: {
                        open: data.timeOnOpen.start,
                        close: data.timeOnOpen.end,
                    },
                    daysOpen: data.daysOn,
                },
                services: [],
            }).then(() => {
                console.log("Document successfully written!");
                console.log(data)
                console.log(userData)
                console.log(currentUser)
                ApiConnect.post("/company", {
                    data: {
                        company: {
                            name: data.nameCompany,
                        },
                        user: {
                            email: userData.email,
                            name: currentUser.displayName,
                        },
                        card: {
                            token: tokenUserCard.id,
                            onPromocode: data.promoCode ? data.promoCode : null,
                        },
                    }
                }, {
                    headers: {
                        Authorization: `Bearer ${tokenUser}`
                    }
                }).then((response) => {
                    console.log(response)
                }).catch((error) => {
                    console.log(error)
                })

                resultCreateCompany.success = true
                resultCreateCompany.message = 'Document successfully written!'

                setNotificationSuccess('Cuenta de empresa creada correctamente.')
            }).catch((error) => {
                resultCreateCompany.success = false
                resultCreateCompany.message = error

                setNotificationError('Error al crear la cuenta de empresa.')
            })
        }
    } catch (error) {
        console.log(error)
    }

    return resultCreateCompany
}


//  UpdateCompany
export const updateCompany = async (companyData, dataActual) => {
    const { currentUser } = auth
    let objectToUpdate = {}

    Object.keys(companyData).some((key) => {
        if (companyData[key] !== '') {
            Object.assign(objectToUpdate, {
                [key]: companyData[key]
            })
        }
    })

    if (Object.keys(objectToUpdate).length === 0) {
        setNotificationError('No hay datos para actualizar.')
        return
    }

    console.log(objectToUpdate)

    if (objectToUpdate.companyImage) {
        try {
            const fileRef = ref(storage, 'companies/' + currentUser.uid + '.png')
            const snapShot = await uploadBytes(fileRef, objectToUpdate.companyImage)
            const photoURL = await getDownloadURL(snapShot.ref)

            console.log(photoURL);

            objectToUpdate = {
                ...objectToUpdate,
                companyImage: photoURL
            }

            updateProfile(currentUser, {photoURL})
        } catch (error) {
            setNotificationError('Error al actualizar la imagen de perfil.')
        }
    }

    console.log(objectToUpdate)

    if (Object.keys(objectToUpdate).length >= 1) {
        await updateDoc(doc(db, "companies", currentUser.uid), {
            category: objectToUpdate.category.index ? objectToUpdate.category.index : dataActual.category,
            'profile.nameCompany': objectToUpdate.name ? objectToUpdate.name : dataActual.profile.nameCompany,
            'profile.description': objectToUpdate.description ? objectToUpdate.description : dataActual.profile.description,
            'profile.phone': objectToUpdate.phoneNumber ? objectToUpdate.phoneNumber : dataActual.profile.phone,
            'profile.photoURL': objectToUpdate.companyImage ? objectToUpdate.companyImage : dataActual.profile.photoURL,
            'profile.socialMedia.facebook': objectToUpdate.facebook ? objectToUpdate.facebook : dataActual.profile.socialMedia.facebook,
            'profile.socialMedia.instagram': objectToUpdate.instagram ? objectToUpdate.instagram : dataActual.profile.socialMedia.instagram,
            'profile.socialMedia.linkedin': objectToUpdate.linkedin ? objectToUpdate.linkedin : dataActual.profile.socialMedia.linkedin,
            'profile.address.city': objectToUpdate.city ? objectToUpdate.city : dataActual.profile.address.city,
            'profile.address.country': objectToUpdate.country ? objectToUpdate.country : dataActual.profile.address.country,
            'profile.address.state': objectToUpdate.state ? objectToUpdate.state : dataActual.profile.address.state,
            'profile.address.postalCode': objectToUpdate.postalCode ? objectToUpdate.postalCode : dataActual.profile.address.postalCode,
        }).then(() => {''
            setNotificationSuccess('Datos actualizados.')
        })
    }

    setNotificationSuccess('Datos actualizados.')
    return {
        error: false,
    }
}

//  GetCompanyForCategory
export const getCompanyForCategory = async ({category, pageNumber}) => {
    let companies = []

    //  const q = query(collection(db, "companies"), where("category", "==", category), where("companyVerified", "==", true), limit(10))
    const q = query(
        collection(db, "companies"), 
        where("category", "==", category),
        limit(12)    
    )

    const querySnapshot = await getDocs(q)
    querySnapshot.forEach((doc) => {
        companies.push(doc.data())
    });

    const limits = Array((12 * pageNumber) - companies.length).fill(0)
    limits.map((_, index) => {
        companies.push(companies[0])
    }) 


    return companies
}

//  GetCompanyForId
export const getCompanyForId = async (id) => {
    let company = {}

    const docRef = doc(db, "companies", id)
    const docSnap = await getDoc(docRef)

    if (docSnap.exists()) {
        company = docSnap.data()
    } else {
        console.log("No such document!");
    }

    return company
}