import { Fragment, useEffect, useState } from 'react'
import { Listbox, Transition } from '@headlessui/react'
import { CheckIcon, ChevronUpDownIcon } from '@heroicons/react/20/solid'

const classNames = (...classes) => {
    return classes.filter(Boolean).join(' ')
}

export default function SelectedCountry({ setCountryUser, labelText='Pais', value, country, onError=false  }) {
    return (
        <Listbox value={value} onChange={setCountryUser}>
            {({ open }) => (
                <>
                    <Listbox.Label htmlFor='country' className='w-full text-[#1a1f36] text-sm font-semibold' >{labelText}</Listbox.Label>
                    <div className='relative mt-2'>
                        <Listbox.Button id='country' name='country' className={`
                            relative w-full cursor-pointer rounded-md bg-white py-2 pl-3 pr-10 text-left 
                            text-gray-900 shadow-sm transition ease-in-out duration-300
                            hover:shadow-4xl border border-[#e0e1e4]
                            focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-blue-500
                            ${onError ? 'border-[#fc526a]' : ''}
                        `} >
                            <span className='flex items-center'>
                                <img src={value.img} alt={value.name} className='h-5 w-5 flex-shrink-0' />
                                <span className='ml-3 block truncate' >{value.name}</span>
                            </span>
                            <span className='pointer-events-none absolute inset-y-0 right-0 ml-3 flex items-center pr-2' >
                                <ChevronUpDownIcon className='h-5 w-5 text-gray-400' aria-hidden='true' />
                            </span>
                        </Listbox.Button>

                        <Transition
                            show={open}
                            as={Fragment}
                            leave='transition ease-in duration-100'
                            leaveFrom='opacity-100'
                            leaveTo='opacity-0'
                        >
                            <Listbox.Options className={`
                                absolute z-10 mt-1 max-h-56 w-full overflow-auto rounded-md bg-white 
                                py-1 text-base shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none 
                                sm:text-sm
                            `}>
                                {
                                    country.map((country) => (
                                        <Listbox.Option
                                            key={country.id}
                                            className={({ active }) => classNames(
                                                active ? 'text-white bg-principal' : 'text-gray-900',
                                                'cursor-default select-none relative py-2 pl-10 pr-4'
                                            )}
                                            value={country}
                                        >
                                            {({ value, active }) => (
                                                <>
                                                    <span className={classNames(value ? 'font-semibold' : 'font-normal', 'truncate flex items-center')} >
                                                        <img src={country.img} alt={country.name} className='h-5 w-5 flex-shrink-0' />
                                                        <span className='ml-3 block truncate' >{country.name}</span>
                                                    </span>
                                                    {value ? (
                                                        <span className={classNames(
                                                            active ? 'text-white' : 'text-principal',
                                                            'absolute inset-y-0 left-0 flex items-center pl-3'
                                                        )}>
                                                            <CheckIcon className='h-5 w-5' aria-hidden='true' />
                                                        </span>
                                                    ) : null}
                                                </>
                                            )}
                                        </Listbox.Option>
                                    ))
                                }
                            </Listbox.Options>
                        </Transition>
                    </div>
                </>
            )}
        </Listbox>
    )
}