import { NavLink, useParams } from "react-router-dom";
import SideBar from "../components/SideBar";
import Raiting from "../components/Rating";
import { categoryData } from "../data/categories";
import { getCompanyForCategory } from "../auth/func/authCompany";
import { useCallback, useContext, useEffect, useRef, useState } from "react";
import Loading from "../components/Loading";
import { setNotificationError } from "../func/setNotification";
import ApiCompany from "../api/company.api";
import { AuthContext } from "../context/AuthContext";


export default function Category() {
    const [tokenUserSearch, setTokenUserSearch] = useState('')
    const [pageNumber, setPageNumber] = useState(1)
    const [companysData, setCompanysData] = useState([])
    const [hasMoreCompanies, setHasMoreCompanies] = useState(false)
    const [loading, setLoading] = useState(false)
    const { tokenUser } = useContext(AuthContext)
    let { category } = useParams()
    const observer = useRef()
    const lastCompanieElementRef = useCallback(node => {
        if (!loading) return
        if (observer.current) observer.current.disconnect()
        observer.current = new IntersectionObserver(entries => {
            if (entries[0].isIntersecting && hasMoreCompanies) {
                setPageNumber(prevPageNumber => prevPageNumber + 1)
                console.log('visible')
            }
        })
        if (node) observer.current.observe(node)
    }, [!loading, hasMoreCompanies])



    //  Get Companies
    const getCompanies = async () => {
        ApiCompany.get("/", {
            params: {
                category: category,
                myKeySearch: tokenUserSearch ? tokenUserSearch : '',
                index: pageNumber
            },
            headers: {
                'Authorization': `Bearer ${tokenUser}`
            },
        }).then((res) => {
            console.log(res.data)
            if (res.data.myKeySearch) {
                setTokenUserSearch(res.data.myKeySearch)
            }
            setHasMoreCompanies(res.data.hasMoreCompany)
            setCompanysData(res.data.data)
            setLoading(true)
        }).catch((err) => {
            console.log(err)
        })
    } 


    useEffect(() => {
        if (!tokenUserSearch) {
            getCompanies()
        }
    }, [!tokenUserSearch])

    












    return (
        <SideBar>
            <div className="px-4 pt-2 flex flex-col gap-y-4 h-[calc(100vh_-_64px)] md:h-screen w-full" >
                <div className="" >
                    <div className="" >
                        <h3 className="capitalize text-principal font-medium text text-2xl" >{ categoryData[category].title + ':' }</h3>
                    </div>
                </div>

                {
                    loading ? (
                        <div className="overflow-y-auto w-full h-full">
                            {
                                companysData.length > 0 ? (
                                    <div className="pb-6 grid grid-cols-1 px-6 pt-4 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 w-fit mx-auto gap-6">
                                        {
                                            companysData.map((company, index) => {
                                                return (
                                                    <div key={ `${company.uid}${index}` } className={`
                                                        no-underline rounded-[12px] bg-white shadow-4xl
                                                        max-w-[320px] w-[320px] p-2 flex flex-col 
                                                        gap-y-4 pb-4 h-fit relative
                                                    `}>
                                                        <div className="flex flex-col items-center justify-center gap-y-2" >
                                                            <div className="w-full pb-2 relative flex" >
                                                                <div className="bottom-0 w-full" >
                                                                    {
                                                                        company.profile.photoURL && (
                                                                            <img
                                                                                className={`
                                                                                    w-[100px] h-[100px] rounded-full mx-auto border-[4px] border-white
                                                                                    object-cover object-center
                                                                                `} 
                                                                                src={company.profile.photoURL } alt={ company.profile.nameCompany } 
                                                                            />
                                                                        )
                                                                    }
                                                                </div>
                                                            </div>


                                                            <h3 className="text-center text-principal font-semibold text-2xl" >{ company.profile.nameCompany }</h3>
                                                            <Raiting total={ company.profile.rating.total} count={ company.profile.rating.count} />
                                                        </div>

                                                        <section className="mt-4 max-w-[220px] mx-auto flex flex-col gap-y-2" >
                                                            <p className="text-[#9D9D9D]" ><b>Categoria:</b> {categoryData[category].title}</p>
                                                            <p className="text-[#9D9D9D]" ><b>Rango de precios:</b> {123}</p>
                                                            <p className="line-clamp-4 text-[#9D9D9D]" ><b>Descripcion:</b> {company.profile.description ? company.profile.description : ''}</p>
                                                        </section>

                                                        <footer className="flex w-full flex-row" >
                                                            <NavLink to={`/company/${ company.uid }`} className={`
                                                                mx-auto no-underline
                                                            `} >
                                                                <div className={`
                                                                    px-12 py-2 rounded-[8px] bg-transparent 
                                                                    text-[#9D9D9D] border-[#9D9D9D]
                                                                    border-[2px]
                                                                `} >
                                                                    <p className="text-current m-0" >Ver</p>
                                                                </div>
                                                            </NavLink>
                                                        </footer>

                                                        
                                                    </div>
                                                )
                                            })
                                        }

                                        {
                                            hasMoreCompanies && (
                                                <div className="w-full col-span-1 lg:col-span-2 xl:col-span-3 2xl:col-span-4 flex flex-col relative gap-y-12 py-8" >
                                                    <div className="relative" >
                                                        <Loading />
                                                    </div>
                                                    <p ref={ lastCompanieElementRef } className="text-gray-500 w-full text-center" >Buscando...</p>
                                                </div>
                                            )
                                        }
                                    </div>
                                ) : (
                                    <div className="w-full relative flex flex-col max-w-sm mx-auto" >
                                        <div className="my-auto flex flex-col gap-y-3 items-center" >
                                            <i className="text-principal text-[96px] bi bi-building"></i>
                                            <h1 className="w-fit text-[32px] text-principal font-medium" >No encontrado...</h1>
                                            <p className="w-fit pb-11 text-lg text-center text-gray-500" >Lo siento no se ha encontrado compañias relacionadas a "<b>{ categoryData[category].title }</b>" porfavor busque en otro momento...</p>
                                        </div>
                                    </div>
                                )
                            }
                        </div>
                    ) : (
                        <div className="w-full relative flex flex-col max-w-sm mx-auto" >
                            <div className="my-auto flex flex-col gap-y-3 items-center" >
                                <i className="text-principal text-[96px] bi bi-building"></i>
                                <h1 className="w-fit text-[32px] text-principal font-medium" >Buscando...</h1>
                                <p className="w-fit pb-11 text-lg text-center text-gray-500" >buscando las compañias relacionadas con "<b>{ categoryData[category].title }</b>" porfavor espere un momento...</p>
                                <Loading />
                            </div>
                        </div>
                    )
                }
            </div>
        </SideBar>
    )
}