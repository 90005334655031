import { FaStar } from "react-icons/fa";

//  ColorsStars
const colors = {
    orange: "#FFBA5A",
    grey: "#A9A9A9",
};

export default function Raiting ({ total, count, styles }) {
    const stars = Array(5).fill(0);
    return (
        <div className={`flex flex-row gap-x-1 items-center ${styles}`} >
            <div className="flex flex-row items-center" >
                {
                    stars.map((_, index) => {
                        return (
                            <FaStar
                                key={index}
                                size={20}
                                color={
                                    Math.round(total / count) > index ? colors.orange : colors.grey
                                }
                            />
                        )
                    })
                }
            </div>

            <p className="text-sm font-bold m-0 text-gray-400 pt-1">({count})</p>
        </div>
    )
}