//  Styles
import './scss/ResumeService.scss'

//  FrameMotion
import { motion } from 'framer-motion'






export default function ResumeService({ closeResumeService, service }) {
    return (
        <motion.div id="resume-service" initial={{ opacity: 0 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }}>
            <div className="modal">
                <div className="header">
                    <h3>{ service.name }</h3>
                </div>
                <div className="body">
                    <div className="image">
                        <div className="image-container">
                            <img src={ service.image } alt={ service.name } />
                        </div>
                    </div>
                    <div className="info">
                        <div className="description">
                            <h5>Descripción del servicio.</h5>
                            <p>{ service.description }</p>
                        </div>
                        <div className="price">
                            <h5>Precio del servicio.</h5>
                            <p>Precio del servicio</p>
                        </div>
                    </div>
                </div>
                <div className="footer">
                    <button onClick={closeResumeService}>Cerrar</button>
                </div>
            </div>
        </motion.div>
    )
}