//  Components
import { useContext, useEffect, useState } from "react";
import SideBar from "../components/SideBar";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CardForm from "../components/CardForm";
import { AiOutlineClose } from "react-icons/ai"
import useMeasure from "react-use-measure"

//  Styles
import { BsPlus } from "react-icons/bs"
import "../scss/payment.scss";
import "../css/Card.css"
import ApiStripe from "../api/stripe.api";
import { AuthContext } from "../context/AuthContext";
import { motion, AnimatePresence, MotionConfig } from "framer-motion";
const Images = {
    CreditCard: process.env.PUBLIC_URL + '/images/logos/CreditCard.png',
    PayPal: process.env.PUBLIC_URL + "/images/logos/PayPal.png",
    Visa: process.env.PUBLIC_URL + '/images/cards/Visa.png',
    Amex: process.env.PUBLIC_URL + '/images/cards/AmericanExpress.png',
    Mastecard: process.env.PUBLIC_URL + '/images/cards/MasterCard.png',
    Diners: process.env.PUBLIC_URL + '/images/cards/Diners.png',
    Discover: process.env.PUBLIC_URL + '/images/cards/Discover.png',
    Jcb: process.env.PUBLIC_URL + '/images/cards/JCB.png',
}

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)
const CardComponent = ({ defaultCard, data, callback, onHoverCard, setOnHoverCard }) => {
    let brand = data.card.brand
    switch(data.card.brand) {
        case 'amex': brand = Images.Amex; break;

        case 'diners': brand = Images.Diners; break;
        case 'discover': brand = Images.Discover; break;
        case 'jcb': brand = Images.Jcb; break;
        case 'mastercard': brand = Images.Mastecard; break;
        case 'visa': brand = Images.Visa; break;

    }
    return (
        <div className="relative bg-gradient-to-r from-cyan-500 to-blue-500 p-[25px] rounded-3xl max-w-[420px] w-full shadow-4xl bg-cover" >
            <div className="relative flex items-center justify-between" >
                <span className="relative flex items-center justify-between" >
                    <img className="w-[48px] mr-[10px]" src={brand} alt={data.card.brand} />
                    <h5 className="text-[16px] font-normal text-white">Visa</h5>
                </span>
                <img className="w-[60px] relative" src={ process.env.PUBLIC_URL + "/images/cards/chip.png" } alt="" />
            </div>

            <div className="flex mt-[40px] justify-between items-end" >
                <div>
                    <h6 className="text-white text-base" >Numero de Cuenta</h6>
                    <h5 className="text-white tracking-widest text-lg">**** **** **** 4242</h5>
                </div>
            </div>
        </div>
    )
}
export default function Payment() {
    const [onHoverCard , setOnHoverCard] = useState("")
    const [defaultUserPayment, setDefaultUserPayment] = useState("")
    const [paymentsUser, setPaymentsUser] = useState([])
    const [onAddCard, setOnAddCard] = useState(false)
    const { currentUser, tokenUser } = useContext(AuthContext)
    const [cardActive, setCardActive] = useState({
        CreditCard: false,
        DebitCard: false,
        PayPal: false,
    })

    const getMethodsPayment = () => {
        ApiStripe(`/payment-methods/list/${currentUser.uid}`, {
            headers: {
                Authorization: `Bearer ${ tokenUser }`
            }
        }).then(data => {
            setPaymentsUser(data.data.data.data)
            ApiStripe.get(`/customer/${currentUser.uid}`, {}, {
                headers: {
                    Authorization: `Bearer ${ tokenUser }`
                }, 
            }).then((res) => {
                setDefaultUserPayment(res.data.data.invoice_settings.default_payment_method)
            })
        })
    }


    useEffect(() => {
        getMethodsPayment()
    }, [!paymentsUser])


    const handleCard = async (tokenCard, error) => {
        if(error) {
            console.log(error)
            return;
        }

        ApiStripe.post("/payment-method/associate", {
            data: {
                userId: currentUser.uid, 
                paymentMethodToken: tokenCard.id
            }
        }, {
            headers: {
                Authorization: `Bearer ${ tokenUser }`
            }, 
        }).then(() => {
            getMethodsPayment()
            setOnAddCard(false)
        })
    }

    const setDefaultCard = async (tokenCard) => {
        ApiStripe.post("/payment-method/default", {
            data: {
                userId: currentUser.uid, 
                paymentMethodId: tokenCard.id
            }
        }, {
            headers: {
                Authorization: `Bearer ${ tokenUser }`
            }, 
        }).then(() => {
            getMethodsPayment()
        })
    }


    return (
        <SideBar>
            <div id="content-payment" className="relative">
                <div className="w-full flex flex-col mb-6" >
                    <span className="text-[#9d9d9d]" >Selecciona tu metodo de pago por defecto.</span>
                </div>
                <div className="content flex flex-col gap-y-8 relative">
                    <div className="relative payments w-full h-full">
                        {paymentsUser.map((data, index) => {
                            return (
                                <CardComponent 
                                    setOnHoverCard={setOnHoverCard} 
                                    onHoverCard={onHoverCard} 
                                    defaultCard={defaultUserPayment} 
                                    data={data} key={index} 
                                    callback={() => {
                                        setDefaultUserPayment(data.id)
                                        setDefaultCard(data)  
                                    }}
                                />
                            )
                        })}
                    </div>

                    <div className="w-full bg-transparent flex flex-row justify-center" >
                        <button className="w-full max-w-[500px] bg-principal rounded-lg p-3" 
                            onClick={() => setOnAddCard(!onAddCard)}
                        >
                            <div className="text-white flex flex-row justify-center items-center gap-x-2">
                                <BsPlus size={ 26 } />
                                <span>Agregar metodo de Pago</span>
                            </div>
                        </button>
                    </div>
                </div>
            </div>

            {
                onAddCard && (
                    <div className="left-0 top-0 flex items-center justify-center w-screen h-screen bg-black/40 fixed" >
                        <div className="relative rounded-[5px] bg-white min-w-[600px] max-w-[600px] h-auto p-6 flex flex-col items-center justify-center gap-y-8" >
                            <button 
                                className="absolute m-6 top-0 left-0"
                                onClick={() => setOnAddCard(!onAddCard)}
                            >
                                <AiOutlineClose color="9d9d9d" />
                            </button>

                            <h2 className="text-2xl text-principal" >Guarda una tarjeta.</h2>
                            <div className="w-full">
                                <p className="text-sm text-[#9D9D9D]" >Facilita tus pagos poniendo una tarjeta por defecto.</p>
                            </div>
                            
                            <div className="w-full flex flex-col gap-y-2" >
                                <Elements stripe={stripePromise} >
                                    <CardForm text="Guardar metodo de pago" callback={handleCard} />
                                </Elements>

                                <button
                                    onClick={() => setOnAddCard(false)}
                                    className="text-white py-2 rounded shadow-md 
                                    hover:shadow-4xl transition-all duration-300 hover:bg-opacity-90 px-3
                                    bg-red-600 w-full"
                                >Cancelar</button>
                            </div>
                        </div>
                    </div>
                )
            }
        </SideBar>
    )
}