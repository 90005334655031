const ComponentImageUpload = ({ name, handleImage, onValue }) => {
    const AvatarImageNotFound = process.env.PUBLIC_URL + "/images/users/notuser.png"
    return (
        <div className="">
            <input 
                type="file" name={name} 
                id={name} className='hidden' 
                onChange={handleImage} 
            />
            <label htmlFor={name} className="relative flex flex-col w-full justify-center items-center">
                <img 
                    className="w-48 h-48 rounded-full object-cover"
                    src={ onValue ? onValue : AvatarImageNotFound } 
                    alt={name} 
                />
                <div className="group absolute w-48 h-48 rounded-full hover:bg-[#9d9d9d]/30 cursor-pointer transition-all duration-300 flex flex-col justify-center items-center" >
                    <i className={`
                        group-hover:text-white text-8xl duration-300 transition-all bi bi-camera-fill text-transparent
                    `}></i>
                </div>
            </label>
        </div>
    )
}

export default ComponentImageUpload