const Loading = () => {
    return (
        <div className="inline-block h-4 w-4 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] motion-reduce:animate-[spin_1.5s_linear_infinite]"
            role="status">
            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]"/>
        </div>
    )
}

const ComponentButton = ({ active=true, text='Continuar', callback, onLoading=false }) => {
    return (
        <button onClick={() => { active && callback() }} className={`
             text-white py-2 rounded shadow-md 
            hover:shadow-4xl transition-all duration-300 hover:bg-opacity-90

            ${ active ? 'bg-principal cursor-pointer' : 'bg-principal/70 cursor-default' }
        `}>
            {
                onLoading ? <Loading /> : <span>{ text }</span>
            }
        </button>
    )
}

export default ComponentButton