//  React
import React from "react";
import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";

//  Context
import { AuthContext } from "../../context/AuthContext";
import { DataContext } from "../../context/DataContext";
import { CompanyContext } from "../../context/CompanyContext";


//  AuthRoute
const OnboardingRoute = () => {
    const auth = useContext(AuthContext);
    const data = useContext(DataContext);
    const company = useContext(CompanyContext);
    return (
        auth.loadingUser && (
            auth.currentUser ? (
                data.loadingData && (
                    data.userData.accountType === 2 && company.companyData === undefined ? (
                        <Navigate to={'/register-complete'}/>
                    ) : (
                        company.loadingCompany && (
                            company.statusAccount ? (
                                <Navigate to={'/home'} />
                            ) : (
                                <Outlet />
                            )
                        )
                    )
                )
            ) : (
                <Navigate to={'/login'} />
            )
        )
    )
};

export default OnboardingRoute;