//  React
import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";

//  Context
import { AuthContext } from "../../context/AuthContext";
import { DataContext } from "../../context/DataContext";

//  AdminRoute
const AdminRoute = () => {
    const auth = useContext(AuthContext);
    const data = useContext(DataContext);


    return (
        auth.loadingUser && (
            auth.currentUser ? (
                data.loadingData && (
                    data.userData.accountType === 2 ? (
                        <Outlet />
                    ) : <Navigate to={'/home'} />
                )
            ) : (
                <Navigate to={'/login'} />
            )
        )
    )
}

export default AdminRoute;