import './scss/Loading.scss';

const Loading = () => {
    return (
        <div id="content-loading">
            <span className="loader"></span>
        </div>
    )
}

export default Loading