//  React
import React from "react";
import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";

//  Context
import { AuthContext } from "../../context/AuthContext";
import { DataContext } from "../../context/DataContext";
import { CompanyContext } from "../../context/CompanyContext";

//  AuthRoute
const PublicRoute = () => {
    const auth = useContext(AuthContext);
    const data = useContext(DataContext);
    const company = useContext(CompanyContext)

    return (
        auth.loadingUser && (
            auth.currentUser ? (
                data.loadingData && (
                    data.userData.accountType === 2 ? (
                        company.loadingCompany && (
                            company.companyData ? (
                                <Navigate to={'/home'} />
                            ) :(
                                <Navigate to={'/register-complete'} />
                            )
                        )
                    ) : (
                        data.userData.accountType === 1 && (
                            <Navigate to={'/home'} />
                        )
                    )
                )
            ) : (
                <Outlet />
            )
        )
    ) 
}

export default PublicRoute;