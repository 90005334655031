//  React
import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";

//  Context
import { AuthContext } from "../../context/AuthContext";
import { DataContext } from "../../context/DataContext";
import { CompanyContext } from "../../context/CompanyContext";

//  AuthRoute
const CheckCompanyRoute = () => {
    //  Context
    const auth = useContext(AuthContext);
    const data = useContext(DataContext);
    const company = useContext(CompanyContext)


    return (
        auth.loadingUser && (
            auth.currentUser ? (
                company.loadingCompany && data.loadingData ? (
                    data.userData.accountType === 2 ? (
                        <Outlet />
                    ) : (
                        <Navigate to={'/home'} />
                    )
                ) : (
                    <Navigate to={'/home'} />
                )
            ) : (
                <Navigate to={'/login'} />
            )
        )
    )
}

export default CheckCompanyRoute;