import SideBar from "../components/SideBar";
import { ConfigToast } from "../func/setNotification";

export default function Tickets () {
    return (
        <SideBar searchValidation={ false } >
            <ConfigToast />
            <div className="px-4 py-2 flex flex-col gap-y-4">
                {/* Title */}
                <h3 className="font-medium" >Tickets</h3>



                {/* Icons */}
                <div className="grid md:grid-cols-2 2xl:grid-cols-4 gap-4 w-full max-w-[1900px] mx-auto">
                    <div className={`
                        shadow-[0_5px_15px_0px_rgba(0,0,0,0.3)] bg-white
                        p-3 rounded-xl flex flex-row gap-x-4
                    `} >
                        <div className="h-full flex flex-col items-center justify-center" >
                            <div className="bg-blue-100 rounded-full flex flex-col items-center justify-center w-16 h-16">
                                <i className="bi bi-ticket-fill text-4xl text-[#3179ff]"></i>
                            </div>
                        </div>

                        <div className="flex flex-col" >
                            <h5 className="font-semibold cursor-default">83457</h5>
                            <p className="text-md text-gray-500 cursor-default" >Total de Solicitudes</p>
                        </div>
                    </div>


                    <div className={`
                        shadow-[0_5px_15px_0px_rgba(0,0,0,0.3)] bg-white
                        p-3 rounded-xl flex flex-row gap-x-4
                    `} >
                        <div className="h-full flex flex-col items-center justify-center" >
                            <div className="bg-yellow-100 rounded-full flex flex-col items-center justify-center w-16 h-16">
                                <i className="bi bi-hourglass-split text-4xl text-[#e8a444]"></i>
                            </div>
                        </div>

                        <div className="flex flex-col" >
                            <h5 className="font-semibold cursor-default">83457</h5>
                            <p className="text-md text-gray-500 cursor-default" >Solicitudes en Progreso</p>
                        </div>
                    </div>


                    <div className={`
                        shadow-[0_5px_15px_0px_rgba(0,0,0,0.3)] bg-white
                        p-3 rounded-xl flex flex-row gap-x-4
                    `} >
                        <div className="h-full flex flex-col items-center justify-center" >
                            <div className="bg-green-200 rounded-full flex flex-col items-center justify-center w-16 h-16">
                                <i className="bi bi-lock-fill text-4xl text-[#1db762]"></i>
                            </div>
                        </div>

                        <div className="flex flex-col" >
                            <h5 className="font-semibold cursor-default">83457</h5>
                            <p className="text-md text-gray-500 cursor-default" >Solicitudes Verificadas</p>
                        </div>
                    </div>


                    <div className={`
                        shadow-[0_5px_15px_0px_rgba(0,0,0,0.3)] bg-white
                        p-3 rounded-xl flex flex-row gap-x-4
                    `} >
                        <div className="h-full flex flex-col items-center justify-center" >
                            <div className="bg-red-100 rounded-full flex flex-col items-center justify-center w-16 h-16">
                                <i className="bi bi-trash-fill text-4xl text-[#ff4d4d]"></i>
                            </div>
                        </div>

                        <div className="flex flex-col" >
                            <h5 className="font-semibold cursor-default">83457</h5>
                            <p className="text-md text-gray-500 cursor-default" >Solicitudes Canceladas</p>
                        </div>
                    </div>
                </div>



                {/* Tickets https://tailwindcss.com/docs/caption-side */}
                <table>
                    <thead>
                        <tr>
                            <th className="px-4 py-2 text-left">ID</th>
                            <th className="px-4 py-2 text-left">Solicitud por</th>
                            <th className="px-4 py-2 text-left">Compañia</th>
                            <th className="px-4 py-2 text-left">Estado</th>
                            <th className="px-4 py-2 text-left">Fecha de Creación</th>
                            <th className="px-4 py-2 text-left">Acciones</th>
                        </tr>
                    </thead>
                </table>
            </div>
        </SideBar>
    )
}