import Loading from "../Loading";

export default function LoadingPage() {
    return(
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center flex-col">
            <div className="flex flex-col gap-y-10">
                <div>
                    <h1 className="text-principal text-2xl w-full text-center">Cargando...</h1>
                    <p className="text-sm text-secondary">Espere porfavor en lo que obtenemos sus datos.</p>
                </div>
                <Loading />
            </div>
        </div>
    )
}