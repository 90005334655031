//  React
import React from "react";
import { useParams } from "react-router-dom";
import { useEffect, useState } from "react";
import { useContext } from "react";

//  Styles
import "../scss/company.scss";

//  Components
import SideBar from "../components/SideBar";
import Loading from "../components/Loading";
import EditCompany from "../components/EditCompany";

import ViewService from "../components/ViewService";

//  Icons
import { IonIcon } from "@ionic/react";
import { addCircleOutline } from "ionicons/icons";
import { BiBuilding } from "@react-icons/all-files/bi/BiBuilding";
import { FaStar } from "react-icons/fa";
import { BsFacebook, BsLinkedin, BsTelephoneFill } from "react-icons/bs";

//  Motion
import { AnimatePresence, motion } from "framer-motion";

//  Context
import { AuthContext } from "../context/AuthContext";

//  AuthCompany
import { checkCompany } from "../auth/func/authCompany";
import { DataContext } from "../context/DataContext";

//  CompanyDataType
import { typeAccountData } from "../data/typeAccount";

//  Firestore
import { doc, getDoc, onSnapshot } from "firebase/firestore";
import { db, database as databaseFirebase } from "../config/firebase-config";
import { createCompany } from "../auth/func/authCompany";
import { onValue, ref, set } from "firebase/database";

//  Utils
import getTimeAgo from '../func/values'
import Raiting from "../components/Rating";
import { setNotificationInfo } from "../func/setNotification";
import ApiServices from "../api/services.api";

export default function Company() {
    let params = useParams();
    let companyId = params["id"];
    const { currentUser, tokenUser } = useContext(AuthContext);
    const { userData } = useContext(DataContext);
    const [services, setServices] = useState([]); //  [name, description, image, price, raiting
    const [loadingServices, setLoadingServices] = useState(true); //  [name, description, image, price, raiting
    const [companyGet, setCompanyGet] = useState({
        ownerId: false,
        exist: false
    });

    useEffect(() => {
        const unSub = () => {
            ApiServices.get(`?company=${companyId}`, {}, {
                headers: {
                    'Authorization': `Bearer ${tokenUser}`
                }
            }).then((res) => {
                
                setServices(res.data.services)
                setLoadingServices(false)
            }).catch((err) => {
                console.log(err)
            })
        }
        return () => unSub();
    }, [!services]);

    const [companyData, setCompanyData] = useState(null);
    useEffect(() => {
        const unSub = onSnapshot(doc(db, "companies", companyId), (doc) => {
            if(doc.exists()) {
                setCompanyData(doc.data());    
                setCompanyGet({
                    ownerId: doc.data().uid === currentUser.uid ? true : false,
                    exist: true
                });
            } else {
                if(userData.uid) {
                    if (userData.accountType !== typeAccountData.user && userData.accountType) {
                        createCompany(userData)
                    }
                }
            }
            
        });
        return () => unSub();
    }, [!companyData]);

    //  Status
    const [onOnline, setOnOnline] = useState(false);
    const [lastOnline, setLastOnline] = useState(null);

    useEffect(() => {
        const unSub = () => {
            const onOnlineRef = ref(databaseFirebase, `/users/${companyId}/connections`);
            const lastOnlineRef = ref(databaseFirebase, `/users/${companyId}/lastOnline`)

            onValue(onOnlineRef, (snapshotOnline) => {
                if (snapshotOnline.exists()) {
                    setOnOnline(true);
                } else {
                    onValue(lastOnlineRef, (snapshot) => { 
                        setOnOnline(false);
                        setLastOnline(snapshot.val())
                    })
                }
            })
        }
        return () => unSub();
    }, []);




    //  OnSetStar
    const [onStar, setOnStar] = useState(false);
    const [requestStatus, setRequestStatus] = useState(false);
    const [serviceData, setServiceData] = useState(null); //  [name, description, image

    //  ModalState
    const [modalOpen, setModalOpen] = useState(false);
    const [modalOpenAddService, setModalOpenAddService] = useState(false);
    const [viewOpenService, setViewOpenService] = useState(false);

    //  ImagesNotExist
    const imageNotExist =
        process.env.PUBLIC_URL + "/images/users/notcompany.png";
    const imageService =
        process.env.PUBLIC_URL + "/images/services/odontologia.png";


    const NonCompany = () => {
        return (
            <div id="container-profile-not-found">
                <div className="content">
                    <div className="container-icon">
                        <BiBuilding className="icon" />
                    </div>
                    <h3>Company Not Found</h3>
                    <p>No se ah encontrado la empresa.</p>
                    <div className="load-company">
                        <Loading />
                    </div>
                </div>
            </div>
        );
    };

    const hanldeCopyPhone = () => {
        navigator.clipboard.writeText("+52 868 381 2782");
        setNotificationInfo("Numero de telefono copiado.");
    };


    return (
        <SideBar>
            {companyGet !== null && !loadingServices ? (
                companyGet.exist === false ? (
                    <NonCompany />
                ) : (
                    <div id="container-profile">
                        <div className="top">
                            <div className="left-side">
                                <div className="relative w-[135px] h-[135px] overflow-hidden drop-shadow-2xl">
                                    
                                    <span className={`
                                        fixed flex bottom-0 right-0 border-4 border-white w-8 h-8 rounded-full z-10
                                        ${onOnline ? "bg-green-500" : "bg-gray-400"}
                                    `} title={onOnline ? 'active' : getTimeAgo(lastOnline) } />
                                    
                                    <img
                                        src={ companyData.profile.photoURL ? companyData.profile.photoURL : imageNotExist }
                                        alt="company"
                                        className="w-full h-full object-cover object-center z-0 rounded-full"
                                    />
                                </div>
                            </div>

                            <div className="middle-side">
                                <div className="title-side">
                                    <h1>{ companyData.profile.nameCompany ? companyData.profile.nameCompany : 'Nombre de tu Empresa' }</h1>
                                </div>
                                <p>{ companyData.profile.description ? companyData.profile.description : 'Descripcion de tu empresa aqui...' }</p>
                            </div>

                            <div className={`
                                flex flex-row w-full
                                items-beetween gap-x-[10px]
                                justify-between lg:justify-end
                            `}>
                                { companyGet.ownerId && (
                                    <button className={`
                                        mt-auto px-6 py-1 bg-yellow-600 rounded-md text-white
                                        hover:bg-yellow-700 transition-all duration-300
                                        w-full lg:w-auto
                                    `} onClick={() => setRequestStatus(!requestStatus)} >Solicitud</button>
                                )}

                                { companyGet.ownerId && (
                                    <button onClick={() => setModalOpen(true)}
                                        className={`
                                            mt-auto px-6 py-1 bg-green-600 rounded-md text-white
                                            hover:bg-green-700 transition-all duration-300 w-full
                                            lg:w-auto
                                        `}
                                    >Editar</button>
                                )}
                            </div>
                        </div>

                        <div className="bottom">
                            <div className="left-bar">
                                <Raiting
                                    total={ companyData.profile.rating.total}
                                    count={ companyData.profile.rating.count}
                                    styles={'mx-auto'}
                                />

                                <div className="social">
                                    <div className="social-media">
                                        {
                                            companyData.profile.socialMedia.facebook && (
                                                <a href={ companyData.profile.socialMedia.facebook } className={`
                                                    cursor-pointer py-[10px] px-[5px] rounded-[8px]
                                                    min-width-[120px] transition-all duration-300
                                                    hover:shadow-4xl bg-white
                                                    flex flex-row items-center gap-x-[5px] no-underline
                                                `}>
                                                    <BsFacebook className={`
                                                        w-[30px] text-[1.5em] justify-self-start
                                                        cursor-pointer text-[#1877F2]
                                                    `} />
                                                    <p className={`
                                                        m-0 text-[#92929D]
                                                    `} >{ '@' + companyData.profile.socialMedia.facebook.split('/')[3] }</p>
                                                </a>
                                            )
                                        }
                                    </div>
                                </div>

                                <div className="phone-company">
                                    {
                                        companyData.profile.phone && (
                                            <button onClick={hanldeCopyPhone}>
                                                <BsTelephoneFill className="icon" />
                                                <p>+52 868 381 2782</p>
                                            </button>
                                        )
                                    }
                                </div>
                            </div>

                            <div className="right-side">
                                <h2>Servicios</h2>
                                <div className="services">

                                    {
                                        services.length > 0 && (
                                            services.map((service, index) => (
                                                <div className="service" key={index}>
                                                    <div className="top-service">
                                                        <div className="imgbx">
                                                            <img
                                                                src={service.data.image}
                                                                alt="service"
                                                            />
                                                        </div>
                                                        <h3>{service.data.name}</h3>
                                                        
                                                        <button className="favorite-button" onClick={() => setOnStar(!onStar)} >
                                                            {
                                                                !companyGet.ownerId && (
                                                                    onStar ? (
                                                                        <i className="bi bi-star-fill"></i>
                                                                    ) : (
                                                                        <i className="bi bi-star"></i>
                                                                    )
                                                                )
                                                            }
                                                        </button>
                                                    </div>
                                                    <div className="middle-service">
                                                        <p>
                                                            {service.data.description}
                                                        </p>
                                                    </div>
                                                    
                                                    <div className="bottom-service">
                                                        <div className="price">
                                                            <p>Precio:</p>
                                                            <span>MXN ${service.data.price}</span>
                                                        </div>
                                                        
                                                        <button className="hire" onClick={() => {
                                                            setViewOpenService(true);
                                                            setServiceData(service)
                                                        }
                                                        }>Contratar</button>
                                                        
                                                        {
                                                            companyGet.ownerId && (
                                                                <button className="edit"
                                                                    onClick={() => setModalOpenAddService(true)}
                                                                >Editar</button>
                                                            )
                                                        }
                                                    </div>
                                                </div>
                                            ))
                                        )
                                    }


                                    {
                                        //  AddService
                                        companyGet.ownerId ? (
                                            <div className="service-add" onClick={() => setModalOpenAddService(true)}>
                                                <IonIcon
                                                    className="iconAdd"
                                                    icon={addCircleOutline}
                                                />
                                                <h4>Agregar otro servicio.</h4>
                                            </div>
                                        ) : (
                                            ""
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                )
            ) : (
                <Loading />
            )}


            <AnimatePresence>
                {modalOpen && (
                    <EditCompany
                        closeModal={() => setModalOpen(false)}
                        statusModel={modalOpen}
                        dataActual={ companyData }
                    />
                )}


                {viewOpenService && (
                    <ViewService
                        serviceData={serviceData}
                        closeViewService={() => {
                            setViewOpenService(false);
                            setServiceData(null);
                        }}
                        statusViewService={viewOpenService}
                        isOwner={companyGet.ownerId}
                    />
                )}

                {requestStatus && (
                    <motion.div
                        initial={{ opacity: 0 }}
                        animate={{ opacity: 1 }}
                        exit={{ opacity: 0 }}
                        className="left-0 top-0 flex items-center justify-center w-screen h-screen bg-black/40 fixed" 
                    >
                        <div></div>
                    </motion.div>
                )}
            </AnimatePresence>
        </SideBar>
    );
}
