//  React
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom'

//  Pages
import Login from "./pages/Login";

import Register from './pages/Register'
import RegisterCompany from './pages/RegisterCompany';

import Home from "./pages/Home";
import Settings from "./pages/Settings";
import Company from "./pages/Company";
import Orders from "./pages/Orders";
import NotFound from "./pages/NotFound";
import Payment from "./pages/Payment";
import Category from "./pages/Category";
import Messages from "./pages/Messages";
import Favorites from "./pages/Favorites";
import ResetPassword from "./pages/ResetPassword";
import ForgotPassword from './pages/ForgotPassword';
import Admin from './pages/Tickets';
import TestingPage from './pages/TestingPage'
import NewProfile from './pages/NewProfile';
import Billing from './pages/Billing';
import Onboarding from './pages/Onboarding';

//  Route
import CheckCompanyRoute from './auth/routes/CheckCompanyRoute.js';
import PrivateRoute from './auth/routes/PrivateRoute';
import PublicRoute from './auth/routes/PublicRoute';
import AdminRoute from './auth/routes/AdminRoute';
import OnboardingRoute from './auth/routes/OnboardingRoute';

//  AuthProvider
import { AuthProvider } from './context/AuthContext'
import { ChatProvider } from './context/ChatContext'
import { DataProvider } from './context/DataContext'
import { CompanyProvider } from './context/CompanyContext'


//  App
export default function App() {
    return (
        <AuthProvider>
            <DataProvider>
                <CompanyProvider>
                    <Router>
                        <Routes>
                            <Route element={<PublicRoute />}>
                                {/*  Auth  */}
                                <Route index path="/login" element={<Login />} />
                                <Route path='/' element={<Navigate to={'/login'} />} />
                                <Route path="/register" element={<Register />} />
                                <Route path='/forgot-password' element={<ForgotPassword />} />
                            </Route>

                            <Route element={<PrivateRoute />}>
                                {/*  Protected  */}
                                <Route path='/home' element={<Home />} exact />
                                <Route path='/home/:category' element={<Category />} exact />

                                <Route path="/oldprofile/:id" element={<Company />} exact />
                                <Route path='/orders/' element={<Orders />} exact />

                                <Route path='/settings' element={<Settings />} exact />
                                <Route path='/settings/payment' element={<Payment />} exact />
                                <Route path='/settings/billing' element={<Billing />} exact />

                                <Route path='/messages' element={<><ChatProvider><Messages /></ChatProvider></>} exact />

                                <Route path='/favorites' element={<Favorites />} exact />

                                <Route path='/company/:id' element={<><ChatProvider><NewProfile /></ChatProvider></>} exact /> 
                            </Route>
                            

                            <Route element={<CheckCompanyRoute />}>
                                <Route path="/register-complete" element={<RegisterCompany />} />
                            </Route>

                            {/*  Onboarding  */}
                            <Route element={<OnboardingRoute />}>
                                <Route path='/onboarding' element={<Onboarding />} />
                            </Route>

    
                            {/*  Admin  */}
                            <Route element={<AdminRoute />}>
                                <Route path='/tickets' element={<Admin />} exact />
                            </Route>

                            {/*  404  */}
                            <Route path='/confirm-email' element={<TestingPage />} exact />

                            <Route path='/testing' element={<TestingPage />} exact />
                            <Route path="*" element={<NotFound />} />

                            {/*  ResetPassword  */}
                            <Route path='/reset-password' element={<ResetPassword />} exact />
                        </Routes>
                    </Router>
                </CompanyProvider>
            </DataProvider>
        </AuthProvider>
    );
}
