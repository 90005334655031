//  React
import { Link } from "react-router-dom";
import { useNavigate } from "react-router-dom";

//  CreateUser
import { loginUser, googleRegister } from "../auth/func/authUser";

//  Notification
import { setNotificationError, ConfigToast } from "../func/setNotification";


//  MainPage
const LoginPage = (props) => {
    //  Navigate
    const navigate = useNavigate();

    //  Images
    const LoginFrame = process.env.PUBLIC_URL + "/images/LoginFrame.png";
    const handleLogin = async (data) => {
        data.preventDefault();

        let loginData = {
            email: data.target[0].value,
            password: data.target[1].value,
            checkbox: data.target[2].checked,
        };

        const res = loginUser(loginData);
        if (typeof res === "object") {
            res.then((res) => {
                if (res !== undefined) {
                    setNotificationError(res);
                } else {
                    navigate("/home");
                }
            });
        } else if (typeof res === "boolean") {
            if (res === true) {
                navigate("/home");
            }
        }
    };

    const handleGoogle = async () => {
        googleRegister();
    };

    const handleFacebook = async () => {
        console.log("Facebook");
    };

    //  Render
    return (
        <>
            <ConfigToast />

            <div className="md:h-screen relative flex flex-col">
                <div className="m-3 text-center block md:hidden">
                    <h2 className="text-[#364670] font-bold">Work It</h2>
                </div>

                <div className="md:mx-auto md:my-auto" >
                    <div className="flex flex-col md:flex-row w-full justify-center relative gap-8">
                        <div className="h-48 relative my-auto mx-auto md:h-96">
                            <img
                                className="object-cover object-center h-full"
                                src={LoginFrame}
                                alt="LoginImage"
                            />
                        </div>

                        <div className="w-full px-12 md:w-fit md:p-0">
                            <h3 className="text-[#364670] font-semibold">
                                Iniciar sesión
                            </h3>
                            <p className="text-[#364670]">
                                ¿No tienes una cuenta?
                                <span className="pl-1">
                                    <Link
                                        className="text-[#9D9D9D] no-underline transition-colors duration-500"
                                        to="/register"
                                    >
                                        Registrate
                                    </Link>
                                </span>
                            </p>

                            <form className="mt-4 flex flex-col gap-y-4" onSubmit={ handleLogin } >
                                <div className="mb-3 gap-y-2 flex flex-col" >
                                    <label className="text-[#364670] font-medium" htmlFor="">Email</label>
                                    <input placeholder="email" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="inline-full-name" type="text" />
                                </div>

                                <div className="mb-3 gap-y-2 flex flex-col" >
                                    <label className="text-[#364670] font-medium" htmlFor="">Contraseña</label>
                                    <input type='password' placeholder="contraseña" className="shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight focus:outline-none focus:shadow-outline" id="inline-full-password" />
                                </div>

                                <div className="mb-3 flex-row flex items-center w-[390px]" >
                                    <input id="checkbox" className="mr-2 cursor-pointer" type="checkbox" />
                                    <label htmlFor="checkbox" className="text-[#364670] font-semibold cursor-pointer">Recuerdame</label>
                                    <Link to={'/forgot-password'} className="text-[#9D9D9D] no-underline transition-colors duration-500 ml-auto">¿Te olvidaste la contraseña?</Link>
                                </div>

                                <div className="mb-1" >
                                    <button type="submit" className="w-full bg-[#9D9D9D] rounded py-2 text-white text-lg hover:drop-shadow-2xl transition-all duration-500">Iniciar sesión</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    );
};

export default LoginPage;
