import { useState } from "react"
import { forgotPassword } from "../auth/func/authUser"
import { ConfigToast } from "../func/setNotification"

export default function ForgotPassword() {
    const [ emailReset, setEmailReset ] = useState('')
    const onHandleSubmit = () => {
        forgotPassword({ email: emailReset })
        setEmailReset('')
    }

    return (
        <>
            <ConfigToast />
            <div className="w-screen h-screen flex relative bg-[#eee]" >
                <div className="w-full h-full flex justify-center items-center">
                    <div className="w-full max-w-md bg-white rounded p-8">
                        <h3 className="w-full text-center mb-12 text-[#364670] font-semibold" >Work It</h3>
                        <div className="flex flex-col items-center">
                            <p className="text-gray-600" >Ingresa tu correo electrónico y te enviaremos un enlace para restablecer tu contraseña.</p>
                            <form className="w-full">
                                <div className="flex flex-col mb-4">

                                    <input placeholder="email" value={ emailReset } onChange={ (e) => setEmailReset(e.target.value) } className={`
                                        px-4 py-2 border-[1.5px] text-[1.2rem] text-[#333] outline-none
                                        transition-all duration-300 rounded-lg
                                        focus:opacity-80 focus:shadow-2xl
                                        placeholder-[#ced4da] placeholder:text-lowercase
                                    `} type="email" />

                                </div>
                                <div className="w-full flex flex-row justify-center" >
                                    <button onClick={ onHandleSubmit } type="button" className={`
                                        block bg-[#364670] hover:bg-[#364670] hover:opacity-90 
                                        text-white font-bold py-2 px-4 rounded transition-all duration-300
                                        hover:shadow-2xl
                                    `}>Restablecer contraseña</button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}