import { CardElement, useElements, useStripe, PaymentElement } from "@stripe/react-stripe-js";
import "../css/CardSectionStyles.css"
import { useState } from "react";

const CARD_ELEMENT_OPTIONS = {
    style: {
        base: {
            color: "#32325d",
            fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
            fontSmoothing: "antialiased",
            fontSize: "16px",
            "::placeholder": {
                color: "#aab7c4",
            },
        },
        invalid: {
            color: "#fa755a",
            iconColor: "#fa755a",
        },
    },
};


export default function CardForm({ callback, text = 'Crear metodo de pago' }) {
    const stripe = useStripe();
    const elements = useElements();
    const [error, setError] = useState(null);


    const handleSubmit = async (event) => {
        event.preventDefault();

        if (!stripe || !elements) {
            return;
        }

        const cardElement = elements.getElement(CardElement);
        const { token, error } = await stripe.createToken(cardElement);

        if (error) {
            setError(error.message);
            callback(token, error.message)
        } else {
            callback(token)
        }
    };

    return (
        <div className="w-full" >
            <form onSubmit={handleSubmit}>
                <CardElement options={CARD_ELEMENT_OPTIONS} />
                <br />
                <div className="flex flex-col gap-y-4" >
                    <button type="submit"
                        className="bg-principal text-white py-2 rounded shadow-md 
                        hover:shadow-4xl transition-all duration-300 hover:bg-opacity-90 px-3"
                    >{text}</button>
                </div>
            </form>
        </div>
    )
}