
//  Styles
import '../scss/orders.scss';

//  Components
import SideBar from '../components/SideBar';
import ResumeService from '../components/ResumeService';

//  Icons
import { BiSpreadsheet } from 'react-icons/bi';
import { FaBox } from 'react-icons/fa';
import { BsFillCartCheckFill, BsHouseDoorFill } from 'react-icons/bs';
import { useEffect, useState } from 'react';
import { AnimatePresence } from 'framer-motion';

export default function Orders() {
    //  States
    const [resumeService, setResumeService] = useState(null);

    //  Images
    const imageOrder = process.env.PUBLIC_URL + "/images/services/odontologia.png";

    useEffect(() => {
        console.log(resumeService)
    }, [resumeService])

    return (
        <SideBar>
            <div id="orders">
                <div className="orders__container">
                    <div className='orders__container_main' >
                        <div className="order">
                            <div className="order__header">
                                <div className="track">
                                    <ul id='progress'>
                                        <li className='active' ><BiSpreadsheet className='icon' /> <span>Pendiente</span> </li>
                                        <li className='active' ><FaBox className='icon' /> <span>Aceptado</span> </li>
                                        <li><BsFillCartCheckFill className='icon' /> <span>Preparando</span> </li>
                                        <li><BsHouseDoorFill className='icon' /> <span>Entregando</span> </li>
                                    </ul>
                                </div>
                            </div>
                            <div className="order__footer">
                                <div className="left_order_footer">
                                    <div className="imgbx">
                                        <img src={imageOrder} alt="odontologia" />
                                    </div>
                                </div>

                                <div className="middle_order_footer">
                                    <p className="title">Odontologia</p>
                                    <div className="time">
                                        <p>Fecha:</p>
                                        <span>22-Julio-2022</span>
                                    </div>
                                    <div className="price">
                                        <p>Total:</p>
                                        <span>$15.99</span>
                                    </div>
                                </div>

                                <div className="right_order_footer">
                                    <div className="status bar finish"><span>Finalizado</span></div>
                                    <button className="resume bar"
                                        onClick={() => setResumeService({
                                            id: 1,
                                            name: 'Odontologia',
                                            description: 'Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam, voluptatum.',
                                            price: 15.99,
                                            image: imageOrder
                                        })}
                                    >
                                        <span>Ver Resumen</span>
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            

            <AnimatePresence >
                {
                    resumeService !== null && (
                        <ResumeService
                            closeResumeService={() => setResumeService(null)}
                            service={resumeService}
                        />
                    )
                }
            </AnimatePresence>
        </SideBar>
    )
}