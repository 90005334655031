import React, { react, useContext } from "react"
import { Listbox, Transition } from "@headlessui/react"
import axios from "axios"
import { motion } from "framer-motion"
import { useEffect, useState } from "react"
import SelectedCountry from "../components/SelectedCountry"
import SelectedFromData from "../components/SelectedFromData"
import DateInputs from "react-date-inputs"
import { State } from "country-state-city";
import { ConfigToast, setNotificationError, setNotificationSuccess } from "../func/setNotification"
import countryData from "../data/countryAvaible"
import { createCompany } from "../auth/func/authCompany"
import { DataContext } from "../context/DataContext"
import { CardElement, Elements, PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { loadStripe } from "@stripe/stripe-js"
import CardForm from "../components/CardForm"
import { AuthContext } from "../context/AuthContext"
import ComponentImageUpload from "../components/ComponentImageUpload"
import ComponentInput from "../components/ComponentInput"
import ComponentButton from "../components/ComponentButton"
import { Navigate } from "react-router-dom"
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)

const dataCompany = [
    { id: 0, name: 'Escoje un tipo de empresa:', },
    { id: 1, name: 'Persona Física con Actividad Empresarial' },
    { id: 2, name: 'Sociedad de Responsabilidad Limitada (S de RL)' },
    { id: 3, name: 'Sociedad Anónima (SA)' },
    { id: 4, name: 'Sociedad Civil (SC)' },
    { id: 5, name: 'Organización sin fines de lucro' },
]

const categoryData = [
    { id: 0, name: 'Escoje una categoria:', },
    { id: 1, name: 'Leyes', },
    { id: 2, name: 'Comunicación', },
    { id: 3, name: 'Salud', },
    { id: 4, name: 'Industrial', },
    { id: 5, name: 'Oficios', },
    { id: 6, name: 'Publicidad', },
    { id: 7, name: 'Construcción', },
    { id: 8, name: 'Educativo', },
    { id: 9, name: 'Gastronomía', },
    { id: 10, name: 'Programación', },
    { id: 11, name: 'Finanzas', },
    { id: 12, name: 'Otros', },
]

let dataState = State.getStatesOfCountry('MX').map((e) => ({ id: State.getStatesOfCountry('MX').indexOf(e) + 1, isoCode: e.isoCode, name: e.name }))
dataState = [
    {id: 0, name: 'Escoje un estado:', },
    ...dataState
]


const hourData = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10','11', '12', '13', '14', '15', '16', '17', '18', '19', '20','21', '22', '23']
const AvatarImageNotFound = process.env.PUBLIC_URL + "/images/users/notuser.png"
const handleDay = (e, daysOn, setDays) => {
    if (daysOn.includes(e)) {
        setDays(daysOn.filter((day) => day !== e))
    } else {
        setDays([...daysOn, e])
    }
}

const CompontentSetHour = ({selected, setSelected, data}) => {
    return (
        <div className="w-full flex flex-row gap-x-1" >
            <Listbox value={selected} onChange={setSelected}  >
                {({ open }) => (
                    <div className="relative mt-2 w-full" title={'Hora'} >
                        <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white shadow-md cursor-pointer focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-blue-500" >
                            <span className="block truncate w-full" >{ selected + ':00 H' }</span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none" >
                                <i className="bi bi-chevron-down"></i>
                            </span>
                        </Listbox.Button>
                        <Transition show={open} enter="transition duration-100 ease-out" leaveFrom="opacity-100" leaveTo="opacity-0">
                            <Listbox.Options className="absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm" >
                                {data.map((e, i) => (
                                    <Listbox.Option key={i} className={({ active }) => `${active ? 'text-amber-900 bg-amber-100' : 'text-gray-900'} cursor-pointer select-none relative px-4 py-2`} value={e} >
                                        {({ selected, active }) => (
                                            <>
                                                <span className={`${selected ? 'font-medium' : 'font-normal'} truncate flex flex-row gap-x-1`}>
                                                    <span className="w-10" >{e+":00"}</span>
                                                    <span>H</span>
                                                </span>
                                                {selected ? (
                                                    <span className={`${active ? 'text-amber-600' : 'text-amber-600'}`}
                                                    >
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                )}
            </Listbox>
        </div>
    )
}

const ComponentDayPicker = ({onDays, setDays}) => {
    const daysArray = [
        {id: 1, name: 'Domingo', abreviation: 'Dom'},
        {id: 2, name: 'Lunes', abreviation: 'Lun'},
        {id: 3, name: 'Martes', abreviation: 'Mar'},
        {id: 4, name: 'Miercoles', abreviation: 'Mie'},
        {id: 5, name: 'Jueves', abreviation: 'Jue'},
        {id: 6, name: 'Viernes', abreviation: 'Vie'},
        {id: 7, name: 'Sabado', abreviation: 'Sab'},
    ]
    return (
        <div className="w-full flex flex-row justify-center items-center" >
            <div className="w-full flex flex-row justify-center items-center mx-auto gap-x-0" >
                {
                    daysArray.map((e, i) => (
                        <button className={`
                            p-2 border w-[50px] h-[50px] ${ onDays.includes(e.id) ? 'bg-[#4e92df] text-white' : 'bg-white text-slate-800' }
                        `} title={e.name} key={e.id} onClick={() => handleDay(e.id, onDays, setDays)} >
                            <span className="text-sm font-semibold" >{e.abreviation}</span>
                        </button>
                    ))
                }
            </div>
        </div>
    )
}

const ComponentOptionMenu = ({ title, number, page, setStep }) => {
    let active = number === page ? true : false
    return (
        <li className="flex flex-row gap-x-2 items-center" >
            <button title={title} className="flex flex-row gap-x-2 items-center" onClick={() => setStep(number)} >
                <i className={`
                    bi text-xl transition-all duration-300
                    ${active ? `text-principal bi-${number}-circle-fill` : `text-slate-800 bi-${number}-circle`}
                `}></i>
                <span className={`
                    text-sm font-semibold transition-all duration-300
                    ${active ? 'text-principal' : 'text-slate-800'}
                `}>{title}</span>
            </button>
        </li>
    )
}

const ComponentRegister = ({ active, text='Continuar', callback }) => {
    return (
        <button onClick={() => { active && callback() }} className={`
             text-white py-2 rounded shadow-md 
            hover:shadow-4xl transition-all duration-300 hover:bg-opacity-90

            ${ active ? 'bg-principal cursor-pointer' : 'bg-principal/70 cursor-default' }
        `}>
            <span>{text}</span>
        </button>
    )
}


const ComponentModal = ({ children }) => {
    return (
        <motion.div className={`
            w-full bg-[#ffffff] phone:rounded-lg phone:shadow-4xl
            flex flex-col px-4 phone:p-4 phone:max-w-md mx-auto md:w-md
        `}   
        initial={{ opacity: 0, y: 100 }}
        animate={{ opacity: 1, y: 0 }}
        exit={{ opacity: 0, y: 100 }} >
            {children}
        </motion.div>
    )
}

const ComponentFooterTop = ({title, subTitle, step, setStep}) => {
    return (
        <>
            <div className="relative flex flex-row items-center pb-2" >
                <div className="w-full absolute" >
                    <button
                        onClick={() => setStep(step - 1)}
                        className="text-slate-800 font-semibold"
                    ><i className="bi bi-arrow-left-short text-[2rem]"></i></button>
                </div>

                <h3 className="w-full text-2xl text-[#30313D] text-center" >{ title }</h3>
            </div>
            <p className="text-zinc-600 text-sm text-justify" >{ subTitle }</p>
        </>
    )
}

const ErrorAlert = ({ text, camp }) => {
    return (
        <div className="flex flex-row items-center w-full gap-x-1 text-[#fc526a]" >
            <i className="bi bi-exclamation-triangle-fill text-base"></i>
            <span className="text-sm" >{ text } {camp && <b>"{camp}"</b>}</span>
        </div>
    )
}

const optionsRfcApi = (rfcUser) => {
    return {
        method: 'POST',
        url: 'https://verifier.p.rapidapi.com/rfc/verify',
        headers: {
            'Content-Type': 'application/json',
            'X-RapidAPI-Key': process.env.REACT_APP_RFC_API_KEY,
            'X-RapidAPI-Host': process.env.REACT_APP_RFC_API_URL
        },
        data: { 
            rfc: rfcUser 
        }
    }
}


export default function RegisterCompany() {
    const { tokenUser } = useContext(AuthContext)
    const { userData } = useContext(DataContext)
    const [timeValue, setTimeValue] = useState({
        start: hourData[0],
        end: hourData[1],
    })
    const [onErrorSubmit, setOnErrorSubmit] = useState({
        status: false,
        message: '',
        camp: ''
    })
    const [tokenUserCard, setTokenUserCard] = useState('')
    const [checkedButton, setCheckedButton] = useState(false)
    const [cardUser, setCardUser] = useState()
    const [errCardToken, setErrCardToken] = useState('')
    const [daysOn, setDaysOn] = useState([])
    const [photoUser, setPhotoUser] = useState(null)
    const [errRfc, setErrRfc] = useState('')
    const [err, setErr] = useState(0)
    const [rfcUser, setRfcUser] = useState('')
    const [step, setStep] = useState(1)
    const [country, setCountry] = useState(countryData[0])
    const [typeCompany, setTypeCompany] = useState(dataCompany[0])
    const [onPromoCode, setOnPromoCode] = useState('')
    const [errorPage, setErrorPage] = useState({
        error: null,
        page: 0,
        index: null
    })
    const [formInputs, setFormInputs] = useState({
        email: '',
        date: '',
        state: dataState[0],
        category: categoryData[0],
        description: '',
        profileImage: null,
        nameCompany: '',
    })
    const [formFirst, setFormFirst] = useState({
        country: country ? country : countryData[0],
        typeCompany: typeCompany ? typeCompany : dataCompany[0],
    })
    //  Ubicacion y Tipo de empresa, error
    useEffect(() => {
        if (err === 1 && formFirst.country.id !== 0 && formFirst.typeCompany.id !== 0) {
            setErr(0)
        }
    }, [formFirst])

    //  Datos personales, Razón social
    useEffect(() => {
        if (err === 2 && formInputs.name !== '' && formInputs.lastName !== '') {
            setErr(0)
        }
    }, [formInputs])

    useEffect(() => {
        if (err === 3 && formInputs.email !== '') {
            setErr(0)
        }
    }, [formInputs])

    useEffect(() => {
        if (err === 4 && formInputs.date !== '') {
            setErr(0)
        }
    }, [formInputs])

    useEffect(() => {
        if (err === 5 && formInputs.state.id !== 0) {
            setErr(0)
        }
    }, [formInputs])

    useEffect(() => {
        setRfcUser(rfcUser.toUpperCase())
        if (err === 6 && rfcUser.length === 13) {
            setErr(0)
        }
    }, [rfcUser])


    useEffect(() => {
        setFormFirst({
            country: country,
            typeCompany: typeCompany,
        })  
    }, [country, typeCompany])

    const handleImage = (e) => {
        setPhotoUser(URL.createObjectURL(e.target.files[0]))
        setFormInputs({
            ...formInputs,
            profileImage: e.target.files[0]
        })
    }

    const handleCard = async (tokenCard, error) => {
        if (error) {
            console.log(error)
            setErrCardToken(error)
            return;
        }

        setStep(step + 1)
        setTokenUserCard(tokenCard)
    }

    const checkData = async (variables, page) => {
        for(const elem of variables) {
            console.log(elem)
            if(typeof(elem) === "object") {
                if(elem.id && elem.id === 0) {
                    setErrorPage({
                        error: elem,
                        page: page,
                        index: variables.indexOf(elem)
                    })
                    return;
                }

                if(elem.value && elem.value.length === 0 && elem.valueName === "onDays") {
                    setErrorPage({
                        error: elem.value,
                        page: page,
                        index: variables.indexOf(elem)
                    })
                    return;
                }

                if(elem.photo === null && elem.valueName === "photoCompany") {
                    setErrorPage({
                        error: elem.photo,
                        page: page,
                        index: variables.indexOf(elem)
                    })
                    return;
                }
            }

            if(typeof(elem) === "object") {
                if(elem.id === 0) {
                    console.log(elem)
                    setErrorPage({
                        error: elem,
                        page: page,
                        index: variables.indexOf(elem)
                    })
                    return;
                }
            }

            //  Check index of variable on array

            if(typeof(elem) === "string") {
                if(elem === '') {
                    setErrorPage({
                        error: elem,
                        page: page,
                        index: variables.indexOf(elem)
                    })
                    return;
                }
            }
        }

        setStep(step + 1)
    }





    const handleSubmit = async () => {
        const data = {
            //  First Page
            country: country,
            state: formInputs.state,
            category: formInputs.category,
            description: formInputs.description,

            //  Second Page
            photo: formInputs.profileImage,
            nameCompany: formInputs.nameCompany,
            facebook: formInputs.facebook || '',
            instagram: formInputs.instagram || '',
            linkedin: formInputs.linkedin || '',

            //  Third Page
            daysOn: daysOn,
            timeOnOpen: {
                start: timeValue.start,
                end: timeValue.end,
            },

            //  Subscription
            promoCode: onPromoCode,
        }

        //  ValidateData
        let status = true
        for (const prop in data) {
            if (prop === 'photo') {continue;}
            if (prop === 'facebook') {continue;}
            if (prop === 'instagram') {continue;}
            if (prop === 'linkedin') {continue;}
            if (prop === 'promoCode') {continue;}

            if (!data[prop]) {
                console.log(prop, data[prop])
                status = false
            }

            if (prop === 'category' && data[prop].id === 0) {status = false}
            if (prop === 'state' && data[prop].id === 0) {status = false}
            if (prop === 'country' && data[prop].id === 0) {status = false}
            if (prop === 'daysOn' && data[prop].length === 0) {status = false}
            if (prop === 'timeOnOpen' && data[prop].start === '' && data[prop].end === '') {status = false}
            if (prop === 'description' && data[prop].length >= 280) {status = false}
            if (!tokenUserCard) { status = false }
            
            if (status === false) {
                setOnErrorSubmit({
                    message: 'Debes de llenar el campo llamado',
                    status: true,
                    camp: prop ? prop : 'Tarjeta',
                })

                break;
            }
        }
        if(status === false) {return;}

        console.log(data)

        createCompany(userData, data, tokenUserCard, tokenUser).then((response) => {
            if(response) {
                if(response.success === true) {
                    window.location.reload()
                }
            }
        }).catch((error) => {
            console.error(error)
            setOnErrorSubmit({
                message: 'Error al crear la cuenta de empresa.',
                status: true,
                camp: '',
            })
        })
    }







    return (
        <>
            <ConfigToast />
            <div className="flex flex-row" > 
                <aside className="hidden md:block z-40 w-64 min-w-[16rem] bg-[#ffffff] h-screen transition-transform shadow-4xl" >
                    <div className="h-full px-3 py-4 overflow-y-auto" >
                        <ul className="space-y-2 font-medium">
                            <ComponentOptionMenu title={'Datos de la empresa'} number={1} page={step} setStep={setStep} />
                            <ComponentOptionMenu title={'Mas datos de empresa'} number={2} page={step} setStep={setStep} />
                            <ComponentOptionMenu title={'Horarios'} number={3} page={step} setStep={setStep} />
                            <ComponentOptionMenu title={'Suscripcion'} number={4} page={step} setStep={setStep} />
                            <ComponentOptionMenu title={'Terminos y condiciones'} number={5} page={step} setStep={setStep} />
                        </ul>
                    </div>
                </aside>
                <main className="flex flex-col w-full h-screen bg-[#fff] overflow-y-auto phone:py-10" >
                    <div className="flex flex-col gap-y-6 py-4" >
                        {
                            step === 1 && (
                                <ComponentModal >
                                    <ComponentFooterTop setStep={setStep} step={step} title={'Datos de la empresa.'} subTitle={'Estos datos serán visibles para todos los usuarios de la plataforma.'} />

                                    <div className="py-10 flex flex-col gap-y-6" >
                                        <div className="">
                                            <SelectedCountry 
                                                country={countryData}
                                                setCountryUser={setCountry} 
                                                value={formFirst.country} 
                                                labelText={'Ubicacion de la empresa.'} 
                                                onError={err === 1 && formFirst.country.id === 0 ? true : false}
                                            />

                                            {   
                                                country === errorPage.error && (
                                                    <ErrorAlert text={'Debes de seleccionar un pais.'} />
                                                )
                                            }
                                        </div>


                                        <div>
                                            <SelectedFromData
                                                setData={(e) => setFormInputs({...formInputs, state: e})}
                                                labelText={'Estado'}
                                                selected={formInputs.state}
                                                data={dataState}
                                                onError={err === 5 && formInputs.state.id === 0 ? true : false}
                                            />
                                            {   
                                                formInputs.state === errorPage.error && (
                                                    <ErrorAlert text={'Debes de seleccionar tu estado.'} />
                                                )
                                            }
                                        </div>


                                        <div>
                                            <SelectedFromData 
                                                labelText={'Categoria de empresa.'}
                                                data={categoryData}
                                                setData={(e) => setFormInputs({...formInputs, category: e})}
                                                selected={formInputs.category}
                                            />
                                            {   
                                                formInputs.category === errorPage.error && (
                                                    <ErrorAlert text={'Debes de seleccionar una categoria.'} />
                                                )
                                            }
                                        </div>


                                        <div className="flex flex-col gap-y-2" >
                                            <label htmlFor="description-register-complete"><span className="w-full text-[#1a1f36] text-sm font-semibold" >Descripcion de la empresa</span></label>
                                            <textarea className={`
                                                w-full rounded-md border border-[#e0e1e4] px-3 py-2
                                                transition duration-500 ease-in-out transform 
                                                hover:shadow-4xl resize-none
                                                focus:outline-none focus:ring-2 focus:ring-blue-300 focus:border-blue-500
                                            `} placeholder='descripcion...' rows={4} onChange={(e) => setFormInputs({...formInputs, description: e.target.value})} value={formInputs.description}></textarea>
                                            {
                                                errorPage.page === 1 && errorPage.index === 3 && errorPage.error === formInputs.description && (
                                                    <ErrorAlert text={'Pon una descripcion a tu empresa.'} />
                                                )
                                            }
                                        </div>

                                        <div className="flex flex-col gap-y-4" >
                                            <ComponentButton callback={ () => {
                                                //  Add varable name
                                                checkData([
                                                    country, 
                                                    formInputs.state, 
                                                    formInputs.category, 
                                                    formInputs.description,
                                                ], 1)
                                            }} />
                                        </div>
                                    </div>
                                </ComponentModal>
                            )
                        }

                        {
                            step === 2 && (
                                <ComponentModal>
                                    <ComponentFooterTop setStep={setStep} step={step} title={'Mas datos de empresa.'} subTitle={'Estos datos serán visibles para todos los usuarios de la plataforma.'} />
                                
                                    <div className="py-10 flex flex-col gap-y-6">
                                        <div className="flex flex-col gap-y-2" >
                                            <label htmlFor="image-register-complete" ><span className="w-full text-[#1a1f36] text-sm font-semibold" >Foto de tu empresa.</span></label>
                                            <ComponentImageUpload 
                                                name={'image-register-complete'} 
                                                handleImage={handleImage}
                                                onValue={photoUser}
                                            />
                                            {
                                                errorPage.page === 2 && errorPage.index === 0 && errorPage.error === formInputs.profileImage && (
                                                    <ErrorAlert text={'Debes de subir una foto de tu empresa.'} />
                                                )
                                            }
                                        </div>


                                        <div className="flex flex-col gap-y-2" >
                                            <label htmlFor="name-company-register-complete"><span className="w-full text-[#1a1f36] text-sm font-semibold" >Nombre de la empresa</span></label>
                                            <ComponentInput name={'name-company-register-complete'} placeholder={'Nombre de la empresa'} setValue={(e) => setFormInputs({...formInputs, nameCompany: e})} selectedValue={formInputs.nameCompany} />
                                            {
                                                errorPage.page === 2 && errorPage.index === 1 && errorPage.error === formInputs.nameCompany && (
                                                    <ErrorAlert text={'Debes de ponerle un nombre a tu empresa.'} />
                                                )
                                            }
                                        </div>

                                        <div className="flex flex-col gap-y-2" >
                                            <label htmlFor="date-register-complete"><span className="w-full text-[#1a1f36] text-sm font-semibold" >Redes sociales. (no es obligatorio)</span></label>
                                            <ComponentInput placeholder={'Facebook (URL)'} />
                                            <ComponentInput placeholder={'Instagram (URL)'} />
                                            <ComponentInput placeholder={'Linkedin (URL)'} />
                                        </div>

                                        <div className="flex flex-col gap-y-4" >
                                            <ComponentButton callback={() => {
                                                console.log(errorPage)
                                                checkData([
                                                    {photo: formInputs.profileImage, valueName: "photoCompany"},
                                                    formInputs.nameCompany,
                                                ], 2)
                                            }}/>
                                        </div>
                                    </div>
                                </ComponentModal>
                            )
                        }

                        {
                            step === 3 && (
                                <ComponentModal>
                                    <ComponentFooterTop setStep={setStep} step={step} title={'Horarios.'} subTitle={'Estos datos serán visibles para todos los usuarios de la plataforma.'} />

                                    <div className="py-10 flex flex-col gap-y-6">
                                        <div className="flex flex-col gap-y-2" >
                                            <label htmlFor="date-register-complete"><span className="w-full text-[#1a1f36] text-sm font-semibold" >Horarios de atención.</span></label>
                                            <ComponentDayPicker
                                                onDays={daysOn}
                                                setDays={setDaysOn}
                                            />

                                            {
                                                errorPage.page === 3 && errorPage.index === 0 && errorPage.error === daysOn && (
                                                    <ErrorAlert text={'Inserta tus dias laborales.'} />
                                                )
                                            }
                                            
                                            <div className="flex flex-row w-full gap-x-3 mt-4" >
                                                <div className="flex flex-col w-1/2" >
                                                    <span className="w-full text-[#1a1f36] text-sm font-semibold" >Hora de apertura.</span>
                                                    <CompontentSetHour
                                                        selected={timeValue.start}
                                                        setSelected={(e) => setTimeValue({ ...timeValue, start: e })}
                                                        data={hourData}
                                                    />
                                                </div>

                                                <div className="flex flex-col w-1/2">
                                                    <span className="w-full text-[#1a1f36] text-sm font-semibold" >Hora de cierre.</span>
                                                    <CompontentSetHour
                                                        selected={timeValue.end}
                                                        setSelected={(e) => setTimeValue({ ...timeValue, end: e })}
                                                        data={hourData}
                                                    />
                                                </div>
                                            </div>
                                        </div>

                                        <div className="flex flex-col gap-y-4" >
                                            <ComponentButton callback={ () =>{
                                                checkData([
                                                    {value: daysOn, valueName: "onDays"},
                                                ], 3)
                                            }} />
                                        </div>
                                    </div>
                                </ComponentModal>
                            )
                        }

                        {
                            step === 4 && (
                                <ComponentModal>
                                    <ComponentFooterTop setStep={setStep} step={step} title={'Suscripcion.'} subTitle={'Llena estos datos con tu tarjeta de debit o credito para pagar tu mensualidad con un precio de 150$ pesos mexicanos al mes.'} />
                                    
                                    <div className="py-6">
                                        <h1 className="text-[#697286]">Empieza tu suscripcion.</h1>
                                        <div className="flex flex-row gap-1">
                                            <h1 className="text-black text-[36px]">MXN $150.00</h1>
                                            <div className="flex flex-col my-auto">
                                                <span className="text-[14px] text-[#697286]">por</span>
                                                <span className="text-[14px] text-[#697286]">mes</span>
                                            </div>
                                        </div>
                                    </div>

                                    <div className="pb-10 pt-2 flex flex-col gap-y-6">
                                        <div>
                                            <label><span className="w-full text-[#1a1f36] text-sm font-semibold" >Codigo promocional.</span></label>
                                            <ComponentInput setValue={setOnPromoCode} selectedValue={onPromoCode} placeholder={"codigo promocional"} />
                                        </div>

                                        <div className="flex flex-col gap-y-2" >
                                            <label><span className="w-full text-[#1a1f36] text-sm font-semibold" >Datos bancarios.</span></label>
                                            <Elements stripe={stripePromise} >
                                                <CardForm callback={handleCard} />
                                            </Elements>
                                        </div>

                                        <div className="flex flex-col gap-y-4" >
                                            
                                            {
                                                errCardToken && (
                                                    <ErrorAlert text={errCardToken } />
                                                )
                                            }
                                        </div>
                                    </div>
                                </ComponentModal>
                            )
                        }

                        {
                            step === 5 && (
                                <ComponentModal>
                                    <ComponentFooterTop setStep={setStep} step={step} title={'Terminos y condiciones.'} subTitle={'Acepta los terminos y condiciones de la empresa para poder completar tu cuenta de empresa.'} />
                                    
                                    <div className="py-6 flex flex-col gap-y-6">
                                        <div className="flex flex-col gap-y-2" >
                                            <label><span className="w-full text-[#1a1f36] text-sm font-semibold" >Reglamento para empresas para utilizar Work It:</span></label>
                                            <p className="text-zinc-600 text-sm text-justify" >Lo mas importante es tener siempre una comunicacion cercana con los usuarios que requieran el servicio brindado por tu empresa.</p>
                                            <p className="text-zinc-600 text-sm text-justify" >Todas las empresas deberan facilitar a los clientes las facturas de los servicios brindados.</p>
                                            <p className="text-zinc-600 text-sm text-justify" >Las empresas que provean un servicio que tenga fecha de entrega de un producto, se debera notificar al usuario con al menos 2 dias de anticipacion de la fecha acordada entre ambas partes. En caso de ser una cita medica, legal, Marketing etc y se cancele la sesion se debera de notificar al usuario y reagendar lo antes posible.</p>
                                            <p className="text-zinc-600 text-sm text-justify" >Si el gasto para realizar o brindar tu servicio excede el precio en el que se especifica en tu perfil, se notificara de inmediato a la persona que compro tus servicios y se debera de llegar a un acuerdo. Y este mismo podra cancelar el servicio.</p>
                                            <p className="text-zinc-600 text-sm text-justify" >Los pedidos que no tengan una respuesta positiva o negativa en un lapso de 12 horas se le notificara al usuario que la empresa no se encuentra disponible para realizar el servicio.</p>
                                        </div>

                                        <div className="flex flex-col gap-y-2" >
                                            <label><span className="w-full text-[#1a1f36] text-sm font-semibold" >Recompensas:</span></label>
                                            <p className="text-zinc-600 text-sm text-justify" >Todas las empresas tienen una valoracion que dan los usuarios, por lo tanto seguir los pasos anteriores hara que tu empresa este mejor posicionada dentro de la plataforma.</p>
                                            <p className="text-zinc-600 text-sm text-justify" >A cada empresa se le dara codigos de promocion, es decir si sus clientes frecuenten se dan de alta dentro de la plataforma con los codigos que se te facilitaran por correo electronico, podras tener una recompensa de mes gratis.</p>
                                            <p className="text-zinc-600 text-sm text-justify" >Las empresas que agreguen promociones especiales dentro de la plataforma y tienen una venta de al menos 10 compras el proximo mes obtendras un codigo de 50% de descuento.</p>
                                            <p className="text-zinc-600 text-sm text-justify" >La empresa que realize mayor numero de transacciones dentro de su categoria al final del año (31 de diciembre del presente año) obtendra un codigo de UN AÑO GRATIS!!!</p>
                                            <p className="text-zinc-600 text-sm text-justify" >Los pedidos que no tengan una respuesta positiva o negativa en un lapso de 12 horas se le notificara al usuario que la empresa no se encuentra disponible para realizar el servicio.</p>
                                        </div>

                                        <div className="flex flex-col gap-y-2" >
                                            <label><span className="w-full text-[#1a1f36] text-sm font-semibold" >Acepto los terminos:</span></label>
                                            <div className="flex w-full flex-row gap-2 align-middle" >
                                                <input checked={checkedButton} onChange={() => setCheckedButton(!checkedButton)} id="accept-terms" className="cursor-pointer" type="checkbox" />
                                                <label htmlFor="accept-terms">
                                                    <span className="text-zinc-600 text-sm text-justify cursor-pointer">Acepto los terminos y condiciones de la empresa.</span>
                                                </label>
                                            </div>
                                        </div>

                                        <div className="flex flex-col gap-y-4" >
                                            {
                                                <ComponentRegister active={checkedButton} text="Registrarse y Suscribirse" callback={handleSubmit}/>
                                            }
                                            {
                                                onErrorSubmit.status && (
                                                    <ErrorAlert text={onErrorSubmit.message} camp={onErrorSubmit.camp} />
                                                )
                                            }
                                        </div>
                                    </div>
                                </ComponentModal>
                            )
                        }
                    </div>    
                </main>
            </div>
        </>
    )
}