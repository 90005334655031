export const categoryData = {
    'law': { 'title': 'leyes', 'icon': 'GoLaw', 'path': 'law', 'index': 1 },
    'communication': { 'title': 'comunicación', 'icon': 'BiMessageSquareDetail', 'path': 'communication', 'index': 2 },
    'health': { 'title': 'salud', 'icon': 'GiHealthNormal', 'path': 'health', 'index': 3 },
    'industrial': { 'title': 'industrial', 'icon': 'FaIndustry', 'path': 'industrial', 'index': 4 },
    'trades': { 'title': 'oficios', 'icon': 'FaRegBuilding', 'path': 'trades', 'index': 5 },
    'advertising': { 'title': 'publicidad', 'icon': 'MdAdsClick', 'path': 'advertising', 'index': 6 },
    'building': { 'title': 'construcción', 'icon': 'FaUsersCog', 'path': 'building', 'index': 7 },
    'educational': { 'title': 'educativo', 'icon': 'IoIosSchool', 'path': 'educational', 'index': 8 },
    'gastronomy': { 'title': 'gastronomía', 'icon': 'MdOutlineFoodBank', 'path': 'gastronomy', 'index': 9 },
    'programming': { 'title': 'programación', 'icon': 'BiCodeAlt', 'path': 'programming', 'index': 10 },
    'finance': { 'title': 'finanzas', 'icon': 'MdAttachMoney', 'path': 'finance', 'index': 11 },
    'others': { 'title': 'otros', 'icon': 'BiPlusCircle', 'path': 'others', 'index': 12 }
}