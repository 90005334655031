import { useContext, useEffect, useState } from "react";
import ApiServices from "../api/services.api"
import ApiFavorites from "../api/favorites.api";
import { AuthContext } from "../context/AuthContext";
import { AiFillStar, AiOutlineStar } from "react-icons/ai"


export default function FavoriteServices({ serviceId, callback }) {
    const [serviceData, setServiceData] = useState(null);
    const { tokenUser } = useContext(AuthContext);
    const [onStar, setOnStar] = useState(true);

    useEffect(() => {
        const unSub = ApiServices.get(`/${serviceId}`, {}, {
            headers: {
                Authorization: `Bearer ${tokenUser}`,
            },
        }).then((res) => {
            setServiceData(res.data.data);
        });

        return () => unSub;
    }, [!serviceData])

    useEffect(() => {
        if (!onStar) {
            callback(serviceId);
        }
    }, [onStar])

    return (
        <div className="p-6 flex flex-col gap-3 relative shadow-4xl rounded-lg w-[450px]">
            <div className="flex flex-row items-center gap-3 relative">
                <div className="w-[75px] h-[75px] overflow-hidden rounded-full relative">
                    <img
                        className="w-full h-full object-cover object-center absolute"
                        src={serviceData?.image}
                        alt="service"
                    />
                </div>
                <h3 className="text-xl text-principal font-semibold" >{serviceData?.name}</h3>
                <div className="absolute top-0 right-0" >
                    {
                        onStar ? (
                            <AiFillStar size={30} className="text-yellow-400 cursor-pointer" onClick={() => setOnStar(!onStar)} />
                        ) : (
                            <AiOutlineStar size={30} className="text-yellow-400 cursor-pointer" onClick={() => setOnStar(!onStar)} />
                        )
                    }
                </div>
            </div>

            <div className="">
                <p className="text-[#9D9D9D]" >{serviceData?.description}</p>
            </div>

            <div className="flex flex-row justify-between items-center">
                <div className="flex flex-row items-center gap-1">
                    <p className="text-black font-semibold" >Precio:</p>
                    <span className="text-[#9D9D9D]">MXN ${ serviceData?.price }</span>
                </div>

                <button className="text-white bg-green-600 px-3 py-1 rounded">Contratar</button>
            </div>
        </div>
    )
}