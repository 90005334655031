import "../css/newprofile.css"
import SideBar from "../components/SideBar";
import { FaStar } from "react-icons/fa";
import { BiErrorCircle } from "react-icons/bi";
import { BiBuilding } from "@react-icons/all-files/bi/BiBuilding";
import { db, database as databaseFirebase } from "../config/firebase-config";
import { ImFacebook, ImLinkedin } from "react-icons/im"
import { BsFillTelephoneFill, BsInstagram } from "react-icons/bs"
import { BiSolidPencil, BiMessageSquareDetail } from "react-icons/bi"
import { AiOutlinePlusCircle, AiOutlineClose, AiOutlineCheckCircle } from "react-icons/ai"
import { useNavigate, useParams } from "react-router-dom";
import { CiWarning } from "react-icons/ci"
import { useContext, useEffect, useState } from "react";
import { AuthContext } from "../context/AuthContext";
import { DataContext } from "../context/DataContext";
import { ref, onValue, set } from "firebase/database";
import getTimeAgo from "../func/values";
import { Timestamp, arrayUnion, doc, getDoc, onSnapshot, updateDoc } from "firebase/firestore";
import { typeAccountData } from "../data/typeAccount";
import Loading from "../components/Loading";
import { BsPlus } from "react-icons/bs"
import { AnimatePresence, motion } from "framer-motion";
import ApiServices from "../api/services.api";
import ApiStripe from "../api/stripe.api";
import { Elements } from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import CardForm from "../components/CardForm";
import AddServiceComponent from "../components/AddService";
import { ChatContext } from "../context/ChatContext";
import { CompanyContext } from "../context/CompanyContext";
import ApiConnect from "../api/connect.api";
import ApiCustomer from "../api/customer.api";
const stars = Array(5).fill(0);
const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY)
const colors = {
    orange: "#FFBA5A",
    grey: "#A9A9A9",
};


const NonCompany = () => {
    return (
        <div id="container-profile-not-found">
            <div className="content">
                <div className="container-icon">
                    <BiBuilding className="icon" />
                </div>
                <h3>Company Not Found</h3>
                <p>No se ah encontrado la empresa.</p>
                <div className="load-company">
                    <Loading />
                </div>
            </div>
        </div>
    );
};

const AddService = ({ setOnNewService }) => {
    return (
        <div className="p-4 border-[2px] rounded-lg border-[#EAEAEA] gap-y-4 h-[240px] max-h-[240px] hover:border-[0px] hover:shadow-4xl transition-all duration-300">
            <button onClick={setOnNewService} className="w-full h-full flex flex-col items-center justify-center gap-y-2">
                <AiOutlinePlusCircle size={48} className="text-principal" />
                <h1 className="text-principal text-xl text-center">Agregar un nuevo servicio para tu empresa.</h1>
            </button>
        </div>
    )
}

const ViewService = ({ setOnBuy, setOnEdit, data, companyGet, handleDelete }) => {
    return (
        <div className="p-4 border-[2px] rounded-lg border-[#EAEAEA] gap-y-4 h-[240px] max-h-[240px] box-border flex flex-col">
            <div className="flex flex-row gap-x-4" >
                <div className="relative min-w-[70px] w-[70px] h-[70px] overflow-hidden drop-shadow-2xl" >
                    <img className="w-full h-full object-cover rounded-lg" src={data.data.image} alt="logo" />
                </div>
                <div>
                    <h1 className="text-principal font-bold text-xl" >{data.data.name}</h1>
                    <span className="text-[#9d9d9d] line-clamp-3" >{data.data.description}</span>
                </div>
            </div>

            <div className="flex flex-row justify-between mt-auto" >
                <div className="flex flex-row items-center gap-x-1 font-extrabold">
                    <FaStar size={20} color={colors.orange} />
                    <span className="text-principal text-lg">{((data.data.raiting.stars / data.data.raiting.count)).toFixed(1)}</span>
                    <span className="text-principal text-lg">({data.data.raiting.count})</span>
                </div>

                <div className="flex flex-col items-end">
                    <span className="text-principal text-xl font-extrabold">Desde ${data.data.price} MXN</span>
                    {data.data.discount > 1 && (
                        <span className="text-[#DA6666] font-extrabold">{data.data.discount}% de descuento</span>
                    )}
                </div>
            </div>

            <div className="flex flex-row justify-end gap-x-4">
                {companyGet.ownerId ? (
                    <>
                        <button className="bg-[#FFD56D] text-white px-8 py-1 rounded cursor-pointer hover:opacity-85 hover:shadow-4xl duration-300 transition-all" onClick={() => { setOnEdit({onEdit: true, idEdit: data.id}) }} >Editar</button>
                        <button className="bg-[#DA6666] text-white px-8 py-1 rounded cursor-pointer hover:opacity-85 hover:shadow-4xl duration-300 transition-all" onClick={handleDelete }>Eliminar</button>
                    </>
                ):(
                    <button className="bg-green-500 text-white px-8 py-1 rounded cursor-pointer hover:opacity-85 hover:shadow-4xl duration-300 transition-all" onClick={() => { setOnBuy({onBuy: true, idBuy: data.id, dataBuy: data, pageBuy: 1}) }} >Contactar</button>
                )}
            </div>
        </div>
    )
}

const getServices = (companyId, token, setServices) => {
    ApiServices.get("/", {
        headers: {
            Authorization: `Bearer ${token}`
        },
        params: {
            company: companyId
        }
    }).then(res => {
        setServices(res.data.services)
    }).catch(err => {
        console.log(err)
    })
}

const getCardsClient = (token, setCardsClient, setOnActiveCard, userID) => {
    ApiStripe.get(`/payment-methods/list/${ userID }`, {
        headers: {
            Authorization: `Bearer ${token}`
        }
    }).then(res => {
        setCardsClient(res.data.data.data)
        ApiCustomer.get(`/customer`, {}, {
            headers: {
                Authorization: `Bearer ${token}`
            }
        }).then(res => {
            setOnActiveCard(res.data.data.invoice_settings.default_payment_method)
        }).catch(err => {
            console.log(err)
        })
    }).catch(err => {
        console.log(err)
    })
}

const handleServicePay = async (navigate, tokenUser, onBuy, setOnBuy, dispatch) => {
    const date = Timestamp.now();
    ApiServices.post("/pay", {
        data: {
            serviceID: onBuy.idBuy,
            date: date,
        }
    }, {
        headers: {
            Authorization: `Bearer ${tokenUser}`
        },
    }).then(res => {
        if (res.status===201) {
            setOnBuy({onBuy: false, idBuy: null, dataBuy: null, pageBuy: 1})
            navigate("/messages")
            console.log("success")
        }
    }).catch(err => {
        console.log(err)
    })
}

export default function NewProfile() {
    const [chats, setChats] = useState([]);
    const [users, setUsers] = useState([]);
    const [onNewService, setOnNewService] = useState(false);
    const [coupon, setCoupon] = useState("");
    const [errorCardInvalid, setErrorCardInvalid] = useState(null)
    const [cardsClient, setCardsClient] = useState(null);
    const [onActiveCard, setOnActiveCard] = useState("");
    const [onOnline, setOnOnline] = useState(false);
    const [services, setServices] = useState([]);
    const [dayString, setDayString] = useState([]);
    const [lastOnline, setLastOnline] = useState(null);
    const [companyData, setCompanyData] = useState(null);
    const [raitingCompany, setRaitingCompany] = useState({});
    const [loadingServices, setLoadingServices] = useState(true);
    const [onEdit, setOnEdit] = useState({ onEdit: false, idEdit: null });
    const [onBuy, setOnBuy] = useState({ onBuy: false, idBuy: null, dataBuy: null, pageBuy: 1 });
    const [companyGet, setCompanyGet] = useState({ ownerId: false, exist: false });

    const { currentUser, tokenUser } = useContext(AuthContext)
    const { dispatch } = useContext(ChatContext)

    const params = useParams()
    const navigate = useNavigate()
    let companyId = params["id"];
    useEffect(() => {
        const unSub = onSnapshot(doc(db, "companies", companyId), (doc) => {
            if (doc.exists()) {
                setCompanyData(doc.data());
                setRaitingCompany(doc.data().profile.rating);
                setCompanyGet({
                    ownerId: doc.data().uid === currentUser.uid ? true : false,
                    exist: true
                });
            }
        });
        return () => unSub();
    }, [!companyData]);

    const onDays = ["Lun", "Mar", "Mie", "Jue", "Vie", "Sab", "Dom"]

    useEffect(() => {
        console.log("==== OnActive ====")
        const unSub = () => {
            const onOnlineRef = ref(databaseFirebase, `/users/${companyId}/connections`);
            const lastOnlineRef = ref(databaseFirebase, `/users/${companyId}/lastOnline`)
            onValue(onOnlineRef, (snapshotOnline) => {
                if (snapshotOnline.exists()) {
                    setOnOnline(true);
                } else {
                    onValue(lastOnlineRef, (snapshot) => {
                        setOnOnline(false);
                        setLastOnline(snapshot.val())
                    })
                }
            })
        }
        return () => unSub();
    }, []);

    useEffect(() => {
        console.log("==== OnData ====")
        const unSub = onSnapshot(doc(db, "companies", companyId), (doc) => {
            if (doc.exists()) {
                getCardsClient(tokenUser, setCardsClient, setOnActiveCard, currentUser.uid)
                let daysOn = []
                doc.data().profile.daysOpen.map((key, index) => {
                    daysOn.push(onDays[key - 1])
                })
                setDayString(daysOn);
                setCompanyData(doc.data());
                setCompanyGet({
                    ownerId: doc.data().uid === currentUser.uid ? true : false,
                    exist: true
                });
                getServices(companyId, tokenUser, setServices)
                setLoadingServices(false)
            }
        });
        return () => unSub();
    }, [!companyData])


    useEffect(() => {
        console.log("==== OnCoupon ====")
        const newCoupon = coupon.toUpperCase()
        setCoupon(newCoupon)
    }, [coupon])

    const handleCoupon = async () => {
        if (coupon === "") return;
        console.log(coupon)
    }


    const handleCard = async (tokenCard, error) => {
        if(error) {
            setErrorCardInvalid(error)
            return;
        }

        ApiStripe.post("/payment-method/associate", {
            data: {
                userId: currentUser.uid, 
                paymentMethodToken: tokenCard.id
            }
        }, {
            headers: {
                Authorization: `Bearer ${ tokenUser }`
            }, 
        }).then(() => {
            getCardsClient(tokenUser, setCardsClient, setOnActiveCard, currentUser.uid)
            setOnBuy({onBuy: onBuy.onBuy, idBuy: onBuy.idBuy, dataBuy: onBuy.dataBuy, pageBuy: 2})
        }).catch(err => {
            console.log(err)
        })
    }
    
    const setDefaultCard = async (tokenCard) => {
        if(tokenCard === onActiveCard) return;
        console.log(tokenCard)
        ApiStripe.post("/payment-method/default", {
            data: {
                userId: currentUser.uid, 
                paymentMethodId: tokenCard
            }
        }, {
            headers: {
                Authorization: `Bearer ${ tokenUser }`
            }, 
        }).then(() => {
            getCardsClient(tokenUser, setCardsClient, setOnActiveCard, currentUser.uid)
        }).catch(err => {
            console.log(err)
        })
    }

    const handleMessage = async () => {
        const dataUserCompany = await getDoc(doc(db, "users", companyData.uid))
        dispatch({ type: "CHANGE_USER", payload: dataUserCompany.data() });
        navigate("/messages")
    }



    const handleResumePay = async () => {
        console.log("hello")
        console.log(tokenUser)
        ApiConnect.get("/subscription/pay", {
            headers: {
                Authorization: `Bearer ${tokenUser}`
            }
        }).then(res => {
            console.log(res)
        }).catch(err => {
            console.log(err)
        })
    }


    const handleDelete = async (service) => {
        ApiServices.delete(`/${service}`, {
            headers: {
                Authorization: `Bearer ${tokenUser}`
            }
        }).then(res => {
            console.log(res)
        }).catch(err => {
            console.log(err)
        })
    }
        


    return (
        <SideBar>
            {companyData !== null ? (
                companyData.companyVerified ? (
                    companyGet.exist === false ? (
                        <NonCompany />
                    ) : onNewService === false ? (
                        <div className="flex-1 flex flex-col overflow-y-auto md:overflow-y-hidden relative" >
                            {companyGet.ownerId ? (
                                    <button onClick={() => console.log("hello")} className="bg-principal rounded-full p-1 absolute top-[5px] right-[5px]">
                                        <BiSolidPencil color="#fff" size={20} />
                                    </button>   
                                ) : (
                                    <button onClick={handleMessage} className="bg-green-500 rounded-full p-1 absolute top-[5px] right-[5px]">
                                        <BiMessageSquareDetail color="#fff" size={20} />
                                    </button>
                                )
                            }
                            
                            <div className="bg-[#DFDDE6]/30 p-3 flex flex-col md:flex-row rounded-lg gap-x-6 shadow-4xl m-3">
                                <div className="relative min-w-[70px] w-[120px]  h-[120px]  md:w-[70px]  md:h-[70px] overflow-hidden drop-shadow-2xl mx-auto md:m-0" >
                                    <span title={onOnline ? 'active' : getTimeAgo(lastOnline)} className={`
                                        border-t border-l border-[#ededf0] w-[14px] h-[14px] absolute bottom-0 right-0 rounded-tl-lg rounded-br-lg
    
                                        ${onOnline ? "bg-[#22c55d]" : "bg-gray-400"}
                                    `} />
                                    <img className="w-full h-full object-cover object-center z-0 rounded-lg" src={companyData.profile.photoURL} alt="logo" />
                                </div>
    
                                <div className="flex flex-col w-full gap-y-2" >
                                    <div className="flex flex-row justify-between items-center" >
                                        <h1 className="text-principal font-semibold text-3xl">{companyData.profile.nameCompany}</h1>
                                        <div className="flex flex-row gap-x-2">
                                            <span className="text-[#9D9D9D]">Raiting:</span>
                                            <div className="flex flex-row items-center" >
                                                {
                                                    stars.map((_, index) => {
                                                        return (
                                                            <FaStar
                                                                key={index}
                                                                size={20}
                                                                color={
                                                                    Math.round(raitingCompany.count / raitingCompany.total) > index ? colors.orange : colors.grey
                                                                }
                                                            />
                                                        )
                                                    })
                                                }
                                            </div>
                                        </div>
                                    </div>
    
                                    <div className="w-3/4" >
                                        <p className="text-[#9D9D9D] text-lg line-clamp-3 md:line-clamp-2" >{companyData.profile.description}</p>
                                    </div>
    
                                    <div className="flex flex-row justify-between">
                                        <div>
                                            <h5 className="text-lg text-principal font-medium" >Horarios</h5>
                                            <h6 className="text-base text-left text-[#9D9D9D]">{dayString.join(", ")}</h6>
                                        </div>
    
                                        <div>
                                            <h5 className="text-lg text-principal font-medium" >Fecha de creación</h5>
                                            <h6 className="text-base text-right text-[#9D9D9D]">Septiembre, 2022</h6>
                                        </div>
                                    </div>
    
                                    <div className="flex flex-row gap-x-4 mt-2">
                                        {companyData.profile.phone && (
                                            <button onClick={() => navigator.clipboard.writeText(companyData.profile.phone)} title={companyData.profile.phone} className="rounded bg-[#25D366] p-2 w-full flex flex-row justify-center items-center hover:shadow-4xl duration-300 transition-all">
                                                <BsFillTelephoneFill color="#fff" size={26} />
                                            </button>
                                        )}
    
                                        {companyData.profile.socialMedia.facebook && (
                                            <button onClick={() => window.open(companyData.profile.socialMedia.facebook, "_blank", "noreferrer")} title={companyData.profile.socialMedia.facebook} className="rounded bg-[#3b5998] p-2 w-full flex flex-row justify-center items-center hover:shadow-4xl duration-300 transition-all">
                                                <ImFacebook color="#fff" size={26} />
                                            </button>
                                        )}
    
                                        {companyData.profile.socialMedia.instagram && (
                                            <button onClick={() => window.open(companyData.profile.socialMedia.instagram, "_blank", "noreferrer")} className="rounded p-2 w-full flex flex-row justify-center items-center hover:shadow-4xl duration-300 transition-all instagram-bg">
                                                <BsInstagram color="#fff" size={26} />
                                            </button>
                                        )}
    
                                        {companyData.profile.socialMedia.linkedin && (
                                            <button onClick={() => window.open(companyData.profile.socialMedia.linkedin, "_blank", "noreferrer")} className="rounded bg-[#0e76a8] p-2 w-full flex flex-row justify-center items-center hover:shadow-4xl duration-300 transition-all">
                                                <ImLinkedin color="#fff" size={26} />
                                            </button>
                                        )}
                                    </div>
                                </div>
                            </div>
    
                            <div className="gap-2 flex flex-col flex-1 laptop:overflow-y-auto pb-5 p-3">
                                <h1 className="text-xl text-principal font-semibold" >Servicios que ofrezco</h1>
                                {loadingServices ? (
                                    <Loading />
                                ):(
                                    <div className="grid grid-cols-1 gap-3 flex-1 laptop:grid-cols-2">
                                        {
                                            services.map((service, index) => {
                                                return (
                                                    <ViewService setOnBuy={setOnBuy} setOnEdit={setOnEdit} key={index} data={service} companyGet={companyGet} handleDelete={() => handleDelete(service.id)} />
                                                )
                                            })
                                        }
    
                                        {companyGet.ownerId && <AddService setOnNewService={() => {
                                            setOnNewService(true)
                                        }} />}
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : (
                        <AddServiceComponent onAddService={onNewService} setOnAddService={setOnNewService}  />
                    )
                ) : (
                    <AnimatePresence>
                        <motion.div className={`
                            w-full bg-[#ffffff] phone:rounded-lg phone:shadow-4xl
                            flex flex-col px-4 phone:p-4 phone:max-w-md mx-auto md:w-md
                        `} 
                        initial={{ opacity: 0, y: 100 }}
                        animate={{ opacity: 1, y: 0 }}
                        exit={{ opacity: 0, y: 100 }}>
                            <div>
                                <div>
                                    <div className="border-b w-full pb-2">
                                        <h1 className="text-2xl w-full text-center text-[#30313D]">Continua con tu Subscripcion</h1>
                                    </div>
                                    <p className="text-[#697286] w-full pt-3">Paga tu subscripcion para que tu cuenta de compañia siga activa y puedas recibir nuevos pedidos.</p>
                                </div>

                                <div className="py-6">
                                    <div className="flex flex-row gap-1">
                                        <h1 className="text-black text-[36px] tracking-tighter">MXN $150.00</h1>
                                        <div className="flex flex-col my-auto">
                                            <span className="text-[14px] text-[#697286]">por</span>
                                            <span className="text-[14px] text-[#697286]">mes</span>
                                        </div>
                                    </div>
                                </div>

                                <div className="pb-10 pt-2 flex flex-col gap-y-6">
                                    
                                </div>

                                <div className="w-full">
                                    <button onClick={handleResumePay} className={`
                                    text-white py-2 rounded shadow-md w-full bg-principal cursor-pointer hover:bg-principal/90
                                      hover:shadow-4xl transition-all duration-300 hover:bg-opacity-90`}>
                                            <span>Pagar</span>
                                    </button>
                                </div>
                            </div>
                        </motion.div>
                    </AnimatePresence>
                )
            ) : (
                <Loading />
            )}

            <AnimatePresence>
                {onEdit.onEdit && (
                    <div className="fixed top-0 left-0 w-full h-full bg-[#000000]/50 flex justify-center items-center z-50">
                        <div className="bg-white rounded-lg p-4 flex flex-col gap-y-4">
                            <h1 className="text-2xl text-principal font-semibold">Editar Servicio</h1>
                            <button onClick={() => setOnEdit({onEdit: false, idEdit: null})} className="absolute top-0 right-0 p-2 rounded-full bg-[#000000]/50 hover:bg-[#000000]/70 transition-all duration-300">
                                <span className="text-white text-xl font-bold">X</span>
                            </button>
                        </div>
                    </div>
                )}

                {onBuy.onBuy && (
                    <motion.div 
                        animate={{ opacity: 1, delay: 0.5, duration: 0.5 }}
                        initial={{ opacity: 0, elay: 0.5, duration: 0.5 }}
                        exit={{ opacity: 0, elay: 0.5, duration: 0.5 }}
                        className="fixed top-0 left-0 w-full h-full bg-[#000000]/50 flex justify-center items-center z-50">
                        

                        <div className="overflow-hidden h-[420px] min-h-[420px] bg-white rounded-lg p-4 flex flex-col gap-y-4 w-[600px] max-w-[600px] relative">
                            <button onClick={() => {setOnBuy({onBuy: false, onBuy: null, dataBuy: null, pageBuy: 1})}} className="absolute top-0 left-0 m-4"><AiOutlineClose color="#9d9d9d" size={16} /></button>
                            <h1 className="text-2xl w-full text-center text-principal font-semibold border-b-[1.5px] pb-2">{onBuy.pageBuy === 1 ? "Servicio" : onBuy.pageBuy === 2 ? "Metodo de pago" : onBuy.pageBuy === "card" && "Agregar un metodo de pago"}</h1>
                            
                            {onBuy.pageBuy === 1 && (
                                <motion.div className="flex flex-col gap-y-4 w-full flex-1"
                                    initial={{ x: -100 }}
                                    animate={{ x: 0 }}
                                    exit={{ x: -100 }}
                                    // transition={{ duration: 1 }}
                                >
                                    <div className="flex flex-row gap-x-4" >
                                        <div className="relative min-w-[70px] w-[70px] h-[70px] overflow-hidden drop-shadow-2xl" >
                                            <img className="w-full h-full object-cover rounded-lg" src={onBuy.dataBuy.data.image} alt="logo" />
                                        </div>
                                        <div>
                                            <h1 className="text-principal font-bold text-xl" >{onBuy.dataBuy.data.name}</h1>
                                            <span className="text-[#9d9d9d]" >{onBuy.dataBuy.data.description}</span>
                                            <div className="w-full pt-[32px] flex flex-row items-center gap-x-2">
                                                <input  value={coupon.toUpperCase()} onChange={(e) => setCoupon(e.target.value)} className="px-2 py-1 border-[1.5px] rounded-lg text-[1rem] text-[#333] w-full outline-none transition-all duration-300 focus:opacity-80 focus:shadow-4xl placeholder:lowercase placeholder:text-[ced4da]" type="text" placeholder="codigo de descuento" />
                                                <button onClick={handleCoupon} className="px-4 py-1 border-[1.5px] rounded-lg text-[1rem] w-fit border-principal bg-principal text-white transition-all duration-300 hover:shadow-4xl">Aplicar</button>
                                            </div>
                                            {
                                                1 === 2 ? (
                                                    <span className="text-sm text-green-500 flex flex-row items-center gap-x-1 mt-1"><AiOutlineCheckCircle />Codigo de descuento disponible.</span>
                                                ) : (
                                                    <span className="text-sm text-red-500 flex flex-row items-center gap-x-1 mt-1"><BiErrorCircle />Codigo de descuento no disponible.</span>
                                                )
                                            }
                                        </div>
                                    </div>
                                    
                                    <div className="flex flex-row justify-between mt-auto" >
                                        <div className="flex flex-row items-center gap-x-1 font-extrabold">
                                            <FaStar size={20} color={colors.orange} />
                                            <span className="text-principal text-lg">{((onBuy.dataBuy.data.raiting.stars / onBuy.dataBuy.data.raiting.count)).toFixed(1)}</span>
                                            <span className="text-principal text-lg">({onBuy.dataBuy.data.raiting.count})</span>
                                        </div>

                                        <div className="flex flex-col items-end">
                                            <span className="text-principal text-xl font-extrabold">Desde ${onBuy.dataBuy.data.price} MXN</span>
                                            {onBuy.dataBuy.data.discount > 1 && (
                                                <span className="text-[#DA6666] font-extrabold">{onBuy.dataBuy.data.discount}% de descuento</span>
                                            )}
                                        </div>
                                    </div>

                                    <div>
                                        <div className="flex flex-row w-full justify-end">
                                            <button onClick={() => {setOnBuy({onBuy: onBuy.onBuy, idBuy: onBuy.idBuy, dataBuy: onBuy.dataBuy, pageBuy: 2})}} className="bg-green-500 text-white px-8 py-1 rounded cursor-pointer hover:opacity-85 hover:shadow-4xl duration-300 transition-all">Contratar</button>
                                        </div>
                                    </div>
                                </motion.div>
                            )}

                            {onBuy.pageBuy === 2 && (
                                <motion.div
                                    initial={{ x: 100 }}
                                    animate={{ x: 0 }}
                                    exit={{ x: 100 }}
                                    // transition={{ duration: 1 }}
                                    className="w-full h-full relative">

                                    <div className="flex flex-col gap-y-4 w-full flex-1 max-h-[392px] relative">
                                        <div>
                                            <span className="text-[#9d9d9d] text-justify">Elige la opción para completar tu transacción de forma segura y conveniente con un costo de <b>"{ onBuy.dataBuy.data.price } pesos mexicanos"</b>.¡Gracias por tu compra!</span>
                                        </div>

                                        <div className="overflow-y-auto w-full h-full">
                                            <div className="flex flex-col overflow-auto gap-y-3 relative h-full p-3">
                                                {cardsClient.map((card, index) => {
                                                    return (
                                                        <button onClick={() => {setDefaultCard(card.id)}} key={index} className="relative p-2 border-[2px] rounded-lg border-[#EAEAEA] gap-y-4 flex items-center justify-between hover:border-transparent hover:shadow-4xl duration-300 transition-all">
                                                            <div className="flex flex-grow basis-0">
                                                                <div className="flex gap-x-2 my-auto">
                                                                    <div className="relative h-[22px] w-full overflow-hidden">
                                                                        <img className="object-cover w-full h-full object-center" src={process.env.PUBLIC_URL + "/images/cards/Visa.png"} alt="" />
                                                                    </div>
                                                                    <span className="text-[#9d9d9d]">{card.card.brand}</span>
                                                                </div>
                                                            </div>

                                                            <div className="flex justify-center text-[#9d9d9d] gap-x-2">
                                                                <span>****</span>
                                                                <span>****</span>
                                                                <span>****</span>
                                                                <span>{card.card.last4}</span>
                                                            </div>
                                                            
                                                            <div className="flex flex-grow basis-0 justify-end">
                                                                <div className="p-[2px] self-end w-4 h-4 rounded-full border-[2px] border-principal flex flex-col justify-center items-center my-auto">
                                                                    {onActiveCard === card.id && (
                                                                        <span className="bg-principal w-full h-full rounded-full"/>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </button>
                                                    )
                                                })}

                                                {cardsClient.length < 3 && (
                                                    <button onClick={() => {setOnBuy({onBuy: onBuy.onBuy, idBuy: onBuy.idBuy, dataBuy: onBuy.dataBuy, pageBuy: "card"})}} className="hover:border-transparent hover:shadow-4xl duration-300 transition-all relative p-2 border-[2px] rounded-lg border-[#EAEAEA] flex flex-row items-center justify-center gap-x-1">
                                                        <BsPlus size={ 26 } className="text-[#9d9d9d]" />
                                                        <span className="text-[#9d9d9d]">Agregar tarjeta</span>
                                                    </button>
                                                )}
                                            </div>
                                        </div>

                                        <div className="flex flex-row justify-between items-center w-full">
                                            <button onClick={() => {setOnBuy({onBuy: onBuy.onBuy, idBuy: onBuy.idBuy, dataBuy: onBuy.dataBuy, pageBuy: 1})}} className="bg-red-500 text-white px-8 py-1 rounded cursor-pointer hover:opacity-85 hover:shadow-4xl duration-300 transition-all">Volver</button>
                                            <button onClick={() => {handleServicePay(navigate, tokenUser, onBuy, setOnBuy, dispatch)}} className="bg-green-500 text-white px-8 py-1 rounded cursor-pointer hover:opacity-85 hover:shadow-4xl duration-300 transition-all">Pagar</button>
                                        </div>
                                    </div>
                                </motion.div>
                            )}

                            {onBuy.pageBuy === "card" && (
                                <motion.div
                                    initial={{ x: 100 }}
                                    animate={{ x: 0 }}
                                    exit={{ x: 100 }}
                                    // transition={{ duration: 1 }}
                                    className="flex flex-col gap-y-4 w-full flex-1 max-h-[392px]">

                                    <div>
                                        <span className="text-[#9d9d9d]">Agrega un metodo de pago para tu cuenta.</span>
                                    </div>
                                    
                                    <div className="flex-1">
                                        <Elements stripe={stripePromise}>
                                            <CardForm text="Guardar metodo de pago" callback={handleCard} />
                                        </Elements>

                                        {errorCardInvalid && (
                                            <div className="flex flex-row gap-y-2 pt-3 items-center gap-x-1 text-red-400">
                                                <CiWarning size={20} />
                                                <span className="">{errorCardInvalid}</span>
                                            </div>
                                        )}
                                    </div>

                                    <div className="flex flex-row justify-between items-center w-full relative">
                                        <button onClick={() => {setOnBuy({onBuy: onBuy.onBuy, idBuy: onBuy.idBuy, dataBuy: onBuy.dataBuy, pageBuy: 2})}} className="bg-red-500 text-white px-8 py-1 rounded cursor-pointer hover:opacity-85 hover:shadow-4xl duration-300 transition-all">Volver</button>
                                    </div>
                                </motion.div>
                            )}
                        </div>
                    </motion.div>
                )}
            </AnimatePresence>
        </SideBar>
    )
}