//  React
import React, { memo, useEffect, useMemo, useState } from "react";
import { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";

//  Context
import { AuthContext } from "../../context/AuthContext";
import { DataContext } from "../../context/DataContext";
import { CompanyContext } from "../../context/CompanyContext";
import LoadingPage from "../../components/pages/LoadingPage";
import ApiConnect from "../../api/connect.api";
import { Zoom } from "react-toastify";


//  AuthRoute
const PrivateRoute = () => {
    const auth = useContext(AuthContext);
    const data = useContext(DataContext);
    const company = useContext(CompanyContext)
    
    const [loading, setLoading] = useState(true)
    const [response, setResponse] = useState(null)
    const [onResponse, setOnResponse] = useState(null)
    
    //  Onboarding
    const checkOnboarding = () => {
        console.log('checkOnboarding')
        ApiConnect.get("/onboarding", {
            headers: {
                Authorization: `Bearer ${auth.tokenUser}`
            }
        }).then((response) => {
            console.log(response.data.data.account.payouts_enabled)
            return setOnResponse(response.data.data.account.payouts_enabled)
        }).catch((error) => {
            setOnResponse(false)
        })
    }

    //  Check response
    const checkResponse = () => {
        console.log('checkResponse')
        if (auth.loadingUser) {
            console.log('auth.loadingUser')
            if (auth.currentUser) {
                console.log('auth.currentUser')
                if (data.loadingData) {
                    console.log('data.loadingData')
                    if (data.userData.accountType === 2) {
                        console.log('data.userData.accountType === 2')
                        if (company.loadingCompany) {
                            console.log('company.loadingCompany')
                            if (company.companyData) {
                                console.log(company.companyData)
                                checkOnboarding()
                                if (onResponse === true) {
                                    console.log('onResponse === true')
                                    setResponse(<Outlet />)
                                } else if (onResponse === false) {
                                    setResponse(<Navigate to={'/onboarding'} />)
                                }
                            } else {
                                setResponse(<Navigate to={'/register-complete'}/>)
                            }
                        }
                    } else {
                        setResponse(<Outlet />)
                    }
                }
            } else {
                setResponse(<Navigate to={'/login'} />)
            }
        }
    }

    //  Check auth
    useEffect(() => {
        checkResponse()
    }, [data, auth, company, onResponse])

    //  Check response
    const responseData = useMemo(() => {
        return response
    }, [response])



    

    //  Render
    return <RenderLoading response={responseData} />
}

//  Memo
const RenderLoading = memo(function RenderLoading({response}) {
    return response !== null ? response : <LoadingPage />
});

export default PrivateRoute;
