import { useContext, useEffect, useState } from "react"
import Loading from "../components/Loading";
import ApiStripe from "../api/stripe.api";
import { AuthContext } from "../context/AuthContext";
import { Link } from "react-router-dom";
import { AiOutlineCheckCircle, AiOutlineCloseCircle } from "react-icons/ai";
import { CiLogout } from "react-icons/ci";
import { logoutUser } from "../auth/func/authUser";
import ApiConnect from "../api/connect.api";
import { CompanyContext } from "../context/CompanyContext";
import axios from "axios";

const ModalComponent = (props) => {
    return (
        <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center z-50">
            <div className="shadow-4xl overflow-hidden bg-white rounded-lg p-4 flex flex-col gap-y-4 w-[600px] max-w-[600px] relative">
                <div className="w-full h-full flex flex-col gap-y-4">
                    <button onClick={logoutUser} className="absolute cursor-pointer" title="salir">
                        <CiLogout className="text-secondary absolute" size={18} />
                    </button>
                    <h1 className="text-2xl w-full text-center text-principal font-semibold border-b-[1.5px] pb-2">{ props.title }</h1>
                    
                    { props.children }
                </div>
            </div>
        </div>
    )
}

const ErrorAlert = ({ text }) => {
    return (
        <div className="flex flex-row items-center w-full justify-center gap-x-1 text-[#fc526a]" >
            <i className="bi bi-exclamation-triangle-fill text-base"></i>
            <span className="text-sm" >{ text }</span>
        </div>
    )
}


export default function Onboarding() {
    const [loading, setLoading] = useState(false);
    const [status, setStatus] = useState(0);
    const [linkOnboarding, setLinkOnboarding] = useState(null);
    const [dataAccount, setDataAccount] = useState(null); // [account, setAccount]
    const [accountBank, setAccountBank] = useState("");
    const [statusAccount, setStatusAccount] = useState("");
    const [error, setError] = useState({
        message: "",
        name: "",
        error: null
    }); // { bank: "error" }
    const { tokenUser } = useContext(AuthContext);

    useEffect(() => {
        if (loading === false){
            ApiConnect.get("/onboarding", {
                headers: {
                    Authorization: `Bearer ${tokenUser}`
                }
            }).then((response) => {
                console.log(response.data.data.account);
                setLinkOnboarding(response.data.data.accountLink.url);
                setStatus(response.data.status);
                setStatus(response.data.data.account.payouts_enabled)
                setLoading(true);

                console.log(response.data.data.account);
                setDataAccount(response.data.data.account);
            }).catch((error) => {
                console.log(error);
                if (error.response.status === 503) {
                    setLoading(true);
                    setStatus(error.response.data.data.error);
                }
            })
        }
    }, [!loading])

    const handles = {
        onBoarding: () => window.location.assign(linkOnboarding),
        reloadPage: () => window.location.reload()
    }

    const handleBankAccount = () => {
        if(accountBank.match(/[a-zA-Z]/g)) {
            setError({
                message: "La clave interbancaria no puede contener letras.",
                name: "bank",
                error: accountBank
            })
            return;
        }

        ApiConnect.post("/bank", {
            data: {
                bankNumber: accountBank
            }
        }, {
            headers: {
                Authorization: `Bearer ${tokenUser}`
            },
        }).then((response) => {
            if(response.status === 200) {
                setError({
                    message: "",
                    name: "",
                    error: null
                })
                window.location.reload();         
            }
        }).catch((error) => {
            if(error.response.status) {
                setError({
                    message: error.response.data.message,
                    name: "bank",
                    error: accountBank
                })
            }
        })
    }
    

    return (
        loading === false ? (
            <div className="fixed top-0 left-0 w-full h-full flex justify-center items-center flex-col">
                <div className="flex flex-col gap-y-10">
                    <div>
                        <h1 className="text-principal text-2xl w-full text-center">Cargando el status...</h1>
                        <p className="text-sm text-secondary">Cargando los datos del tu status porfavor espre un momento.</p>
                    </div>
                    <Loading />
                </div>
            </div>
        ) : (
            dataAccount.details_submitted === false || statusAccount === "failed_keyed_identity" ? (
                <ModalComponent title={"Activar Cuenta de Empresa"}>
                    <div className="">
                        <p className="text-justify text-secondary">Hola, Antes de empezar debes verificar tu cuenta con varios requisitos para poder vender tus servicios(productos) dentro de work it, debes verificarla <span className="text-principal font-bold cursor-pointer" onClick={handles.onBoarding}><b>aqui.</b></span> En cuanto la cuenta termine de ser verificada(Este proceso tarda alrededor de 1 a 3 días hábiles) se habilitara su perfil.</p>
                        <div className="flex flex-row gap-x-2 mt-4">
                            <span>Status:</span>
                            {
                                status ? (
                                    <div className="flex flex-row gap-x-1 text-green-500 items-center">
                                        <AiOutlineCheckCircle className="text-lg"/>
                                        <span className="">Verificado</span>
                                    </div>
                                ) : (
                                    <div className="flex flex-row gap-x-1 text-red-500 items-center">
                                        <AiOutlineCloseCircle className="text-lg"/>
                                        <span className="">No verificado</span>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                    <div className="flex flex-col justify-center">
                        <button onClick={handles.reloadPage} className="text-white bg-principal w-fit mx-auto py-1 px-10 rounded-lg">Actualizar</button>
                        <p className="text-sm w-full text-secondary text-center">Da click para actualizar tu status.</p>
                    </div>
                </ModalComponent>
            ) : !dataAccount.payouts_enabled && dataAccount.details_submitted && (
                <ModalComponent title={"Cuenta de Banco"}>
                    <div className="">
                        <p className="text-justify text-secondary">Hola, Ahora tienes que poner tu clave interbancaria donde te llegaran los depositos.</p>
                        <input className="bg-gray-50 border my-2 border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-principal focus:border-principal block w-full p-2.5 transition-all duration-300" type="text"
                            value={accountBank}
                            onChange={(e) => setAccountBank(e.target.value)}
                            placeholder="clave interbancaria"
                        />
                    </div>
                    <div className="flex flex-col justify-center">
                        <button onClick={handleBankAccount} className="text-white bg-principal w-fit mx-auto py-1 px-10 rounded-lg hover:bg-principal/90 transition-all duration-300">
                            <span>Guardar</span>
                        </button>
                        {
                            error.name === "bank" && error.error === accountBank && (
                                <ErrorAlert text={error.message} />
                            )
                        }
                    </div>
                </ModalComponent>
            )
        )
    )
}