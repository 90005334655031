import { useEffect, useState } from "react"
import CurrencyInput from 'react-currency-input-field';

const ComponentInput = ({ placeholder, setValue, name, maxLenght=null, onError=false, selectedValue, type="input" }) => {
    const [lenght, setLenght] = useState(0)
    useEffect(() => {
        if (maxLenght) {
            if (selectedValue.length > maxLenght) {
                setValue(selectedValue.slice(0, maxLenght))
                setLenght(selectedValue.length)
            } else {
                setLenght(selectedValue.length)
            }
        }
    }, [selectedValue])

    return (
        type === "input" ? (
            <div className={`
                flex px-2 gap-2 flex-row w-full rounded-md border transition duration-500 
                ease-in-out transform hover:shadow-4xl ${lenght === maxLenght ? "focus-within:ring-red-300 focus-within:border-red-500 border-red-200" : 'focus-within:ring-blue-300 focus-within:border-blue-500 border-[#e0e1e4]'} 

                focus-within:outline-none focus-within:ring-2 focus-within:ring-blue-300 focus-within:border-blue-500`} 
            >

                <input className={`flex-1 px-0 py-2 focus:no-underline focus:outline-none focus:ring-0 rounded-md`} 
                    type="text" 
                    placeholder={ placeholder } 
                    onChange={(e) => setValue(e.target.value)} 
                    id={name ? name : ''} 
                    name={name ? name : ''} 
                    maxLength={maxLenght ? maxLenght : 9999999999} 
                    value={selectedValue}
                />

                {
                    maxLenght && (
                        <div className="flex flex-col justify-center items-center">
                            <span className="text-[#9da2af] text-sm font-semibold">{lenght}/{maxLenght}</span>
                        </div>
                    )
                }
            </div>
        ) : type === "textarea" ? (
            <div className={` py-2
                flex px-2 gap-2 flex-col w-full rounded-md border border-[#e0e1e4] transition duration-500 
                ease-in-out transform hover:shadow-4xl ${lenght === maxLenght ? "focus-within:ring-red-300 focus-within:border-red-500 border-red-200" : 'focus-within:ring-blue-300 focus-within:border-blue-500 border-[#e0e1e4]'} 

                focus-within:outline-none focus-within:ring-2`} 
            >

                <textarea className={`flex-1 focus:no-underline focus:outline-none focus:ring-0 resize-none`}
                    type="text"
                    placeholder={ placeholder }
                    onChange={(e) => setValue(e.target.value)}
                    id={name ? name : ''}
                    name={name ? name : ''}
                    maxLength={maxLenght ? maxLenght : 9999999999}
                    value={selectedValue}
                    rows={5}
                />

                {
                    maxLenght && (
                        <div className="flex flex-row justify-end">
                            <span className="text-[#9da2af] text-sm font-semibold">{lenght}/{maxLenght}</span>
                        </div>
                    )
                }
            </div>
        ) : type === "price" && (
            <div>
                <div className={`
                    relative rounded-md shadow-sm flex flex-row gap-2 px-2 border transition duration-500
                    ease-in-out transform hover:shadow-4xl py-2 focus-within:ring-blue-300 focus-within:border-blue-500 border-[#e0e1e4] focus-within:outline-none focus-within:ring-2
                `}>
                    <div className="pointer-events-none inset-y-0 left-0 flex items-center">
                        <span className="text-[#9da2af] sm:text-sm">$</span>
                    </div>

                    <CurrencyInput 
                        allowDecimals
                        decimalSeparator="."
                        step={1}

                        name={name ? name : ''} 
                        id={name ? name : ''}
                        value={selectedValue}
                        onValueChange={(value) => setValue(value)}
                        
                        className="flex-1 focus:no-underline focus:outline-none focus:ring-0 resize-none`"
                    />

                    <div className="pointer-events-none inset-y-0 flex items-center">
                        <span className="text-[#9da2af] sm:text-sm">MXN</span>
                    </div>
                </div>
            </div>
        )
    )
}

export default ComponentInput