//  CustomCards
import CustomCard from "../func/customCards";

//  Components
import SideBar from "../components/SideBar";

//  Styles
import "../scss/home.scss";

//  Data
import { categoryData } from "../data/categories";

export default function Home() {
    return (
        <SideBar>
            <div id="home">
                {
                    Object.keys(categoryData).map((category, index) => {
                        return (
                            <CustomCard
                                key={index}
                                title={categoryData[category].title}
                                icon={categoryData[category].icon}
                                path={categoryData[category].path}
                            />
                        )
                    })
                }
            </div>
        </SideBar>
    );
}
