//  Style SCSS
import "./scss/SideBar.scss";

//  React
import { NavLink } from "react-router-dom";
import { useState } from "react";
import { useContext } from "react";

//  Icons
import { HiViewGrid } from "@react-icons/all-files/hi/HiViewGrid";
import { BiMessageSquareDetail } from "@react-icons/all-files/bi/BiMessageSquareDetail";
import { CiShoppingBasket } from "react-icons/ci";
import { CgNotes } from "@react-icons/all-files/cg/CgNotes";
import { BiBuilding } from "@react-icons/all-files/bi/BiBuilding";
import { BiLogOut } from "@react-icons/all-files/bi/BiLogOut";
import { AiOutlineStar } from '@react-icons/all-files/ai/AiOutlineStar'
import { FaBars } from '@react-icons/all-files/fa/FaBars'

//  Components
import SearchBar from "./SearchBar";

//  React
import { useNavigate } from "react-router-dom";

//  Toast
import { ConfigToast } from "../func/setNotification";

//  Auth
import { logoutUser } from "../auth/func/authUser";
import { AuthContext } from "../context/AuthContext";
import { DataContext } from "../context/DataContext";

//  Data
import { CompanyContext } from "../context/CompanyContext";
import LoadingPage from "./pages/LoadingPage";


const checkSearch = (validation) => {
    if (validation !== false) {
        return <SearchBar />;
    }
};

//  ProfileButton
const ProfileButton = ({ companyData, currentUser }) => {
    return (
        <NavLink to={`/company/${ currentUser.uid }`} className="text-decoration-none">
            <BiBuilding />
            <span className="ps-1 flex flex-row justify-between w-full">
                <span>Mi Empresa</span>
                {
                    !companyData.companyVerified && (
                        <span className="relative flex w-3 h-3 my-auto">
                            <span className="animate-ping absolute inline-flex h-full w-full rounded-full bg-[#fd0206] opacity-75"/>
                            <span className="w-full h-full bg-[#fd0206] shadow-4xl rounded-full my-auto"/>
                        </span>
                    )
                }
            </span>
        </NavLink>
    );
};

export default function SideBar(props) {
    //  Context
    const { userData, loadingData } = useContext(DataContext);
    const { companyData, loadingCompany } = useContext(CompanyContext);
    const { currentUser } = useContext(AuthContext);
    const navigate = useNavigate()
    const [isActive, setActive] = useState(false);
    //  checkHeader
    const checkHeader = (validation) => {
        if (validation !== false) {
            return (
                <header>
                    <h4 className="title text-2xl">Work It</h4>
                    <button onClick={() => setActive(!isActive)} ><FaBars /></button>
                </header>
            );
        }
    };


    return (
        loadingData && currentUser && loadingCompany ? (
            <>  
                <ConfigToast />
                <div id="side-bar">
                    <div className={isActive === true ? 'leftSide active' : 'leftSide'} id="sidebar">
                        <div className="side">
                            <div className="top-side">
                                <h4 className="title">
                                    <NavLink className='text-2xl' to={"/home"}>Work It</NavLink>
                                </h4>
                            </div>

                            <div className="mid-side">
                                <NavLink to={"/home"} className="text-decoration-none">
                                    <HiViewGrid />
                                    <span className="ps-1">Home</span>
                                </NavLink>

                                {
                                    loadingData && (
                                        userData.accountType === 2 && (
                                            <ProfileButton companyData={companyData} currentUser={currentUser} />
                                        )
                                    )
                                }

                                <NavLink
                                    to={"/messages"}
                                    className="text-decoration-none"
                                >
                                    <BiMessageSquareDetail />
                                    <span className="ps-1">Mensajes</span>
                                </NavLink>

                                <NavLink
                                    to={"/orders"}
                                    className="text-decoration-none"
                                >
                                    <CiShoppingBasket />
                                    <span className="ps-1">Ordenes</span>
                                </NavLink>

                                <NavLink to={"/favorites"} className="text-decoration-none">
                                    <AiOutlineStar />
                                    <span className="ps-1">Favoritos</span>
                                </NavLink>
                            </div>
                        </div>

                        <div className="bottom-side">
                            <button onClick={logoutUser}>
                                <BiLogOut />
                                <span className="ps-1">Salir</span>
                            </button>
                        </div>
                    </div>

                    <div className="rightSide flex flex-col">
                        {checkHeader(props.onHideHeader)}

                        {checkSearch(props.searchValidation)}

                        {props.children}
                    </div>
                </div>
            </>
        ) : (
            <LoadingPage/>
        )
    );
}
