//  React
import { Link } from 'react-router-dom'

//  Icons
import { GoLaw } from '@react-icons/all-files/go/GoLaw'
import { BiMessageSquareDetail } from '@react-icons/all-files/bi/BiMessageSquareDetail'
import { GiHealthNormal } from '@react-icons/all-files/gi/GiHealthNormal'
import { FaIndustry } from '@react-icons/all-files/fa/FaIndustry'

import { FaRegBuilding } from '@react-icons/all-files/fa/FaRegBuilding'
import { MdAdsClick, MdOutlineFoodBank, MdAttachMoney } from 'react-icons/md'
import { FaUsersCog } from 'react-icons/fa'
import { IoIosSchool } from 'react-icons/io'
import { BiCodeAlt, BiPlusCircle } from 'react-icons/bi'


export default function CustomCard(props) {

    const checkContainer = () => {
        return (
            <div className="card-home">
                <div className="card-body-home">
                    <div className="iconDiv">
                        {
                            iconUsed
                        }
                    </div>


                    <p className="card-title m-0">{props.title}</p>
                </div>
            </div>
        )
    }

    let iconUsed
    switch (props.icon) {
        case "GoLaw":
            iconUsed = <GoLaw className='icon p-2' />
            break;

        case "BiMessageSquareDetail":
            iconUsed = <BiMessageSquareDetail className='icon p-2' />
            break;

        case "GiHealthNormal":
            iconUsed = <GiHealthNormal className='icon p-2' />
            break;

        case "FaRegBuilding":
            iconUsed = <FaRegBuilding className='icon p-2' />
            break;

        case "FaIndustry":
            iconUsed = <FaIndustry className='icon p-2' />
            break;

        case "MdAdsClick":
            iconUsed = <MdAdsClick className='icon p-2' />
            break;

        case "FaUsersCog":
            iconUsed = <FaUsersCog values={{ color: "blue" }} className='icon p-2' />
            break;

        case "IoIosSchool":
            iconUsed = <IoIosSchool className='icon p-2' />
            break;

        case "MdOutlineFoodBank":
            iconUsed = <MdOutlineFoodBank className='icon p-2' />
            break;

        case "BiCodeAlt":
            iconUsed = <BiCodeAlt className='icon p-2' />
            break

        case "MdAttachMoney":
            iconUsed = <MdAttachMoney className='icon p-2' />
            break

        case "BiPlusCircle":
            iconUsed = <BiPlusCircle className='icon p-2' />
            break

        default:
            iconUsed = <h1>ERROR</h1>
            break;
    }

    return (
        <Link className='text-decoration-none' to={ props.path }>
            { checkContainer() }
        </Link>
    )
}