export default function formattedValue(inputValue) {
    const capitalizeFirstLetter = (word) => {
        if (word.length > 0) return word.charAt(0).toUpperCase() + word.slice(1);
        return '';
    };

    const removeSpecialCharacters = (str) => {
        return str.replace(/[^a-zA-Z\s]/g, '');
    };

    const sanitizedValue = removeSpecialCharacters(inputValue);
    const inputValueWords = sanitizedValue.split(' ');
    const formattedValue = inputValueWords.map(capitalizeFirstLetter).join(' ');

    let trimmedValue = formattedValue.split(' ').slice(0, 2).join(' ');

    if (trimmedValue.length > 28) trimmedValue = trimmedValue.slice(0, 28);

    return trimmedValue;
}